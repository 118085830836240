import React from 'react';

export default (_props) => {
  const { className, size, large, small, ...props } = _props;

  // small icon
  if ((size && size.toLowerCase() === 'small') || small) {
    return (
      <svg width="24" height="24" viewBox="0 0 24 24" className={`audiicon audiicon-small ${className ? className : ''}`} {...props}>
        <g stroke="currentColor" strokeWidth="1" fill="none" fillRule="evenodd">
          <path d="M0.5,13.4187567 L0.5,20.5022728 C0.5,21.0545576 0.94771525,21.5022728 1.5,21.5022728 L3.5,21.5022728 C4.05228475,21.5022728 4.5,21.0545576 4.5,20.5022728 L4.5,19.4568183 L19.5,19.5022728 L19.5,20.5022728 C19.5,21.0545576 19.9477153,21.5022728 20.5,21.5022728 L22.5,21.5022728 C23.0522847,21.5022728 23.5,21.0545576 23.5,20.5022728 L23.5,12.5022728 L21.5,10.5022728 C20.8393934,9.29015161 20.3090904,8.36590919 19.9090909,7.72954555 C19.5090915,7.09318191 19.0393945,6.36590919 18.5,5.54772737 C16.9569117,5.43626324 15.8035743,5.36543428 15.0399878,5.33524048 M10.0033391,5.30640394 C8.86322776,5.34520202 7.36211474,5.42564316 5.5,5.54772737 C4.9606062,6.36590919 4.49090923,7.09318191 4.09090909,7.72954555 C3.81429392,8.16961499 3.47536473,8.74735508 3.07412152,9.46276582"/>
          <path d="M16.7,22.8 L10.6,13.5 L16.7,22.8 Z M13.5,7.9 C13.6,11.4 11,14.3 7.5,14.4 C7.4,14.4 7.2,14.4 7.1,14.4 C3.5,14.4 0.6,11.5 0.5,7.8 C0.5,4.2 3.5,1.3 7.1,1.4 C10.6,1.5 13.5,4.4 13.5,7.9 Z"/>
        </g>
      </svg>
    );
  }

  // large icon
  return (
    <svg width="48" height="48" viewBox="0 0 48 48" className={`audiicon audiicon-large ${className ? className : ''}`} {...props}>
      <g stroke="currentColor" strokeWidth="1" fill="none" fillRule="evenodd">
        <path d="M2.5,25 L2.5,38 C2.5,38.8284271 3.17157288,39.5 4,39.5 L9,39.5 C9.82842712,39.5 10.5,38.8284271 10.5,38 L10.5,36.5 L24,36.5 M31,36.5 L37.5,36.5 L37.5,38 C37.5,38.8284271 38.1715729,39.5 39,39.5 L44,39.5 C44.8284271,39.5 45.5,38.8284271 45.5,38 L45.5,24 L41,19.5 C40.0666656,17.6666667 39.2333323,16.1666667 38.5,15 C37.7666677,13.8333333 36.7666677,12.5 35.5,11 C32.5788574,10.778973 30.4900244,10.5540618 29,10.5 M22,10.5 C19.8290365,10.5302806 16.7403348,10.7057765 12.5,11 C11.2333336,12.5 10.2333336,13.8333333 9.5,15 C8.76666641,16.1666667 7.93333308,17.6666667 7,19.5 M24,25.5 L31,25.5 L33,28 C31.6666667,31.6666667 30.6666667,33.5 30,33.5 C29.8514669,33.5 29.4866654,33.5 29,33.5 M22,33.5 C20.0387231,33.5 18.3756149,33.5 18,33.5 C17.4324916,33.5 16.550607,32.1570426 15.5,29.5 M6.75,19.5 L21,19.5 M28,19.5 L41.25,19.5 M43.5,24.5749239 C43.5,25.5459384 43.4166667,26.2053587 43.25,26.5531847 C43,27.0749239 42.5,27.5749239 42,27.5749239 L40,27.5749239 C39.5,27.5749239 35.75,26.5749239 35.25,26.0749239 C34.9166667,25.7415905 34.6666667,25.4082572 34.5,25.0749239 C36.2683726,24.841489 37.7683726,24.6748224 39,24.5749239 C40.2316274,24.4750254 41.7316274,24.4750254 43.5,24.5749239 Z"/>
        <path d="M32.2,44.2 L20,24.8 L32.2,44.2 Z M25.5,14.4 C25.5,21.7 20.2,26.5 13.6,26.5 C7,26.4 1.6,21.1 1.5,14.4 C1.6,7.8 7,2.5 13.6,2.5 C20.2,2.5 25.5,7.8 25.5,14.4 C25.5,14.4 25.5,14.4 25.5,14.4 Z"/>
        <path d="M8,27.5749239 C7.5,27.5749239 6.5,27.5749239 6,27.5749239 C5.5,27.5749239 5,27.0749239 4.75,26.5531847 C4.68562168,26.41883 4.63367706,26.237984 4.59416615,26.0106467"/>
      </g>
    </svg>
  );
};
