import React from 'react';
import classnames from 'classnames';

export default ({className, style, symbol}) => {
  return (
    <div id="disc-avail" className={classnames("disclaimer", className)} style={style ? style : {}}>
      <sup>{symbol}</sup> Availability of vehicles from individual dealers is based on Audi Australia records at the time of publication. Real time availability cannot be guaranteed and should be confirmed with individual dealers. Images are digitally generated according to vehicle specifications from factory systems of the vehicles available for sale at the specified dealers, and may display overseas configurations.
    </div>
  )
};
