import { sanitizeString } from '../../lib/helpers';
import { RESERVATION_AMT } from '../../constants';

export const mapValuesToBackend = (values, vehicleData, vehicleImage, token) => {
  let data = {
    "pricingRequests": {
      "driveAwayPrice": values.driveAwayPrice,
      "monthyRepayment": values.monthyRepayment,
      "changeOverPrice": values.changeOverPrice,
      "otherRequests": values.otherRequests
    },
    "customer": {
      "firstName": values.firstName,
      "lastName": values.lastName,
      "email": values.email,
      "mobile": values.mobile,
      "postcode": values.postcode
    },
    "payment": {
      "paymentType": "CreditCard",
      "chargeToken": token,
      "amount": RESERVATION_AMT
    },
    "tradeIn": values.tradeIn === 'true',
    "contactFinance": values.contactFinance === 'true',
    "dealer": vehicleData?.vehicle?.basic?.dealer?.id,
    "model": vehicleData?.vehicle?.basic?.symbolicCarline?.description,
    "modelCode": vehicleData?.vehicle?.basic?.modelCode?.code,
    "engine": vehicleData?.vehicle?.basic?.modelCode?.description,
    "modelYear": vehicleData?.vehicle?.basic?.modelYear,
    "cost": "",
    "optIn": !!values.optIn,
    "marketingOptins": !!values.marketingOptins,
    "commissionNumber": vehicleData?.vehicle?.basic?.commissionNumber,
    "imageUrl": vehicleImage ? vehicleImage.url : "",
    "vehicleId": vehicleData?.vehicle?.basic?.id,
    "usedCar": false,
    "usedMileage": null
  }
  if (vehicleData?.vehicle?.basic?.typedPrices) {
    let price = vehicleData.vehicle.basic.typedPrices.find((p) => { return p.type === "retail"; });
    if (price) {
      data.cost = price.amount;
    }
  }
  if (vehicleData?.vehicle?.basic?.used && vehicleData?.vehicle?.basic?.used?.mileage > 0) {
      data.usedCar = true;
      data.usedMileage = `${vehicleData.vehicle.basic.used.mileageFormatted}${vehicleData.vehicle.basic.used.mileageUnit}`;
  }
  if (data.tradeIn) {
    data.tradeInDetails = {
      "redbookcode": values.redbookcode,
      "regoNumber": values.tradeRego,
      "regoState": values.tradeState,
      "make": values.tradeMake,
      "model": values.tradeModel,
      "year": values.tradeYear,
      "kilometers": values.tradeKms,
      "hasFinance": values.tradeHasFinance === 'yes',
    }
  }
  return data;
}

export const mapValuesToGTM = (values, vehicleData) => {
  let data = {
    "driveAwayPrice": values.driveAwayPrice,
    "monthyRepayment": values.monthyRepayment,
    "changeOverPrice": values.changeOverPrice,
    "otherRequests": String.prototype.trim(sanitizeString(values.otherRequests)),
    "firstName": values.firstName,
    "lastName": values.lastName,
    "email": values.email,
    "mobile": values.mobile,
    "postcode": values.postcode,
    "tradeIn": values.tradeIn === 'true',
    "contactFinance": values.contactFinance === 'true',
    "dealer": vehicleData?.vehicle?.basic?.dealer?.id,
    "model": vehicleData?.vehicle?.basic?.symbolicCarline?.description,
    "modelCode": vehicleData?.vehicle?.basic?.modelCode?.code,
    "engine": vehicleData?.vehicle?.basic?.modelCode?.description,
    "modelYear": vehicleData?.vehicle?.basic?.modelYear,
    "cost": "",
    "optIn": values.optIn,
    "commissionNumber": vehicleData?.vehicle?.basic?.commissionNumber,
    "vehicleId": vehicleData?.vehicle?.basic?.id,
    "usedCar": false,
    "usedMileage": null
  };
  if (vehicleData?.vehicle?.basic?.typedPrices) {
    let price = vehicleData.vehicle.basic.typedPrices.find((p) => { return p.type === "retail"; });
    if (price) {
      data.cost = price.amount;
    }
  }
  if (vehicleData?.vehicle?.basic?.used && vehicleData?.vehicle?.basic?.used?.mileage > 0) {
      data.usedCar = true;
      data.usedMileage = `${vehicleData.vehicle.basic.used.mileageFormatted}${vehicleData.vehicle.basic.used.mileageUnit}`;
  }
  if (data.tradeIn) {
    data.tradeInMake = values.tradeMake;
    data.tradeInModel = values.tradeModel;
    data.tradeInYear = values.tradeYear;
    data.tradeInKms = values.tradeKms;
    data.tradeInHasFinance = values.tradeHasFinance === 'yes';
  }
  return data;
}

export const flattenBackendErrors = (nestedErrors) => {
  let {pricingRequests, customer, payment, tradeInDetails, ...rest} = nestedErrors;
  let errors = {...rest};
  if (pricingRequests && Object.keys(pricingRequests).length) {
    errors = Object.assign(errors, pricingRequests);
  }
  if (customer && Object.keys(customer).length) {
    errors = Object.assign(errors, customer);
  }
  if (payment && Object.keys(payment).length) {
    errors = Object.assign(errors, payment);
  }
  if (tradeInDetails && Object.keys(tradeInDetails).length) {
    errors = Object.assign(errors, tradeInDetails);
  }
  return errors;
}
