import React from 'react';

export default (_props) => {
  const { className, size, large, small, ...props } = _props;

  // small icon
  if ((size && size.toLowerCase() === 'small') || small) {
    return (
      <svg width="24" height="24" viewBox="0 0 24 24" className={`audiicon audiicon-small ${className ? className : ''}`} {...props}>
        <g stroke="currentColor" strokeWidth="1" fill="none" fillRule="evenodd">
          <circle cx="11.5" cy="8.5" r="3"/>
          <path d="M18.5,8.5 C18.5,4.6 15.4,1.5 11.5,1.5 C7.6,1.5 4.5,4.6 4.5,8.5 C4.5,12.4 11.5,22.5 11.5,22.5 C11.5,22.5 18.5,12.4 18.5,8.5 Z"/>
        </g>
      </svg>
    );
  }

  // large icon
  return (
    <svg width="48" height="48" viewBox="0 0 48 48" className={`audiicon audiicon-large ${className ? className : ''}`} {...props}>
      <g stroke="currentColor" strokeWidth="1" fill="none" fillRule="evenodd">
        <circle cx="23.5" cy="17.5" r="7"/>
        <path d="M37.5,17.5 C37.5,9.8 31.2,3.5 23.5,3.5 C15.8,3.5 9.5,9.8 9.5,17.5 C9.5,25.2 23.5,44.5 23.5,44.5 C23.5,44.5 37.5,25.2 37.5,17.5 Z"/>
      </g>
    </svg>
  );
};
