import React from 'react';

import TextField from '../audi-ui-components/TextField';
import TextArea from '../audi-ui-components/TextArea';
import Select from '../audi-ui-components/Select';
import Dropdown from '../audi-ui-components/Dropdown';
import Checkbox from '../audi-ui-components/Checkbox';
import Switch from '../audi-ui-components/Switch';
import RadioFieldset from '../audi-ui-components/RadioFieldset';

import {
  FIELD_SELECT,
  FIELD_DROPDOWN,
  FIELD_TEXT,
  FIELD_TEXTAREA,
  FIELD_CHECKBOX,
  FIELD_RADIOS,
  FIELD_SWITCH
} from '../constants';

const fieldComponents = {
  [FIELD_SELECT]: Select,
  [FIELD_DROPDOWN]: Dropdown,
  [FIELD_TEXT]: TextField,
  [FIELD_TEXTAREA]: TextArea,
  [FIELD_RADIOS]: RadioFieldset,
  [FIELD_SWITCH]: Switch
};

const FormField = (props) => {

  const {fieldType, name, formikBag, onChange, formatValue, trim, wrapperClassName, showErrors, ...rest} = props;
  const {values, errors, touched, submitCount, setFieldValue, setFieldTouched} = formikBag;

  // allow errors to be a string or array
  let errMsg = "";
  if (errors[name]) {
    if (typeof errors[name] === 'string') {
      errMsg = errors[name];
    } else if (Array.isArray(errors[name])) {
      errMsg = errors[name][0];
    }
  }

  if (fieldType === FIELD_CHECKBOX) {
    return (
      <div className={`form-field ${wrapperClassName}`}>
        <Checkbox
          name={name}
          value=""
          checked={!!values[name]}
          onBlur={() => { setFieldTouched(name); }}
          onChange={(v,e) => { setFieldValue(name, e.target.checked); onChange(v); }}
          isInvalid={Boolean(errors[name] && (touched[name] || submitCount > 0 || showErrors))}
          invalidMessage={errMsg}
          {...rest}
        />
      </div>
    );
  }

  const Component = fieldComponents[fieldType];
  const noValue = fieldType === FIELD_SWITCH ? false : "";
  return (
    <div className={`form-field ${wrapperClassName}`}>
      <Component
        name={name}
        value={values[name] || noValue}
        onBlur={() => { setFieldTouched(name); }}
        onChange={(v) => {
          if (typeof formatValue === 'function') {
            v = formatValue(v);
          } else if (trim) {
            v = v.trim();
          }
          setFieldValue(name, v);
          onChange(v);
        }}
        isInvalid={Boolean(errors[name] && (touched[name] || submitCount > 0 || showErrors))}
        invalidMessage={errMsg}
        {...rest}
      />
    </div>
  );
}

FormField.defaultProps = {
  onChange: () => {},
  wrapperClassName: "",
  fieldType: FIELD_TEXT
}

export default FormField;
