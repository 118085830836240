import React, { useState, useEffect } from 'react';
import { Route, Switch, NavLink } from 'react-router-dom';
import moment from 'moment';
import queryString from 'query-string';
import { request } from '../lib/apiRequestWrapper';
import LoadingSpinner from '../components/LoadingSpinner';
import Enquiry from './Enquiry';
import Reservation from './Reservation';
import { MAINTENANCE } from '../constants';

import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
const stripePromise = loadStripe(process.env.RAZZLE_STRIPE_PUBLIC_KEY);

const FormWrapper = () => {

  const [vehicleId, setVehicleId] = useState(false);
  const [isFetchingData, setIsFetchingData] = useState(false);
  const [hasFetchedData, setHasFetchedData] = useState(false);
  const [fetchError, setFetchError] = useState(false);
  const [vehicleImage, setVehicleImage] = useState("");
  const [vehicleEdmImage, setVehicleEdmImage] = useState("");
  const [vehicleData, setVehicleData] = useState("");
  const [isReserved, setIsReserved] = useState(false);
  const [isSold, setIsSold] = useState(false);
  const [isUsed, setIsUsed] = useState(false);

  useEffect(() => {
    setTimeout(() => {window.scrollTo(0, 0);}, 50);

    if (vehicleId) {
      setIsFetchingData(true);
      setHasFetchedData(false);

      let uri = "";
      let date = moment().format('YYYY-MM-DD');
      let strippedVehicleId = vehicleId.replace(/[^a-z0-9]/gi, '');
      let aa = false;
      if (strippedVehicleId.indexOf("AA") === 0) {
        uri = `${process.env.RAZZLE_API}/1/service/vehicle/${strippedVehicleId}`;
        aa = true;
      } else {
        uri = `${process.env.RAZZLE_SCS_API}/api/v1/vehicles/vehicle/au/en/${strippedVehicleId}=?svd=svd-${date}`;
      }

      request(uri).then((data) => {
        // console.log(data)
        receiveVehicleData(data, aa);

      }).catch((error) => {
          if (error.status === 404 && !aa) {
              // try used car api
              request(
                  `${process.env.RAZZLE_SCS_USED_API}/api/v1/vehicles/vehicle/auuc/en/${strippedVehicleId}=?svd=svd-${date}`
              ).then((data) => {
                  // setIsUsed(true);
                  receiveVehicleData(data, aa);
              }).catch(() => {
                  setIsFetchingData(false);
                  setFetchError(true);
              })
          } else {
              setIsFetchingData(false);
              setFetchError(true);
          }
      });
    }

  }, [vehicleId]);

  const receiveVehicleData = (data, aa) => {
      // console.log("receiveVehicleData", aa);
      if (data.vehicle.basic.type && data.vehicle.basic.type === "U") {
          setIsUsed(true);
      }
      // get edm image
      request(
          `${process.env.RAZZLE_MEDIASERVICE}/MediaServiceCache/vinimageurl`,
          {
            method: "POST",
            body: JSON.stringify({vin: data.vehicle.basic.vin, view: "n3c01auw", mimeType: "image/jpg", width: 1200})
          }
      ).then((res) => {
          console.log(res);
          setVehicleEdmImage({url: res.imageUrl});
      }).catch((error) => {
      //
      });
      if (aa) {
        // setVehicleImage({url: `https://mediaservice.audi.com/media/live/n3c01/${data.vehicle.basic.id}.jpg`});
        // get render from cache
        request(
            `${process.env.RAZZLE_MEDIASERVICE}/MediaServiceCache/vinimageurl`,
            {
              method: "POST",
              body: JSON.stringify({vin: data.vehicle.basic.vin, view: "n3c01aut", mimeType: "image/png", width: 1000})
            }
        ).then((res) => {
            // console.log(res);
            setVehicleImage({url: res.imageUrl});
        }).catch((error) => {
            //
        });
        //
      } else {
        var pictures = data.vehicle.detail.pictures || data.vehicle.basic.pictures;
        // console.log("pictures", pictures)
        if (pictures && pictures.length) {
            var image = pictures.find(image => {
              return image.key === 'n3c01';
            });
            if (!image) {
                image = pictures[0];
            }
            if (image && image.url) {
                setVehicleImage(image);
                // if (image.url.indexOf("https://mediaservice") === 0) {
                //     let smallUrl = image.url;
                //     let qIdx = image.url.indexOf("?");
                //     if (qIdx === -1) {
                //       smallUrl = image.url + "?wid=600";
                //     } else {
                //       let qString = image.url.substring(qIdx);
                //       let qObj = queryString.parse(qString);
                //       qObj.wid = 600;
                //       let newQString = queryString.stringify(qObj);
                //       smallUrl = image.url.substring(0, qIdx) + newQString;
                //     }
                //     setVehicleEdmImage({url: smallUrl});
                // } else {
                //     setVehicleEdmImage(image);
                // }
            }
        }
      }
      setVehicleData(data);

      request(
        `${process.env.RAZZLE_MYAUDI_API}/1/vtp/isreserved/${data.vehicle.basic.commissionNumber}`
      ).then(() => {
        setIsFetchingData(false);
        setHasFetchedData(true);
      }).catch(error => {
        if (error.status === 409) {
          // car is already reserved or sold
          if (error.body && error.body.message && error.body.message === "Sold") {
            setIsSold(true);
          } else {
            setIsReserved(true);
          }
        }
        if (/*error.status === 400 || */error.status === 500) {
          setFetchError(true);
        }
        setIsFetchingData(false);
        setHasFetchedData(true);
      });
  }

  let isReservable = false;
  if (hasFetchedData && !isReserved) {
      if (isUsed && !MAINTENANCE.reservationUsed) { isReservable = true; }
      if (!isUsed && !MAINTENANCE.reservationNew) { isReservable = true; }
  }

  return(
    <div className="">
      <div className="vehicle-hero aui-color-gray95">
        {isFetchingData && !vehicleImage && <LoadingSpinner type="placeholder" />}
        {vehicleImage && <div role="img" className="vehicle-hero__img" style={{backgroundImage: `url("${vehicleImage.url}")`}} />}
      </div>

      <nav className="form-tabs aui-color-gray95">
        <NavLink to={`/enquiry?vehicleid=${vehicleId}`} activeClassName="active">Make an enquiry</NavLink>
        {isReservable && <NavLink to={`/reservation?vehicleid=${vehicleId}`} className="text-right" activeClassName="active">Order your Audi</NavLink>}
      </nav>
      <div className="aui-color-white p-2 position-relative" />

      <Switch>
        <Route exact path="/enquiry"
          render={(routeProps) =>
            <Enquiry {...routeProps}
              setVehicleId={setVehicleId}
              isFetchingData={isFetchingData}
              setFetchError={setFetchError}
              fetchError={fetchError}
              vehicleId={vehicleId}
              vehicleImage={vehicleImage}
              vehicleEdmImage={vehicleEdmImage}
              vehicleData={vehicleData}
              isUsed={isUsed}
              />} />
        <Route exact path="/reservation"
          render={(routeProps) => {
            return (
              <Elements stripe={stripePromise}>
                <Reservation {...routeProps}
                  setVehicleId={setVehicleId}
                  vehicleId={vehicleId}
                  isFetchingData={isFetchingData}
                  setFetchError={setFetchError}
                  fetchError={fetchError}
                  vehicleImage={vehicleImage}
                  vehicleEdmImage={vehicleEdmImage}
                  vehicleData={vehicleData}
                  isReserved={isReserved}
                  isSold={isSold}
                  isUsed={isUsed}
                />
              </Elements>
            );
          }}
        />
        <Route path="*" render={({ staticContext }) => {
          if (staticContext) { staticContext.status = 404; }
          return <div>404 Not Found</div>
        }} />
      </Switch>
    </div>
  )
};

export default FormWrapper;
