import React from 'react';
import classnames from 'classnames';
import FormField from './FormField';
import LoadingSpinner from './LoadingSpinner';
import Button from '../audi-ui-components/Button';
import Modal from '../audi-ui-components/Modal';
import IconSystemSelect from '../audi-ui-components/icons/SystemSelect';
import TradeinTerms from './legal/TradeinTerms';
import { formatNumberOnly } from '../lib/helpers';
import { request } from '../lib/apiRequestWrapper';
import { OPTS_STATES, FIELD_SELECT } from '../constants';
import _find from 'lodash/find';

import { connect } from 'react-redux';
import { rbGetMakes, rbGetFamilies, getVehicleByRego, getVehicleByModel, thatsMyCar, notMyCar, clearRbData, reset } from '../redux/Tradein';
const mapStateToProps = state => {
    return {
        loading: state.tradein.loading,
        rbData: state.tradein.rbData,
        rbList: state.tradein.rbList,
        regoError: state.tradein.regoError,
        carNotFound: state.tradein.carNotFound,
        showEstimate: state.tradein.showEstimate,
        showModelFields: state.tradein.showModelFields,
        showExtraFields: state.tradein.showExtraFields,
        rbMakes: state.tradein.rbMakes,
        rbFamilies: state.tradein.rbFamilies
    }
}
const mapDispatchToProps = dispatch => {
    return {
        rbGetMakes: () => { dispatch(rbGetMakes()); },
        rbGetFamilies: (m) => { dispatch(rbGetFamilies(m)); },
        getVehicleByRego: (r,s) => { dispatch(getVehicleByRego(r, s)); },
        getVehicleByModel: (m,f,y) => { dispatch(getVehicleByModel(m,f,y)); },
        thatsMyCar: (c) => { dispatch(thatsMyCar(c)); },
        notMyCar: () => { dispatch(notMyCar()); },
        clearRbData: () => { dispatch(clearRbData()); },
        reset: () => { dispatch(reset()); },
    };
};

class TradeInForm extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      showTradeTerms: false
    };
  }

  componentDidMount() {
    const {rbMakes, rbGetMakes, formikBag} = this.props;
    if (!rbMakes || rbMakes === null || rbMakes.length === 0) {
      rbGetMakes();
    }
    // check if we need to reset, TODO more checks
    if ((!formikBag.values.tradeRego || !formikBag.values.tradeState) || (this.props.showModelFields && !formikBag.values.tradeMakeCode)) {
      this.reset();
    }
    // get lowest allowed year
    var d = new Date();
    this.currentYear = d.getFullYear();
    this.lowestYear = d.getFullYear() - 10;
  }

  handleNextRego = () => {
    const {values} = this.props.formikBag;
    // validate
    if (!values.tradeRego || !values.tradeState) {
      let errors = {};
      if (!values.tradeRego) { errors.tradeRego = "Please enter your vehicle registration number"; }
      if (!values.tradeState) { errors.tradeState = "Please select your registration state"; }
      this.props.formikBag.setErrors(errors);
      return false;
    }
    // send request
    this.props.getVehicleByRego(values.tradeRego, values.tradeState);
  }

  handleNextModel = () => {
    const {values} = this.props.formikBag;
    // validate
    if (!values.tradeMakeCode || !values.tradeYear) {
      let errors = {};
      if (!values.tradeMakeCode) { errors.tradeMakeCode = "Please select a make"; }
      if (!values.tradeYear) { errors.tradeYear = "Please select a year"; }
      this.props.formikBag.setErrors(errors);
      return false;
    }
    // send request
    this.props.getVehicleByModel(values.tradeMakeCode, values.tradeFamilyCode, values.tradeYear);
  }

  selectCar = (rbc) => {
    const {formikBag, rbData} = this.props;
    if (rbc && typeof rbc === "string") {
      // selected from list
      formikBag.setFieldValue("redbookcode", rbc);
      this.props.thatsMyCar(rbc, (car) => {
        console.log("car", car);
        if (car.make) { formikBag.setFieldValue("tradeMake", car.make); }
        if (car.family) { formikBag.setFieldValue("tradeModel", car.family); }
        if (car.year) { formikBag.setFieldValue("tradeYear", car.year); }
      });
    } else {
      // confirm single
      this.props.thatsMyCar();
      if (rbData.rbc) { formikBag.setFieldValue("redbookcode", rbData.rbc); }
      if (rbData.make) { formikBag.setFieldValue("tradeMake", rbData.make); }
      if (rbData.family) { formikBag.setFieldValue("tradeModel", rbData.family); }
      if (rbData.year) { formikBag.setFieldValue("tradeYear", rbData.year); }
    }
  }

  reset = () => {
    this.props.reset();
    this.props.formikBag.setValues(Object.assign({}, this.props.formikBag.values, {
      tradeState: "", tradeRego: "", tradeMakeCode: "", tradeMake: "", tradeFamilyCode: "", tradeModel: "", tradeYear: "", redbookcode: ""
    }));
  }

  render() {
    const { formikBag, showErrors, rbMakes, rbFamilies, rbData, rbList, showModelFields, showExtraFields, showEstimate } = this.props;
    var famOpts = (formikBag.values.tradeMakeCode && rbFamilies[formikBag.values.tradeMakeCode]) ? rbFamilies[formikBag.values.tradeMakeCode] : [];
    var yearOpts = [];
    var selectedMake = null;
    var selectedFam = null;
    if (formikBag.values.tradeMakeCode) {
      selectedMake = _find(rbMakes, {value: formikBag.values.tradeMakeCode});
    }
    if (formikBag.values.tradeFamilyCode) {
      selectedFam = _find(famOpts, {value: formikBag.values.tradeFamilyCode});
    }
    let latestyear = this.currentYear;
    if (selectedFam) { latestyear = selectedFam.latestyear; }
    else if (selectedMake) { latestyear = selectedMake.latestyear; }
    for (let i=0; i<10; i++) {
      let yr = latestyear - i;
      if (yr >= this.lowestYear || i === 0) {
        yearOpts.push({label: `${yr}`, value: `${yr}`});
      }
    }
    return (
      <div className={classnames("trade-in-form position-relative", this.props.className)}>

        {this.props.loading && <LoadingSpinner />}

        <p className="mb-2">Enter vehicle details to start your trade-in estimate.</p>

        <div className="row align-items-end mb-4">
          <div className="col">
            <div className="row">
              <div className="col-xsmall">
                <FormField name="tradeState"
                  label="Registration state"
                  options={OPTS_STATES}
                  fieldType={FIELD_SELECT}
                  formikBag={formikBag}
                  showErrors={showErrors}
                />
              </div>
              <div className="col-xsmall">
                <FormField name="tradeRego"
                  label="Registration number"
                  formikBag={formikBag}
                  showErrors={showErrors}
                  formatValue={(v) => { return v.toUpperCase().replace(" ", ""); }}
                />
              </div>
            </div>
          </div>
          <div className="col-12 col-tiny-auto mt-4 mt-tiny-0">
            {(!showModelFields && !rbData) && <Button variant="secondary" onClick={this.handleNextRego}>Next</Button>}
          </div>
        </div>

        {showModelFields && rbMakes && <div className="row align-items-end mb-4">
          {this.props.regoError && <div className="col-12">
            <p className="aui-color-text-red mb-3">We could not find your vehicle. Please complete the following fields and try again.</p>
          </div>}
          <div className="col">
            <div className="row">
              <div className="col-xsmall">
                <FormField
                  name="tradeMakeCode"
                  fieldType={FIELD_SELECT}
                  label="Make"
                  options={rbMakes}
                  showErrors={showErrors}
                  formikBag={formikBag}
                  onChange={(v) => {
                    this.props.rbGetFamilies(v);
                    formikBag.setFieldValue("tradeFamilyCode", "");
                    formikBag.setFieldValue("tradeModel", "");
                    formikBag.setFieldValue("tradeYear", "");
                    let opt = _find(rbMakes, {value: v});
                    formikBag.setFieldValue("tradeMake", opt.label);
                    this.props.clearRbData();
                  }}
                />
              </div>
              <div className="col-xsmall">
                <FormField
                  name="tradeFamilyCode"
                  label="Model"
                  fieldType={FIELD_SELECT}
                  options={famOpts}
                  showErrors={showErrors}
                  formikBag={formikBag}
                  onChange={(v) => {
                    formikBag.setFieldValue("tradeYear", "");
                    let opt = _find(famOpts, {value: v});
                    formikBag.setFieldValue("tradeModel", opt.label);
                    this.props.clearRbData();
                  }}
                />
              </div>
              <div className="col-xsmall">
                <FormField
                  name="tradeYear"
                  label="Year"
                  fieldType={FIELD_SELECT}
                  options={yearOpts}
                  showErrors={showErrors}
                  formikBag={formikBag}
                  onChange={() => {
                    this.props.clearRbData();
                  }}
                />
              </div>
            </div>
          </div>
          <div className="col-12 col-tiny-auto mt-4 mt-tiny-0">
            {(!rbData && !showExtraFields) &&
              <Button variant="secondary" onClick={this.handleNextModel} disabled={!formikBag.values.tradeYear}>Next</Button>
            }
          </div>
        </div>}

        {rbData && <div className="mb-4">
          {!showEstimate && <p className="mb-2">Is this your vehicle?</p>}
          <div className="row">
            <div className="col">
              <div className="row">
                <div className="col-12 col-small-6">
                  <p><b>{rbData.make} {rbData.family} {rbData.year}</b></p>
                  <p>{rbData.description}</p>
                </div>
                <div className="col-12 col-small-6">
                  <p>Engine: {rbData.fueltype}</p>
                  <p>Transmission: {rbData.geartype}</p>
                </div>
              </div>
            </div>
            <div className="col-12 col-xsmall-auto">
              {!showEstimate && <div className="row flex-nowrap">
                <div className="col-auto">
                  <Button variant="secondary" onClick={this.props.notMyCar}>No</Button>
                </div>
                <div className="col-auto">
                  <Button variant="secondary" onClick={this.selectCar}>Yes</Button>
                </div>
              </div>}
            </div>
          </div>
        </div>}

        {rbList && <div className="mb-4 rb-list">
          <p className="mb-2">We found several possible matches. Please select the correct one.</p>
          {rbList.map((car) => {
            return (
              <div className="row rb-list__item" onClick={() => { this.selectCar(car.rbc); }} key={car.rbc}>
                <div className="col">
                  <p><b>{car.make || selectedMake.label} {car.family || selectedFam.label} {car.year}</b></p>
                  <p>{car.description}</p>
                </div>
                <div className="col-auto">
                  <IconSystemSelect large />
                </div>
              </div>
            );
          })}
        </div>}

        {showEstimate && rbData && rbData.tradeinmin && <div className="mb-4">
          <p>Your trade-in estimate for your {rbData.make} {rbData.family} {rbData.year} with fall somewhere between</p>
          <div className="estimate">
            ${rbData.tradeinmin}
            <span className="estimate-arrow" />
            ${rbData.tradeinmax}
          </div>
          <p className="disclaimer my-2">
            This online valuation is a guide only and is based on the information provided and the average quality of similar vehicles.
            Upon inspection of your vehicle by an Audi dealer the price could be higher or lower.
            This is impacted by several factors including; options fitted, condition, service history, odometer reading, damage and wear and tear.
            Please contact your local dealer to receive a formal estimate on your trade-in.
          </p>
          <p><Button variant="text" onClick={() => { this.setState({showTradeTerms: true}); }}>Terms &amp; conditions</Button></p>
        </div>}

        {showEstimate && rbData && !rbData.tradeinmin && <div className="mb-4 aui-color-text-red">
          Sorry, we don't have a trade-in value for that vehicle. Your dealer will evaluate your vehicle and supply you with a trade-in value.
        </div>}

        {this.props.carNotFound && <div className="mb-4 aui-color-text-red">
          Sorry, we don't have a trade-in value for that vehicle. Your dealer will evaluate your vehicle and supply you with a trade-in value.
        </div>}

        {showExtraFields && <div className="mb-4">
          <p>Please complete these fields</p>
          <div className="row">
            <div className="col-12 col-tiny-6">
              <FormField
                name="tradeKms"
                label="KMs"
                showErrors={showErrors}
                formikBag={formikBag}
                formatValue={formatNumberOnly}
              />
            </div>
            <div className="col-12 col-tiny-6">
              <FormField
                name="tradeHasFinance"
                fieldType={FIELD_SELECT}
                label="Currently financed*"
                showErrors={showErrors}
                formikBag={formikBag}
                options={[{ label: "No", value: "no" }, { label: "Yes", value: "yes" }]}
              />
            </div>
          </div>
        </div>}

        {(formikBag.errors.tradeMake || formikBag.errors.tradeModel || formikBag.errors.tradeYear) && showErrors &&
          <p className="aui-color-text-red my-2">Please complete the trade-in form</p>
        }

        {(rbData || rbList || showModelFields) && <p className="mt-4">
          <Button variant="secondary" onClick={this.reset}>Reset trade-in</Button>
        </p>}

        <Modal isActive={this.state.showTradeTerms} variant="layer" closeButton close={() => { this.setState({showTradeTerms: false}); }}>
          <TradeinTerms />
        </Modal>

      </div>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(TradeInForm);
