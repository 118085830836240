import React from 'react'

const ThankYou = ({
  submitStatus,
  reservationNumber,
  paymentToWaitlist,
  model,
  dealerName
}) => {
  return (
    <div>
      <section className="order-section full-width">
        <div className="order-section--content">

          <div className="row">
            <div className="col col-medium-8">

              {submitStatus === 3 && <>
                <h2 className="aui-headline-2 mb-5">Thank you.</h2>
                <p className="mb-5">
                  Thank you for your enquiry regarding the {model}.
                  A member of the {dealerName} team will be in contact with you.
                  If there is anything we can help with in the meantime, you can get in touch with Audi Customer care online or by calling <a href="tel:1800502834" className="text-link">1800 50 2834</a> between 8am and 6pm (AEST) Monday to Friday.
                </p>
                </>
              }

              {submitStatus === 4 && <>
                <h2 className="aui-headline-2 mb-5">Thank you.</h2>
                <p className="mb-5">
                  We've previously received your enquiry regarding the {model}.
                  A member of the {dealerName} team will be in contact with you.
                  If there is anything we can help with in the meantime, you can get in touch with Audi Customer care online or by calling <a href="tel:1800502834" className="text-link">1800 50 2834</a> between 8am and 6pm (AEST) Monday to Friday.
                </p>
              </>
              }

              {(submitStatus === 1 || (process.env.RAZZLE_APP_ENV !== "production" && submitStatus === null)) && <>
                <h2 className="aui-headline-2 mb-5">Thank you.</h2>
                <p className="mb-5">
                  Congratulations. You will receive an email confirming receipt of your Online Reservation.
                  A member of the {dealerName} team will contact you shortly regarding the next steps towards your purchase and will discuss any finance or trade-in needs you may have.
                  If you do not receive the email, please call the Audi Australia Customer Care Team on <a href="tel:1800502834" className="text-link">1800 50 2834</a>.
                </p>
                {reservationNumber && <p>Your reservation number is: <b style={{ padding: "0 10px", letterSpacing: ".3em" }}>{reservationNumber}</b></p>}
              </>}

              {submitStatus === 2 && <>
                <p className="aui-headline-4 mb-5"><b>Unfortunately stock has run out. No payment has been taken.</b></p>
                <p className="mb-5">Please contact your preferred Audi dealer to discuss how they can assist you with ordering a vehicle offline.</p>
                <PrimaryButton label="Find a dealer" href={`https://forms.audi.com.au/locate-a-dealer?preorder=${config.slug}`} />
              </>}

            </div>
          </div>

        </div>
      </section>

    </div>
  )
}

export default ThankYou;
