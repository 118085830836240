import React from 'react';
import classnames from 'classnames';
import { STEPS } from '../../constants';

const StepsList = ({currentStep}) => {
  return (
    <ul className="steps-list">

      <li className={classnames("first", {
        "active": currentStep === STEPS.FINANCE})}>
        <span className="dot" />
        <span className="label">Audi Finance</span>
      </li>

      <li className={classnames({
        "active": currentStep === STEPS.CUSTOMER,
        "next": currentStep === STEPS.FINANCE
      })}>
        <span className="dot" />
        <span className="label">My details</span>
      </li>

      <li className={classnames("last", {
        "active": currentStep === STEPS.PAYMENT,
        "next": currentStep === STEPS.CUSTOMER,
        "disabled": currentStep === STEPS.FINANCE
      })}>
        <span className="dot" />
        <span className="label">Submit order</span>
      </li>

    </ul>
  );
}

export default StepsList;
