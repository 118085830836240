import React from 'react';
import classnames from 'classnames';
import ResizeObserver from 'resize-observer-polyfill';
import Search from './Search';
import './styles/audi-components.scss';

class Subnav extends React.Component {

    render() {
        const {link, subNavigation, teaser, open, idx, onClickNav} = this.props;
        return (
            <div className={classnames("one-header-nav-item", {"one-header-nav-item--subnav-open": open})}>

                {link && <a ref={(el) => { this.props.setItemRef(el, idx); }} onClick={(e) => { onClickNav(idx, e); }} className="one-header-nav-item__link one-header__copy-m one-header__copy-m--link" href={link.url}>
                    <span className="one-header-nav-item__text">{link.text}</span>
                    <svg className="one-header-nav-item__icon" viewBox="0 0 24 24">
                        <path d="M10 16.9l5.5-5.4L10 6.1" stroke="currentColor" fill="none"></path>
                    </svg>
                </a>}

                <div className="one-header-sub-nav">
                    <div className="one-header-sub-nav__inner">
                        {link && <button onClick={(e) => { onClickNav(idx, e); }} className="one-header-sub-nav__button">
                            <svg viewBox="0 0 24 24">
                                <path d="M10 16.9l5.5-5.4L10 6.1" stroke="currentColor" fill="none"></path>
                            </svg>
                            <span className="one-header__copy-m one-header__copy-m--link">{link.text}</span>
                        </button>}
                        <div className="one-header-sub-nav__list-teaser-wrapper">
                            <nav className="one-header-sub-nav__list-container">
                                <ul className="one-header-sub-nav__list">
                                    {subNavigation && subNavigation.map((subItem, n) => {
                                        return (
                                            <li className={classnames("one-header-sub-nav__item", {"one-header-sub-nav__item--audi-sport": subItem.isAudiSport})} key={`nav-subitem-${n}`}>
                                                <a href={subItem.url} target={subItem.target ? subItem.target : "_self"} className="one-header-sub-nav__item-link one-header__copy-m one-header__copy-m--link">
                                                    {subItem.text}
                                                </a>
                                            </li>
                                        );
                                    })}
                                </ul>
                            </nav>
                            {teaser && <div className="one-header-teaser">
                                <div className="one-header-teaser__inner">
                                    {teaser.image && <div className="one-header-teaser__image-wrapper">
                                        <img className="one-header-teaser__image" src={teaser.image.src} alt={teaser.image.alt} />
                                    </div>}
                                    {teaser.link && <a className="one-header-teaser__link" href={teaser.link.url} target={teaser.link.target ? teaser.link.target : "_self"}>
                                        <div className="one-header-teaser__text one-header__headline-order-3 one-header__headline-order-3--normal">
                                            <span>{teaser.link.text}</span>
                                        </div>
                                        <svg className="one-header-teaser__icon" viewBox="0 0 48 48">
                                            <path d="M19.4 32.6l9.1-9.1-9.1-9.1" stroke="currentColor" fill="none"></path>
                                        </svg>
                                    </a>}
                                </div>
                            </div>}
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

class AudiHeader extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            data: null,
            view: "small",
            navOpen: false,
            openIdx: null,
            searchOpen: false
        };
        this.refWrapper = React.createRef();
        // this.containerRef = React.createRef();
        this.panelRef = React.createRef();
        this.indicatorRef = React.createRef();
        this.itemsRef = [];
    }

    componentDidMount() {
        this.resizeObserver = new ResizeObserver(this.layoutUpdate);
        this.resizeObserver.observe(this.refWrapper.current);
        var url = `${this.props.api}/1/cms/audiHeaderLinks`;
        fetch(url, {
          method: 'GET',
          headers: {
            'Accept': 'application/json',
            'Cache-Control': 'no-cache',
            'Content-Type': 'application/json'
          },
        }).then(
            (response) => {
                let ok = response.status >= 200 && response.status < 300;
                if (ok) {
                    response.json().then(data => {
                        this.setState({data: data});
                    }).catch(() => {
                        //
                    });
                } else {
                    //
                }
            }
        );
    }

    setItemRef = (el, idx) => {
        this.itemsRef[idx] = el;
    }

    layoutUpdate = () => {
        this.updateIndicatorPosition(this.state.openIdx);
    }

    toggleSearch = () => {
        let o = !this.state.searchOpen;
        this.setState({openIdx: null, searchOpen: o});
        document.body.classList.toggle("one-header--search-open");
    }

    toggleNav = () => {
        let o = !this.state.navOpen;
        this.setState({navOpen: o});
        document.body.classList.toggle("one-header--nav-open");
    }

    handleNavClick = (idx, e) => {
        e.preventDefault();
        if (this.state.openIdx === idx) {
            this.setState({openIdx: null});
            this.updateIndicatorPosition(null);
        } else {
            this.setState({openIdx: idx});
            this.updateIndicatorPosition(idx);
        }
        return false;
    }

    updateIndicatorPosition = (i) => {
        if (this.indicatorRef && this.indicatorRef.current && this.indicatorRef.current !== null) {
            if (i === null) {
                this.indicatorRef.current.style.width = '0px';
                this.indicatorRef.current.style.left = '0px';
            } else {
                let indicatorLeft = 0;
                let indicatorWidth = 0;
                let activeTarget = this.itemsRef[`${i}`];
                if (activeTarget && activeTarget !== null) {
                    let containerBounds = this.refWrapper.current.getBoundingClientRect();
                    let rectTarget = activeTarget.getBoundingClientRect();
                    indicatorLeft = rectTarget.left - containerBounds.left + this.panelRef.current.scrollLeft;
                    indicatorWidth = activeTarget.offsetWidth;
                }
                this.indicatorRef.current.style.left = `${indicatorLeft}px`;
                this.indicatorRef.current.style.width = `${indicatorWidth}px`;
            }
        }
    }

    render() {
        const {data, navOpen, openIdx, searchOpen} = this.state;
        return (
            <div ref={this.refWrapper} className={classnames("one-header one-header--teaser-animation-enabled", `one-header--view-${this.state.view}`, {"one-header--shader-visible": openIdx !== null || searchOpen, "one-header--search-open": searchOpen, "one-header--nav-open": navOpen, "one-header--nav-closed": !navOpen})}>
                <div className="one-header__wrapper">

                    <div className="one-header-logo">
                        <a className="one-header-logo__link" href="https://www.audi.com.au/au/web/en.html">
                            <svg className="one-header-logo__icon" viewBox="0 0 69 24">
                                <path d="M56.927 0a11.798 11.798 0 00-7.495 2.671A11.906 11.906 0 0041.9 0a11.719 11.719 0 00-7.494 2.671C32.347 1.006 29.745 0 26.91 0a11.798 11.798 0 00-7.494 2.671C17.358 1.006 14.756 0 11.92 0 5.32 0 0 5.303 0 11.884c0 6.58 5.32 11.884 11.921 11.884 2.835 0 5.475-1.007 7.495-2.671 2.058 1.664 4.66 2.67 7.494 2.67 2.835 0 5.475-1.006 7.495-2.67a11.906 11.906 0 007.533 2.67c2.874 0 5.475-1.006 7.533-2.67 2.058 1.664 4.66 2.67 7.495 2.67 6.601 0 11.921-5.302 11.921-11.883C68.848 5.342 63.528 0 56.927 0zm-7.495 17.226a9.126 9.126 0 01-1.708-5.342c0-1.974.621-3.832 1.708-5.342a9.126 9.126 0 011.709 5.342c0 2.013-.66 3.832-1.709 5.342zm-15.027 0a9.126 9.126 0 01-1.709-5.342c0-1.974.621-3.832 1.709-5.342a9.126 9.126 0 011.708 5.342c0 2.013-.66 3.832-1.708 5.342zm-15.028 0a9.126 9.126 0 01-1.709-5.342c0-1.974.622-3.832 1.709-5.342a9.126 9.126 0 011.708 5.342c0 2.013-.66 3.832-1.708 5.342zM2.602 11.884c0-5.071 4.116-9.213 9.242-9.213a9.22 9.22 0 015.63 1.897c-1.592 2.013-2.524 4.529-2.524 7.316 0 2.748.932 5.303 2.524 7.316-1.553 1.2-3.495 1.897-5.63 1.897-5.087 0-9.242-4.103-9.242-9.213zM21.24 19.2c1.592-2.013 2.524-4.529 2.524-7.316 0-2.749-.932-5.303-2.524-7.316 1.553-1.2 3.495-1.897 5.63-1.897 2.136 0 4.078.697 5.63 1.897-1.591 2.013-2.523 4.529-2.523 7.316 0 2.748.932 5.303 2.524 7.316-1.553 1.2-3.495 1.897-5.63 1.897-2.136 0-4.078-.697-5.631-1.897zm15.028 0c1.592-2.013 2.524-4.529 2.524-7.316 0-2.749-.932-5.303-2.524-7.316 1.553-1.2 3.494-1.897 5.63-1.897a9.22 9.22 0 015.63 1.897c-1.591 2.013-2.523 4.529-2.523 7.316 0 2.748.932 5.303 2.524 7.316-1.554 1.2-3.495 1.897-5.63 1.897a9.22 9.22 0 01-5.631-1.897zm20.658 1.897a9.22 9.22 0 01-5.63-1.897c1.591-2.013 2.523-4.529 2.523-7.316 0-2.749-.932-5.303-2.524-7.316 1.554-1.2 3.495-1.897 5.63-1.897 5.088 0 9.243 4.103 9.243 9.213 0 5.11-4.155 9.213-9.242 9.213z" fill="#000" fillRule="evenodd"></path>
                            </svg>
                            <strong className="one-header-logo__text u-one-header-visually-hidden">Menu</strong>
                        </a>
                    </div>

                    <div className="one-header-menu-button">
                        <button type="button" onClick={this.toggleNav} className="one-header-menu-button__button">
                            <div className="one-header-menu-button__icon">
                                <div className="one-header-menu-button__icon-line one-header-menu-button__icon-line--top"></div>
                                <div className="one-header-menu-button__icon-line one-header-menu-button__icon-line--middle"></div>
                                <div className="one-header-menu-button__icon-line one-header-menu-button__icon-line--middle-2"></div>
                                <div className="one-header-menu-button__icon-line one-header-menu-button__icon-line--bottom"></div>
                            </div>
                            <span className="one-header-menu-button__text one-header__copy-m">Menu</span>
                        </button>
                    </div>

                    <div className="one-header-nav">
                        <nav ref={this.panelRef} className="one-header-nav__list">

                            {data && data.mainNavigation && data.mainNavigation.map((item, i) => {
                                return (
                                    <Subnav {...item} setItemRef={this.setItemRef} onClickNav={this.handleNavClick} idx={i} open={openIdx === i} key={`nav-item-${i}`} />
                                );
                            })}

                        </nav>
                        <div ref={this.indicatorRef} className="one-header-active-bar"></div>
                    </div>

                    <div className="one-header__user-action-wrapper">
                        <div className="one-header-search-button">
                            <button onClick={this.toggleSearch} className="one-header-search-button__button one-header__copy-m" type="button">
                                <svg className="one-header-search-button__icon" viewBox="0 0 24 24">
                                    <path d="M22 22l-8.3-8.3L22 22zM15.5 9c.1 3.5-2.6 6.4-6.1 6.5H9c-3.6 0-6.5-2.9-6.5-6.5S5.4 2.5 9 2.5s6.5 2.9 6.5 6.5c0 0 0 0 0 0z" stroke="currentColor" fill="none"></path>
                                </svg>
                            </button>
                        </div>
                    </div>

                    <Search searchOpen={searchOpen} toggleSearch={this.toggleSearch} />

                </div>
            </div>
        );
    }
}

AudiHeader.defaultProps = {
    api: "https://cms-myaudi.audidevelopment.com.au"
};

export default AudiHeader;
