import React from 'react';
import ResizeObserver from 'resize-observer-polyfill';
import Button from '../audi-ui-components/Button';
import Modal from '../audi-ui-components/Modal';
import IconForward from '../audi-ui-components/icons/Forward';
import FinanceCalculator from './FinanceCalculator';

class PageSidebarLayout extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      sidebarHeight: 100,
      financeModalOpen: false
    };
    this.refSidebar = React.createRef();
    this.refContent = React.createRef();
  }

  componentDidMount() {
    this.layoutUpdate();
    this.resizeObserver = new ResizeObserver(this.layoutUpdate);
    this.resizeObserver.observe(this.refSidebar.current);
    this.resizeObserver.observe(this.refContent.current);
  }

  componentWillUnmount() {
    this.resizeObserver.unobserve(this.refSidebar.current);
    this.resizeObserver.unobserve(this.refContent.current);
  }

  layoutUpdate = () => {
    let h = this.refSidebar && this.refSidebar.current ? this.refSidebar.current.offsetHeight + 200 : 100;
    this.setState({ sidebarHeight: h });
  }

  render() {
    const {vehicle, dealer, faqLink, financeAmount} = this.props;
    const dealerTel = (dealer && dealer.dealerContactNumber) ? dealer.dealerContactNumber.replace("(", "").replace(")", "").replace(/ /g, "-") : "";
    return (
      <div className="order-wrap" style={{ minHeight: `${this.state.sidebarHeight}px` }}>
        <div ref={this.refContent} className="content-wrap">

          {this.props.children}

        </div>

        <div className="sidebar-wrap">
          <div ref={this.refSidebar} className="sidebar">
            
            {(financeAmount && vehicle) &&
              <div className="d-large-none">
                <Modal isActive={this.state.financeModalOpen} variant="layer" closeButton close={() => { this.setState({financeModalOpen: false}); }}>
                  <FinanceCalculator
                      amount={financeAmount}
                      openModal={() => {}}
                      model={vehicle.genericModelName || vehicle.modelName}
                      isUsed={vehicle.isUsed}
                  />
                </Modal>
              </div>
            }
          
            <div className="row mx-0">

              {vehicle && <div className="col-6 col-large-12 px-large-0">
                <div className="sidebar-box py-3 px-large-3">
                  <p className="aui-headline-4 mb-2">{vehicle.modelName} {vehicle.isUsed && "(pre-owned)"}</p>
                  <ul>
                    {vehicle.price?.amount > 0 && <li>
                      <IconForward small /> {vehicle.price.formatted} {vehicle.price.nameText !== "EGC" && <>Manufacturer's Recommended Drive Away Price<sup>1</sup></>} {vehicle.price.nameText === "EGC" && <>Excl. Gov't charges</>}
                    </li>}
                    {vehicle.mileage && <li><IconForward small /> Mileage: {vehicle.mileage}</li>}
                    {vehicle.commissionNumber && <li><IconForward small /> Stock ID {vehicle.commissionNumber.indexOf("AA") === 0 ? vehicle.commissionNumber.substring(2) : vehicle.commissionNumber}</li>}
                    {vehicle.details && vehicle.details.map((item, i) => {
                      return (
                        <li key={i} className="d-none d-large-block"><IconForward small /> {item}</li>
                      );
                    })}
                  </ul>
                </div>
              </div>}

              {(financeAmount && vehicle) &&
                <div className="d-none d-large-block col-large-12 px-large-0">
                  <div className="sidebar-box py-3 px-large-3">
                    <FinanceCalculator
                        amount={financeAmount}
                        openModal={this.props.openModal}
                        model={vehicle.genericModelName || vehicle.modelName}
                        isUsed={vehicle.isUsed}
                    />
                  </div>
                </div>
              }

              {(dealer || faqLink) && <div className="col-6 col-large-12 px-large-0">
                <div className="sidebar-box py-3 px-large-3">
                  {faqLink && <>
                    <Button variant="secondary" href={faqLink} target="_blank" rel="noopener noreferrer" isStretched className="faq-link mb-2 d-none d-large-block">FAQ</Button>
                    <Button variant="text" href={faqLink} target="_blank" rel="noopener noreferrer" className="faq-link mb-2 d-large-none">FAQ</Button>
                  </>}
                  {dealer && <>
                    <p className="">For assistance, please contact <b>{dealer.dealerName}</b></p>
                    <p className="mb-large-4">Phone: <a className="aui-textlink" href={`tel:${dealerTel}`}>{dealer.dealerContactNumber}</a></p>
                  </>}
                  {financeAmount &&
                    <p className="d-large-none">
                      <Button variant="text" onClick={() => { this.setState({financeModalOpen: true}); }}>Audi Financial Services</Button>
                    </p>
                  }
                </div>
              </div>}

            </div>

          </div>
        </div>

      </div>
    );
  }
}

export default PageSidebarLayout;
