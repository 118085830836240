import { request } from '../lib/apiRequestWrapper';
import _find from 'lodash/find';

const SET_LOADING = "TRADEIN/SET_LOADING";
const SET_MAKES = "TRADEIN/SET_MAKES";
const SET_FAMILIES = "TRADEIN/SET_FAMILIES";
const SET_LIST = "TRADEIN/SET_LIST";
const SET_DATA = "TRADEIN/SET_DATA";
const SET_REGO_ERROR = "TRADEIN/SET_REGO_ERROR";
const SET_CAR_NOT_FOUND = "TRADEIN/SET_CAR_NOT_FOUND";
const SET_SHOW_MODEL_FIELDS = "TRADEIN/SET_SHOW_MODEL_FIELDS";
const SET_SHOW_EXTRA_FIELDS = "TRADEIN/SET_SHOW_EXTRA_FIELDS";
const SET_SHOW_ESTIMATE = "TRADEIN/SET_SHOW_ESTIMATE";
const SELECT_CAR = "TRADEIN/SELECT_CAR";
const CLEAR = "TRADEIN/CLEAR";
const RESET = "TRADEIN/RESET";

export function rbGetMakes() {
  return (dispatch, getState) => {
    request(
      `${process.env.RAZZLE_MYAUDI_API}/1/services/vehicle/makes`,
      {
        method: "POST"
      }
    ).then((res) => {
      let makes = res.map(m => { return {label: m.description, value: m.makecode, latestyear: m.latestyear}});
      dispatch({type: SET_MAKES, payload: makes});
    }).catch((error) => {
      console.error(error);
    });
  };
}

export function rbGetFamilies(makecode) {
  return (dispatch, getState) => {
    request(
      `${process.env.RAZZLE_MYAUDI_API}/1/services/vehicle/${makecode}/families`,
      {
        method: "POST"
      }
    ).then((res) => {
      let fams = res.map(m => { return {label: m.description, value: m.familycode, latestyear: m.latestyear}});
      dispatch({type: SET_FAMILIES, payload: fams, makecode: makecode});
    }).catch((error) => {
      console.error(error);
    });
  };
}

export function getVehicleByRego(rego, state) {
  return (dispatch, getState) => {
    dispatch({type: SET_LOADING, payload: true});
    request(
      `${process.env.RAZZLE_MYAUDI_API}/1/services/vehicle/${rego}/${state}`,
      {
        method: 'POST',
      }
    ).then((response) => {
      // console.log(response);
      if (Array.isArray(response)) {
        dispatch({type: SET_LIST, payload: response});
      } else {
        dispatch({type: SET_DATA, payload: response});
      }
    }).catch((error) => {
      console.error(error);
      dispatch({type: SET_LOADING, payload: false});
      dispatch({type: SET_REGO_ERROR, payload: true});
      dispatch({type: SET_SHOW_MODEL_FIELDS, payload: true});
    });
  }
}

export function getVehicleByModel(makecode, familycode, year) {
  return (dispatch, getState) => {
    dispatch({type: SET_LOADING, payload: true});
    dispatch({type: SET_REGO_ERROR, payload: false});
    request(
      `${process.env.RAZZLE_MYAUDI_API}/1/services/vehicle/pricing`,
      {
        method: 'POST',
        body: JSON.stringify({"makecode": makecode, "familycode": familycode, "year": year})
      }
    ).then((response) => {
      // console.log(response);
      if (Array.isArray(response)) {
        if (response.length) {
          dispatch({type: SET_LIST, payload: response});
        } else {
          // empty list returned
          dispatch({type: SET_LOADING, payload: false});
          dispatch({type: SET_CAR_NOT_FOUND, payload: true});
          dispatch({type: SET_SHOW_EXTRA_FIELDS, payload: true});
        }
      } else {
        dispatch({type: SET_DATA, payload: response});
      }
    }).catch((error) => {
      console.error(error);
      dispatch({type: SET_LOADING, payload: false});
      dispatch({type: SET_CAR_NOT_FOUND, payload: true});
      dispatch({type: SET_SHOW_EXTRA_FIELDS, payload: true});
    });
  }
}

export function thatsMyCar(rbc, cb) {
  return (dispatch, getState) => {
    if (rbc) {
      // selected from list
      dispatch({type: SET_LOADING, payload: true});
      request(
        `${process.env.RAZZLE_MYAUDI_API}/1/services/vehicle/code/${rbc}`,
        {
          method: 'POST'
        }
      ).then((response) => {
        // console.log(response);
        dispatch({type: SELECT_CAR, payload: response});
        dispatch({type: SET_LOADING, payload: false});
        if (typeof cb === 'function') { cb(response); }
      }).catch((error) => {
        console.error(error);
        const list  = getState().tradein.rbList;
        let car = _find(list, {rbc: rbc});
        dispatch({type: SELECT_CAR, payload: car});
        dispatch({type: SET_LOADING, payload: false});
        if (typeof cb === 'function') { cb(car); }
      });
    } else {
      // confirmed single result
      dispatch({type: SET_SHOW_ESTIMATE, payload: true});
      dispatch({type: SET_SHOW_EXTRA_FIELDS, payload: true});
    }
  }
}

export function notMyCar() {
  return (dispatch, getState) => {
    const showModelFields = getState().tradein.showModelFields;
    if (!showModelFields) {
      dispatch({type: SET_DATA, payload: null});
      dispatch({type: SET_SHOW_MODEL_FIELDS, payload: true});
    } else {
      dispatch({type: SET_CAR_NOT_FOUND, payload: true});
      dispatch({type: SET_DATA, payload: null});
      dispatch({type: SET_SHOW_EXTRA_FIELDS, payload: true});
    }
  }
}

export function clearRbData() {
  return {type: CLEAR};
}

export function reset() {
  return {type: RESET};
}

const defaultState = {
  loading: false,
  rbData: null,
  rbList: null,
  regoError: false,
  carNotFound: false,
  showEstimate: false,
  showModelFields: false,
  showExtraFields: false,
  rbMakes: null,
  rbFamilies: {}
}

export function tradeinReducer(state = defaultState, action) {
  switch (action.type) {
    case SET_LOADING:
      return Object.assign({}, state, {
        loading: action.payload
      });
    case SET_DATA:
      return Object.assign({}, state, {
        rbData: action.payload,
        loading: false
      });
    case SET_LIST:
      return Object.assign({}, state, {
        rbList: action.payload,
        loading: false
      });
    case SET_MAKES:
      return Object.assign({}, state, {
        rbMakes: action.payload
      });
    case SET_FAMILIES:
      return Object.assign({}, state, {
        rbFamilies: Object.assign({}, state.rbFamilies, {
          [action.makecode]: action.payload
        })
      });
    case SET_REGO_ERROR:
      return Object.assign({}, state, {
        regoError: action.payload
      });
    case SET_CAR_NOT_FOUND:
      return Object.assign({}, state, {
        carNotFound: action.payload
      });
    case SET_SHOW_MODEL_FIELDS:
      return Object.assign({}, state, {
        showModelFields: action.payload
      });
    case SET_SHOW_EXTRA_FIELDS:
      return Object.assign({}, state, {
        showExtraFields: action.payload
      });
    case SET_SHOW_ESTIMATE:
      return Object.assign({}, state, {
        showEstimate: action.payload
      });
    case SELECT_CAR:
      return Object.assign({}, state, {
        rbList: null,
        rbData: action.payload,
        showEstimate: true,
        showExtraFields: true
      });
    case CLEAR:
      return Object.assign({}, state, {
        rbList: null,
        rbData: null,
        carNotFound: false,
        showExtraFields: false
      });
    case RESET:
      return Object.assign({}, state, {
        rbData: null,
        rbList: null,
        regoError: false,
        carNotFound: false,
        showEstimate: false,
        showModelFields: false,
        showExtraFields: false,
      })
    default:
      return state;
  }
}
