import React from 'react';
import classnames from 'classnames';

export default ({className, style, symbol}) => {
  return (
    <div id="disc-resfee" className={classnames("disclaimer", className)} style={style ? style : {}}>
      <sup>{symbol}</sup> The holding fee is paid directly to the dealer but does not secure ownership of the vehicle. If your dealer confirms that the vehicle remains in stock, your dealer will hold the vehicle for 3 business days while you finalise the sale with your dealer. Audi Australia Pty Ltd is not party to your interactions with your chosen dealer, is not party to any transactions entered into between you and the dealer (including payment or refund of the holding fee and any final sale) and does not warrant the accuracy of any representations made or information provided to you by the dealer in response to your completion of this form.
    </div>
  )
};
