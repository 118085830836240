import App from './App';
import { BrowserRouter } from 'react-router-dom';
import React from 'react';
import { hydrate } from 'react-dom';
import { Provider as ReduxProvider } from 'react-redux';
import { createAppStore } from './redux';
import {APPLICATION_CONTAINER_ID} from './constants';

const store = createAppStore(window.__initialState__);

hydrate(
  <ReduxProvider store={store}>
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </ReduxProvider>,
  document.getElementById(APPLICATION_CONTAINER_ID)
);

if (module.hot) {
  module.hot.accept();
}
