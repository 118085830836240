import React, { useState, useEffect } from 'react';
import { Route, Redirect, Switch } from 'react-router-dom';
import moment from 'moment';
import { request } from './lib/apiRequestWrapper';
import AudiHeader from './components/audi/AudiHeader';
import AudiFooter from './components/audi/AudiFooter';
import FormWrapper from './pages/FormWrapper';
import VehicleList from './pages/VehicleList';
import VehicleDetail from './pages/VehicleDetail';
import PriceDisclaimer from './components/legal/PriceDisclaimer';
import ResFeeDisclaimer from './components/legal/ResFeeDisclaimer';
import AvailabilityDisclaimer from './components/legal/AvailabilityDisclaimer';

import './styles/main.scss';

class App extends React.Component {

    render() {
        return(
            <div className="App">

                <AudiHeader api={process.env.RAZZLE_MYAUDI_CMS} />

                <main id="main">

                    <Switch>
                        <Route path={["/enquiry", "/reservation"]}>
                            <FormWrapper />
                        </Route>
                        <Route path="/:modelName/:commissionNumber" render={(routeProps) => <VehicleDetail {...routeProps} />} />
                        <Route path="/:modelName?" render={(routeProps) => <VehicleList {...routeProps} />} />
                        <Route path="*" render={({ staticContext }) => {
                          if (staticContext) { staticContext.status = 404; }
                          return <div>404 Not Found</div>
                        }} />
                    </Switch>

                </main>
                <AudiFooter api={process.env.RAZZLE_MYAUDI_CMS} />
                <Switch>
                    <Route path="/enquiry"><div /></Route>
                    <Route path="/reservation">
                        <div className="one-footer aui-color-black aui-color-text-light">
                            <PriceDisclaimer className="pb-2" symbol="1" />
                            <ResFeeDisclaimer className="pb-5 pt-2" symbol="2" />
                        </div>
                    </Route>
                    <Route path="/:modelName/:commissionNumber">
                        <div className="one-footer aui-color-black aui-color-text-light">
                            <PriceDisclaimer className="pb-5" symbol="1" />
                        </div>
                    </Route>
                    <Route path="/:modelName">
                        <div className="one-footer aui-color-black aui-color-text-light">
                            <PriceDisclaimer className="pb-2" symbol="^" />
                            <AvailabilityDisclaimer className="pb-5 pt-2" symbol="*" />
                        </div>
                    </Route>
                </Switch>
            </div>
        );
    }
};

export default App;
