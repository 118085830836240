import validate from 'validate.js';

import { DRIVEAWAY_VARIANCE } from '../constants'

// return only the first error message
validate.formatters.firstError = function(errors) {
  var errObj = {};
  if (errors.length > 0) {
    for (let i=0; i<errors.length; i++) {
      let field = errors[i].attribute;
      let msg = errors[i].options.message;
      errObj[field] = msg;
    }
  }
  return errObj;
};

validate.validators.truthy = function(value, options, key, attributes) {
  if (!value) {
    return options.message || "This field is required";
  }
  return;
}

validate.validators.isVin = function(value, options, key, attributes) {
  var vin = value.toUpperCase();
  if(vin.length !== 17) {
    return options.message || "Please enter a valid VIN";
  }
  // begin WAU, WUA, TRU,
  if(! /^(WAU|WUA|TRU)/.test(vin) ) {
    return options.message || "Please enter a valid VIN";
  }
  // followed by ZZZ
  if(! /^...(ZZZ)/.test(vin) ) {
    return options.message || "Please enter a valid VIN";
  }
  return;
};

validate.validators.isNumeric = function (value, options, key, attributes) {
  let number;
  if (value) {
    number = Number.parseInt(value.replace(/ ,/g, ''));
  }
  if (number && !Number.isInteger(number)) {
    return options.message || "Please enter a number"
  }
  return;
}

validate.validators.isValidDriveAwayPrice = function (value, options, key, attributes) {

  const cost = attributes.cost;
  let number;
  if (value) {
    number = Number.parseInt(value.replace(/,/g, ''));
  }

  if (number && Number.isInteger(number)) {
    // Number constrained to within DRIVEAWAY_VARIANCE % of retail price
    let percentageDiff = (cost / DRIVEAWAY_VARIANCE);
    let lowerBound = cost - percentageDiff;
    let upperBound = cost + percentageDiff;

    if (number > upperBound || number < lowerBound) {
      return options.message || "This offer is not within the acceptable range for the dealership";
    }
  }
  return;
}

validate.validators.tradeinRequired = function (value, options, key, attributes) {
  if (attributes.tradeIn === 'true' && (!value || value === null || value === "")) {
    return options.message || "This field is required";
  }
  return;
};

validate.validators.financeInformationRequired = function (value, options, key, attributes) {
  if (attributes.contactFinance === 'true' && (!value || value === null || value === "")) {
    return options.message || "This field is required";
  }
  return;
};

// validate.validators.stripeCardNumber = function (value, options) {
//   if (value === '' || value === undefined) { return ''; }
//   let Stripe = null;
//   if (typeof window !== 'undefined' && window.Stripe) {
//     Stripe = window.Stripe;
//   }
//   let isValid = !!Stripe ? Stripe.card.validateCardNumber(value.replace(/ /g, '')) : true;
//   if (!isValid) {
//     return options.message || "Please enter a valid card number";
//   }
//   return;
// }
//
// validate.validators.stripeCVC = function (value, options) {
//   if (value === '' || value === undefined) { return ''; }
//   let Stripe = null;
//   if (typeof window !== 'undefined' && window.Stripe) {
//     Stripe = window.Stripe;
//   }
//
//   let isValid = !!Stripe ? Stripe.card.validateCVC(typeof(value) !== 'number' ? value.replace(/ /g, '') : value) : true;
//   if (!isValid) {
//     return options.message || "Please enter a valid CVC";
//   }
//   return;
// }

export {
  validate
};
