import React from 'react';

// convert number to currency string with comma
// returns JSX or string
export const formatDollar = (num, returnJsx) => {
  if (typeof num === 'undefined' || num === null || num === "") { return " "; }
  if (typeof num === 'string' && num.indexOf('$') === 0) { return num; }
  var _num = parseFloat(num);
  var p = _num.toFixed(2).split(".");
  var s = _num < 0 ? "-$" : "$";
  var n = p[0].split("").reverse().reduce(function(acc, _num, i, orig) {
      return  _num === "-" ? acc : _num + (i && !(i % 3) ? "," : "") + acc;
  }, "") + "." + p[1];
  if (returnJsx) {
    return (<span className="currency"><sup>{s}</sup>{n}</span>);
  }
  return s+n;
};

// convert a currency string to number by removing non-numeric chars
// returns a Number
export const unFormatDollar = (currency) => {
  return Number(currency.replace(/[^0-9.-]+/g,""));
}


// format a cc or amex number with spaces
// returns a string
export const formatCreditCard = (value) => {
  if (value === '' || value === undefined) { return ''; }
  let rawdata = value.replace(/\D/g,'');
  if (rawdata.length < 5) {
    return rawdata;
  }
  let formatted = '';
  let firstTwo = rawdata.substring(0,2);
  if (firstTwo === '34' || firstTwo === '37') {
    // amex
    rawdata = rawdata.substring(0,15);
    if (rawdata.length < 11) {
      formatted = rawdata.replace(/\b(\d{4})(\d{1,6})\b/, '$1 $2');
    } else {
      formatted = rawdata.replace(/\b(\d{4})(\d{6})(\d{1,5})\b/, '$1 $2 $3');
    }
  } else {
    // normal cc
    rawdata = rawdata.substring(0,16);
    let pieces = rawdata.match(/\d{1,4}/g);
    formatted = pieces ? pieces.join(' ') : '';
  }
  return formatted;
};


export function formatMobile(value) {
  let _v = value.replace(/\D/g,'').substring(0,10); // First ten digits of input only
  let zip = _v.substring(0,4);
  let middle = _v.substring(4,7);
  let last = _v.substring(7,10);
  if(_v.length > 7) {
    return `${zip} ${middle} ${last}`;
  }
  else if(_v.length > 4) {
    return `${zip} ${middle}`;
  }
  else if(_v.length > 0) {
    return `${zip}`;
  }
  else {
    return '';
  }
}


export const formatNumberOnly = value => {
  let numberOnly = parseInt(value.replace(/[^0-9]/gi, ''));
  return isNaN(numberOnly) ? "" : numberOnly.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}


export function loadStripe() {
  if (!window.Stripe) {
    var script = document.createElement("script");
    script.type = "text/javascript";
    script.src = "https://js.stripe.com/v2/";
    script.onload = function() {
      window.Stripe.setPublishableKey(process.env.RAZZLE_STRIPE_PUBLIC_KEY);
    };
    document.getElementsByTagName("head")[0].appendChild(script);
  }
}


export function scrollToTop() {
  if (typeof window === 'object' && typeof window.scrollTo === 'function') {
    window.scrollTo(0, 0);
  }
}


export const gtmPush = (eventCategory, action, label, data) => {
  if (dataLayer) {
    if (data) {
      dataLayer.push({event: eventCategory, eventAction: action, eventLabel: label, data: data});
    } else {
      dataLayer.push({event: eventCategory, eventAction: action, eventLabel: label});
    }
  }
  if (process.env.RAZZLE_APP_ENV !== "production") {
    console.log("gtm", eventCategory, action, label, data);
  }
};


export const stateByPostcode = postcode => {

  const postcodeInt = parseInt(postcode);

  const nsw1 = range(1000,1000);
  const nsw2 = range(600,2000);
  const nsw3 = range(280,2619);
  const nsw4 = range(79,2921);
  const nsw = nsw1.concat(nsw2).concat(nsw3).concat(nsw4);

  const act1 = range(100, 200);
  const act2 = range(19, 2600);
  const act3 = range(21, 2900);
  const act = act1.concat(act2).concat(act3);

  const vic1 = range(1000,3000);
  const vic2 = range(1000,8000);
  const vic = vic1.concat(vic2);

  const qld1 = range(1000, 4000);
  const qld2 = range(1000, 9000);
  const qld = qld1.concat(qld2);

  const sa = range(1000, 5000);
  const wa = range(1000, 6000);
  const tas = range(1000, 7000);
  const nt = range(200, 800);

  if (nsw.indexOf(postcodeInt) >= 0) {
    return "NSW";
  }
  if (act.indexOf(postcodeInt) >= 0) {
    return "ACT";
  }
  if (vic.indexOf(postcodeInt) >= 0) {
    return "VIC";
  }
  if (qld.indexOf(postcodeInt) >= 0) {
    return "QLD";
  }
  if (sa.indexOf(postcodeInt) >= 0) {
    return "SA";
  }
  if (wa.indexOf(postcodeInt) >= 0) {
    return "WA";
  }
  if (tas.indexOf(postcodeInt) >= 0) {
    return "TAS";
  }
  if (nt.indexOf(postcodeInt) >= 0) {
    return "NT";
  }

  return false;

}

const range = (a, b, c) => {
  c = [];
  while (a--) c[a] = a + b;
  return c;
};


export const sanitizeString = (string) => {
  if (string === "" || string === undefined) { return; };
  const map = {
    '&': '&amp;',
    '<': '&lt;',
    '>': '&gt;',
    '"': '&quot;',
    "'": '&#x27;',
    "/": '&#x2F;',
    "`": '&grave;'
  };
  const reg = /[&<>"'/]/ig;
  return string.replace(reg, (match) => (map[match]));
};

export function smoothScrollTo(element, target, duration) {
  target = Math.round(target);
  duration = Math.round(duration);
  if (duration < 0) {
    return Promise.reject("bad duration");
  }
  if (duration === 0) {
    element.scrollTop = target;
    return Promise.resolve();
  }

  var start_time = Date.now();
  var end_time = start_time + duration;

  var start_top = element.scrollTop;
  var distance = target - start_top;

  // based on http://en.wikipedia.org/wiki/Smoothstep
  var smooth_step = function(start, end, point) {
    if(point <= start) { return 0; }
    if(point >= end) { return 1; }
    var x = (point - start) / (end - start); // interpolation
    return x*x*(3 - 2*x);
  }

  return new Promise(function(resolve, reject) {
    // This is to keep track of where the element's scrollTop is supposed to be, based on what we're doing
    var previous_top = element.scrollTop;

    // This is like a think function from a game loop
    var scroll_frame = function() {
      if(element.scrollTop != previous_top) {
        reject("interrupted");
        return;
      }

      // set the scrollTop for this frame
      var now = Date.now();
      var point = smooth_step(start_time, end_time, now);
      var frameTop = Math.round(start_top + (distance * point));
      element.scrollTop = frameTop;

      // check if we're done!
      if(now >= end_time) {
        resolve();
        return;
      }

      // If we were supposed to scroll but didn't, then we probably hit the limit, so consider it done; not interrupted.
      if(element.scrollTop === previous_top && element.scrollTop !== frameTop) {
        resolve();
        return;
      }
      previous_top = element.scrollTop;

      // schedule next frame for execution
      setTimeout(scroll_frame, 0);
    }

    // boostrap the animation process
    setTimeout(scroll_frame, 0);
  });
}
