import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

class Select extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            isFocused: false
        };
    }

    handleChange = (e) => {
        if (typeof this.props.onChange === 'function') {
            this.props.onChange(e.target.value, e);
        }
    }

    handleFocus = (e) => {
        this.setState({isFocused: true});
        if (typeof this.props.onFocus === 'function') {
            this.props.onFocus(e);
        }
    }

    handleBlur = (e) => {
        this.setState({isFocused: false});
        if (typeof this.props.onBlur === 'function') {
            this.props.onBlur(e);
        }
    }

    renderMessage = () => {
        const {isValid, isInvalid, validMessage, invalidMessage, description} = this.props;
        if (isValid && validMessage) {
            return (<span className="aui-select__valid">{validMessage}</span>);
        }
        if (isInvalid && invalidMessage) {
            return (<span className="aui-select__error">{invalidMessage}</span>);
        }
        if (description) {
            return (<span className="aui-select__description">{description}</span>);
        }
        return false;
    }

    render() {
        const {name, value, label, floatingLabel, disabled, isValid, isInvalid, options, theme} = this.props;
        const {isFocused} = this.state;
        var autoFillProp = {};
        if (this.props.autoComplete) {
          autoFillProp = {autoComplete: this.props.autoComplete};
        }
        return(
            <div style={this.props.style} className={classnames(
                "aui-select",
                `${this.props.className}`,
                {
                    "aui-select--floating-label": floatingLabel,
                    "is-disabled": disabled,
                    "is-focused": isFocused,
                    "is-dirty": value || value === 0,
                    "is-valid": isValid,
                    "is-invalid": isInvalid,
                    [`aui-theme-${theme}`]: theme
                }
            )}>
                <div className="aui-select__field">
                    <select name={name}
                        id={name}
                        value={value}
                        className="aui-select__input"
                        disabled={disabled}
                        onChange={this.handleChange}
                        onFocus={this.handleFocus}
                        onBlur={this.handleBlur}
                        {...autoFillProp}
                    >
                        <option disabled={value && value !== null} className="aui-select__input-label">{label}</option>
                        {options.map((opt, i) => {
                            if (typeof opt === "string") {
                                return (
                                    <option key={`opt_${i}`} value={opt}>{opt}</option>
                                );
                            }
                            if (opt.value) {
                                return (
                                    <option key={`opt_${i}`} value={opt.value} disabled={opt.disabled}>{opt.label}</option>
                                );
                            }
                            if (opt.options) {
                                return (
                                    <optgroup key={`opt_${i}`} label={opt.label}>
                                        {opt.options.map((subOpt,n) => {
                                            if (typeof subOpt === "string") {
                                                return (
                                                    <option key={`opt_${i}_${n}`} value={subOpt}>{subOpt}</option>
                                                );
                                            }
                                            if (subOpt.value) {
                                                return (
                                                    <option key={`opt_${i}_${n}`} value={subOpt.value} disabled={subOpt.disabled}>{subOpt.label}</option>
                                                );
                                            }
                                        })}
                                    </optgroup>
                                );
                            }
                            return null;
                        })}
                    </select>
                    <label className="aui-select__label" htmlFor={name}>{label}</label>
                    <span className="aui-select__focus-line"></span>
                </div>
                {this.renderMessage()}
            </div>
        );
    }

}

Select.propTypes = {
    name: PropTypes.string,
    value: PropTypes.any,
    label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
    floatingLabel: PropTypes.bool,
    disabled: PropTypes.bool,
    isValid: PropTypes.bool,
    isInvalid: PropTypes.bool,
    options: PropTypes.array,
    validMessage: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
    invalidMessage: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
    description: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
    onChange: PropTypes.func,
    onFocus: PropTypes.func,
    onBlur: PropTypes.func,
    style: PropTypes.object,
    className: PropTypes.string,
    theme: PropTypes.oneOf(['light']) // see styles/components/_select-themes.scss
};

Select.defaultProps = {
    style: {},
    className: '',
    floatingLabel: true
}

export default Select;
