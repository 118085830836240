import React from 'react';
import PropTypes from 'prop-types';
import Spinner from '../audi-ui-components/Spinner';

const LoadingSpinner = (props) => {
  return (
    <div className={"loader " + props.type}>
      <div className="loader__inner">
        <Spinner isContinuous />
        {props.children && <div>{props.children}</div>}
      </div>
    </div>
  );
}

LoadingSpinner.propTypes = {
  type: PropTypes.oneOf(['overlay', 'placeholder', 'fixed'])
};

LoadingSpinner.defaultProps = {
  type: 'overlay'
};

export default LoadingSpinner;
