import React from 'react';

export default () => {
  return (
    <div className="terms">
      <p className="aui-headline-4 mb-4">Repayments Calculator (Audi Financial Services)</p>
      <p className="mb-3">
        <sup><b>VI</b></sup> Available to approved applicants of Audi Financial Services (AFS) ABN 20 097 071 460, Australian Credit Licence Number 389344.
        <br />The information provided is indicative only and is not a formal or binding quote.
        It is of a general nature and does not take into account your personal needs and financial circumstances.
        Nothing on this website constitutes or should be construed to constitute legal, taxation or financial advice.
        Before making a decision about any of the products and services featured on this website, you should consult with your own independent legal, taxation and financial advisors.
      </p>
      <p className="mb-3">
        <sup><b>IX</b></sup>  The represented range of interest rates that may be selected using this does not reflect the rate offered to a particular customer.
        The actual rate will vary, depending on your circumstances, our credit criteria and other details.
        Conditions, fees and charges may apply.
      </p>
      <p className="mb-3">
        <sup><b>VII</b></sup> The repayment amount is based on the accuracy of information entered and does not include any provision for fees and charges.
        Dealer may charge to arrange finance, please contact your Audi Dealer for more information.
      </p>
      <p className="mb-3">
        <sup><b>VIII</b></sup> Additional interest charges accrue when a lump sum agreed at the time of the loan ("balloon") is owed to AFS at the end of the loan after all monthly repayments have been made.
      </p>
      <p className="mb-3">
        Statutory charges current as at 29-Jan-2020.
      </p>
      <p className="mb-3">
        AFS is a trading name of Volkswagen Financial Services Australia Pty Limited ABN 20 097 071 460, Australian Credit Licence Number 389344.
      </p>
    </div>
  );
};
