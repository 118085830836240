import React from 'react';
import classnames from 'classnames';

import { STEPS, FIELD_RADIOS, FIELD_TEXTAREA, FIELD_SELECT } from '../../constants';

import Button from '../../audi-ui-components/Button';
import { Field } from 'formik';
import { formatDollar, formatNumberOnly, gtmPush } from '../../lib/helpers';
import FormField from '../../components/FormField';
import TradeInForm from '../../components/TradeInForm';

class FinanceStep extends React.Component {

  componentDidMount() {
      let gtmEventCat = this.props.isUsed ? "VTP Used" : "VTP";
      gtmPush(gtmEventCat, "AudiFinance", this.props.model);
  }

  render() {
    const { formikBag, nextClicked } = this.props;
    return (
      <div className="step customer">

        <section className="order-section">
          <div className="order-section--content">

            <div className="question mb-5">
              <h3 className="aui-headline-3 mb-2">Purchase Information</h3>
              <p className="mb-3">To help your dealer best meet your needs, please let us know if you have any purchase figures or requirements in mind.</p>
              <div className="row">
                <div className="col-12 col-medium-6">
                  <FormField
                    name="driveAwayPrice"
                    label="Your desired driveaway price"
                    showErrors={nextClicked}
                    formikBag={formikBag}
                    formatValue={formatNumberOnly}
                    icon={<span className="prepend-dollar"><b>$</b></span>}
                  />
                  <Field name="cost" type="hidden" />
                </div>
              </div>
            </div>

            <div className="question mb-5 pt-2">
              <div className="aui-headline-3 mb-2">Audi Financial Services</div>
              <p className="mb-3">Audi Financial Services make financing or leasing simple and flexible by offering solutions that suit your budget and lifestyle. Would you like to know more?</p>
              <FormField
                name="contactFinance"
                label=""
                showErrors={nextClicked}
                formikBag={formikBag}
                fieldType={FIELD_RADIOS}
                options={[
                  { label: 'Yes, please', value: 'true' },
                  { label: 'No, thanks', value: 'false' },
                ]}
                wrapperClassName="mb-4"
              />
              <div className="row mb-5">
                {formikBag.values.contactFinance === 'true' &&
                  <div className="col-12 col-medium-6">
                    <FormField
                      name="monthyRepayment"
                      label="Your desired monthly repayment"
                      showErrors={nextClicked}
                      formikBag={formikBag}
                      formatValue={formatNumberOnly}
                      icon={<span className="prepend-dollar"><b>$</b></span>}
                    />
                  </div>
                }
              </div>
            </div>

            <div className="question mb-5 pt-2">
              <div className="aui-headline-3 mb-2">Trade-in</div>
              <p className="mb-3">Your purchase experience should be as easy as possible. Would you like your dealer to provide a trade-in appraisal on your current vehicle and remove the hassle of selling it privately?</p>
              <FormField
                name="tradeIn"
                label=""
                showErrors={nextClicked}
                formikBag={formikBag}
                fieldType={FIELD_RADIOS}
                options={[
                  { label: 'Yes, please', value: 'true' },
                  { label: 'No, thanks', value: 'false' },
                ]}
                wrapperClassName="mb-4"
              />
              {formikBag.values.tradeIn === 'true' &&
                <TradeInForm formikBag={formikBag} showErrors={nextClicked} />
              }
            </div>

          </div>
        </section>

        <section className="order-section aui-color-gray95">
          <div className="order-section--content">
            <div className="question mb-2 pt-2">
              <p>Please use the message box below if you have any specific comments or requests.</p>
              <FormField
                name='otherRequests'
                fieldType={FIELD_TEXTAREA}
                showErrors={nextClicked}
                formikBag={formikBag}
                label="Your message"
                maxLength={1000}
                wrapperClassName="mb-4"
              />
            </div>
          </div>
        </section>

        <section className="order-section">
          <div className="order-section--content">
            {!this.props.isInBuild && <Button variant="secondary" href="https://www.audi.com.au/au/web/en/audi-new-car-search.html">Back</Button>}
            {this.props.isInBuild && <Button variant="secondary" onClick={this.props.onBackInBuild}>Back</Button>}
            {` `}
            <Button variant="primary" onClick={(e) => { this.props.onClickNext(formikBag.values, formikBag); e.preventDefault(); }}>Next</Button>
          </div>
        </section>

      </div>
    );
  }

}

export default FinanceStep;
