import React, { useEffect, useState } from 'react';
import { Formik, Form } from 'formik';
import FormField from '../../components/FormField';
import Button from '../../audi-ui-components/Button';
import queryString from 'query-string';

import LoadingSpinner from '../../components/LoadingSpinner';
import ThankYou from '../ThankYou';
import Privacy from '../../components/legal/Privacy';

import { validate } from '../../lib/validation';
import { enquirySchema } from './schema';
import { formatMobile, scrollToTop, gtmPush } from '../../lib/helpers';
import { request } from '../../lib/apiRequestWrapper';

import { FIELD_CHECKBOX, FIELD_RADIOS, POST_ENQUIRY_URL, STEPS } from '../../constants';


/* User will arrive at this page from the audi configurator
 * and the link will have a query string with the vehicle ID
 * eg. ?vehicleid=QVVTMjAxMjA3NTAxNjQ=
 */

const Enquiry = ({
  location,
  vehicleId,
  setVehicleId,
  vehicleData,
  vehicleImage,
  vehicleEdmImage,
  isUsed,
  isFetchingData,
  fetchError,
  setFetchError
}) => {

  const { vehicle } = vehicleData;
  const commissionNumber = vehicle?.basic?.commissionNumber;
  const model = vehicle?.basic?.symbolicCarline?.description;
  const dealerName = vehicle?.basic?.dealer?.name;
  const dealerId = vehicle?.basic?.dealer?.id;
  const basicId = vehicle?.basic?.id;
  const modelCode = vehicle?.basic?.modelCode?.code;
  const engine = vehicle?.basic?.modelCode?.description;
  const modelYear = vehicle?.basic?.modelYear;
  const retailPrice = vehicle?.basic?.typedPrices[0].amount;
  const mileage = (vehicle && isUsed && vehicle.basic?.used) ? `${vehicle.basic.used.mileageFormatted}${vehicle.basic.used.mileageUnit}` : false;

  const [isPostingData, setIsPostingData] = useState(false);
  const [postError, setPostError] = useState(false);
  const [userAlreadySubmitted, setUserAlreadySubmitted] = useState(false);
  const [submitData, setSubmitData] = useState(false);
  const [step, setStep] = useState(STEPS.ENQUIRY);

  const fields = [
    { name: 'firstName', labelText: 'First name*', },
    { name: 'lastName', labelText: 'Last name*', },
    { name: 'emailAddress', labelText: 'Email*', trim: true },
    { name: 'phoneNumber', labelText: 'Mobile Number*', formatValue: formatMobile },
    { name: 'postcode', labelText: 'Postcode*', trim: true }
  ];

  const initialValues = {};
  for (let i = 0; i < fields.length; i++) {
    initialValues[fields[i].name] = "";
  }

  useEffect(() => {
    // First landing on this page, access the vehicleId from query string
    if (!vehicleId) {
      if (location.search) {
        let s = queryString.parse(location.search);
        if (s.vehicleid) {
          setVehicleId(s.vehicleid);
        }
      } else {
        setFetchError(true);
      }
    }
  }, [location.search]);

  useEffect(() => {
    if (model) {
      let gtmEventCat = isUsed ? "VTP Used" : "VTP";
      gtmPush(gtmEventCat, "EnquiryStart", model);
    }
  }, [vehicleData]);

  const handleSubmit = (values, formikBag) => {

    const { setSubmitting } = formikBag;

    let data = {
      "vehicleId": basicId,
      "firstName": values.firstName,
      "lastName": values.lastName,
      "emailAddress": values.emailAddress,
      "phoneNumber": values.phoneNumber,
      "postcode": values.postcode,
      "commissionNumber": commissionNumber,
      "model": model,
      "dealerId": dealerId,
      "optIn": values.optIn,
      "modelCode": modelCode,
      "modelYear": modelYear,
      "engine": engine,
      "imageUrl": vehicleEdmImage ? vehicleEdmImage.url : "",
      "cost": retailPrice,
      "tradeIn": false,
      "usedCar": isUsed,
      "usedMileage": vehicle.basic?.used?.mileageFormatted ? `${vehicle.basic.used.mileageFormatted}${vehicle.basic.used.mileageUnit}` : null
    };

    setIsPostingData(true);
    request(
      `${process.env.RAZZLE_MYAUDI_API}/1/vtp/enquiry`,
      {
        method: 'POST',
        body: JSON.stringify(data),
      }
    ).then((response) => {
      scrollToTop();
      setIsPostingData(false);
      setSubmitting(false);
      setStep(STEPS.THANKYOU);
      gtmPush("form", "submitSuccess", "vtp-enquiry", data);
    }).catch((error) => {
      if (error.status === 409) {
        setUserAlreadySubmitted(true);
      }
      let msg = "An error has occured";
      if (error.body && error.body.modelState) {
        formikBag.setErrors(error.body.modelState);
        msg = false;
      } else if (error.body && error.body.message) {
        msg = error.body.message;
      }
      setPostError(msg);
      setIsPostingData(false);
      setSubmitting(false);
    });
  };

  const validateForm = (values) => {
    let validation = validate(values, enquirySchema, { format: "firstError", fullMessages: true });
    return validation;
  };

  return (
    <div className="enquiry spinner-wrapper my-3">
      {isPostingData && <LoadingSpinner />}

      {step === STEPS.ENQUIRY &&
        <Formik
          onSubmit={handleSubmit}
          initialValues={initialValues}
          validate={validateForm}
        >
          {formikBag => (
            <Form>
              <section className="order-section">
                <div className="order-section--content">
                  {fields.map(field => {
                    return <FormField
                      key={field.name}
                      name={field.name}
                      label={field.labelText}
                      trim={field.trim}
                      formikBag={formikBag}
                      formatValue={field.formatValue}
                      wrapperClassName="mb-4"
                    />
                  })}
                </div>
              </section>

              <section className="order-section aui-color-gray95">
                <div className="order-section--content">
                  <p className="aui-headline-4 mb-4"><b>Enquiry details</b></p>
                  <div className="container">
                    {isFetchingData && <LoadingSpinner placeholder />}
                    {fetchError &&
                      <p className="aui-color-text-red">
                        Sorry, we appear to be experiencing technical difficulties.
                        Please try reloading the page, or contact your dealer directly.
                      </p>
                    }
                    {dealerName && !isFetchingData && !fetchError &&
                      <div className="d-flex flex-column">
                        <div className="row my-2">
                          <div className="enquiry-details d-flex justify-content-small-end"><b>Stock ID</b>:</div><div className="col">{commissionNumber.indexOf("AA") === 0 ? commissionNumber.substring(2) : commissionNumber}</div>
                        </div>
                        <div className="row my-2">
                          <div className="enquiry-details d-flex justify-content-small-end"><b>Model</b>:</div><div className="col">{model} {isUsed && "(pre-owned)"}</div>
                        </div>
                        {(isUsed && mileage) && <div className="row my-2">
                          <div className="enquiry-details d-flex justify-content-small-end"><b>Mileage</b>:</div><div className="col">{mileage}</div>
                        </div>}
                        <div className="row my-2">
                          <div className="enquiry-details d-flex justify-content-small-end"><b>Dealer</b>:</div><div className="col">{dealerName}</div>
                        </div>
                      </div>
                    }
                  </div>
                </div>
              </section>

              <section className="order-section">
                <div className="order-section--content">
                  <FormField
                    name="optIn"
                    label={`I would like to be contacted by Audi and Audi Dealers with occasional communications on products, offers, news and events.`}
                    formikBag={formikBag}
                    fieldType={FIELD_CHECKBOX}
                    wrapperClassName="mb-4"
                  />

                  {postError &&
                    <p className="aui-color-text-red mb-3">{postError}</p>
                  }

                  <p className="submit">
                    <Button
                      variant="primary"
                      type="submit"
                      className="theme-red"
                      disabled={formikBag.isSubmitting || !formikBag.isValid}>
                      Submit &gt;
                    </Button>
                  </p>
                </div>
              </section>
            </Form>
          )}
        </Formik>
      }

      {step === STEPS.THANKYOU &&
        <section className="order-section">
          <div className="order-section--content">
            <ThankYou
              submitStatus={userAlreadySubmitted ? 4 : 3}
              model={model}
              dealerName={dealerName}
            />
          </div>
        </section>
      }

      <section className="order-section full-width">
        <div className="order-section--content">
          <Privacy />
        </div>
      </section>
    </div>
  );
}

export default Enquiry;
