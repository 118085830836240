import React from 'react';
import classnames from 'classnames';
import CollapsibleSection from '../../components/CollapsibleSection';

class Section extends React.Component {

  renderSubSection = (sub, key) => {
    const {equipment} = this.props;
    return (
      <React.Fragment key={key}>
        {sub.title && <p className="aui-headline-5 my-3">{sub.title}</p>}
        {sub.description && <div className="cms-content" dangerouslySetInnerHTML={{__html: sub.description}} />}
        <div className="row">
          {equipment && sub.equipment && sub.equipment.map((code) => {
            let item = equipment[code];
            if (item) {
              return (
                <div key={code} className="equipment col-12 col-xsmall-6 mb-3 mb-medium-5">
                  <div className="row">
                    <div className="col-4">
                      <div className="responsive-aspect-ratio square equipment-thumb" style={{backgroundImage: item.thumb ? `url(${process.env.RAZZLE_APP_ENV === 'production' ? item.thumb : item.thumbStg})` : ""}} />
                    </div>
                    <div className="col-8">
                      <p className="equipment-name"><b>{item.name}</b></p>
                      <div className="equipment-desc cms-content" dangerouslySetInnerHTML={{__html: item.description}} />
                    </div>
                  </div>
                </div>
              );
            }
            return null;
          })}
        </div>
      </React.Fragment>
    );
  }

  render() {
    const { section, onInteract, layoutUpdate } = this.props;
    return (
      <section className={classnames("order-section", this.props.className, section.className)}>
        <div className="order-section--content">
          <div className="left">
            <p className="aui-headline-4 mb-3"><b>{section.title}</b></p>
            {section.subTitle && <p className="mb-3">{section.subTitle}</p>}
          </div>
          {!section.isCollapsible && <div className="right">
            {section.description && <div className={classnames("desc cms-content", {"mb-3 mb-medium-5": section.subSections})} dangerouslySetInnerHTML={{__html: section.description}}></div>}
            {section.subSections && section.subSections.map((sub, i) => this.renderSubSection(sub, `${section.id}_${i}`))}
          </div>}
          {section.isCollapsible && <div className="right">
            <CollapsibleSection onToggle={() => { onInteract(section.id); layoutUpdate(); }} toggleOnClickVisible visibleContent={(
              <div className="cms-content" dangerouslySetInnerHTML={{__html: section.description}}></div>
            )}>
              {section.subSections && section.subSections.map((sub, i) => this.renderSubSection(sub, `${section.id}_${i}`))}
            </CollapsibleSection>
          </div>}
        </div>
      </section>
    );
  }
}

Section.defaultProps = {
  onInteract: () => {},
  layoutUpdate: () => {}
};

export default Section;
