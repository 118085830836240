import React from 'react';

export default (_props) => {
  const { className, size, large, small, ...props } = _props;

  // small icon
  if ((size && size.toLowerCase() === 'small') || small) {
    return (
      <svg width="24" height="24" viewBox="0 0 24 24" className={`audiicon audiicon-small ${className ? className : ''}`} {...props}>
        <g stroke="currentColor" strokeWidth="1" fill="none" fillRule="evenodd">
          <path d="M18.5,2 C19.8333333,3.66666667 20.8333333,5.66666667 21.5,8 L21.5,17.5 C21.5,18.6045695 20.6045695,19.5 19.5,19.5 L19.5,19.5 C18.3954305,19.5 17.5,18.6045695 17.5,17.5 L17.5,12.5 C17.5,11.3954305 16.6045695,10.5 15.5,10.5 L14.5,10.5"/>
          <path d="M16,22.5 L3,22.5 M14.5,22.5 L14.5,3.5 C14.5,2.3954305 13.6045695,1.5 12.5,1.5 L6.5,1.5 C5.3954305,1.5 4.5,2.3954305 4.5,3.5 L4.5,22.5 M13,10.5 L6,10.5"/>
        </g>
      </svg>
    );
  }

  // large icon
  return (
    <svg width="48" height="48" viewBox="0 0 48 48" className={`audiicon audiicon-large ${className ? className : ''}`} {...props}>
      <g stroke="currentColor" strokeWidth="1" fill="none" fillRule="evenodd">
        <path d="M36.5,4 C40.5,9.15735014 42.5,13.3240168 42.5,16.5 C42.5,19.6759832 42.5,21.3426499 42.5,21.5 L42.5,35.5 C42.5,37.709139 40.709139,39.5 38.5,39.5 L38.5,39.5 C36.290861,39.5 34.5,37.709139 34.5,35.5 L34.5,24.5 C34.5,22.290861 32.709139,20.5 30.5,20.5 L28.5,20.5" strokeLinejoin="bevel"/>
        <path d="M31,44.5 L5,44.5 M28.5,44.5 L28.5,6.5 C28.5,4.84314575 27.1568542,3.5 25.5,3.5 L10.5,3.5 C8.84314575,3.5 7.5,4.84314575 7.5,6.5 L7.5,44.5 M26,20.5 L10,20.5"/>
      </g>
    </svg>
  );
};
