import React from 'react';
import classnames from 'classnames';
import RangeSlider from './RangeSlider';
import TextField from '../audi-ui-components/TextField';
import Select from '../audi-ui-components/Select';
import Button from '../audi-ui-components/Button';
import AFSDisclaimer from './legal/AFSDisclaimer';
import CollapsibleSection from './CollapsibleSection';
import { formatDollar, unFormatDollar, smoothScrollTo, gtmPush } from '../lib/helpers';

// const financeProductOpts = {
//   "personal": [{value: 'consumer', label: 'Consumer Loan'}, {value: 'novated', label: 'Novated Lease'}],
//   "business": [{value: 'novated', label: 'Novated Lease'}, {value: 'chattel', label: 'Chattel Mortgage'}]
// };
const financeTermRange = {
  "consumer": {min: 12, max: 84, default: 60, labels: {12: "12", 84: "84"}},
  "novated": {min: 12, max: 60, default: 60, labels: {12: "12", 60: "60"}},
  "chattel": {min: 12, max: 60, default: 60, labels: {12: "12", 60: "60"}}
};
const financeBalloonRange = {
  "consumer": {
    12: {min: 0, max: 0.5, default: 0.3, labels: {0: "0%", 0.5: "50%"}},
    24: {min: 0, max: 0.45, default: 0.3, labels: {0: "0%", 0.45: "45%"}},
    36: {min: 0, max: 0.4, default: 0.3, labels: {0: "0%", 0.4: "40%"}},
    48: {min: 0, max: 0.35, default: 0.3, labels: {0: "0%", 0.35: "35%"}},
    60: {min: 0, max: 0.3, default: 0.3, labels: {0: "0%", 0.3: "30%"}},
    72: {min: 0, max: 0.01, default: 0, labels: {0: "0%"}, disabled: true},
    84: {min: 0, max: 0.01, default: 0, labels: {0: "0%"}, disabled: true}
  },
  "novated": {
    12: {min: 0.66, max: 0.7, default: 0.66, labels: {0.66: "66%", 0.7: "70%"}},
    24: {min: 0.57, max: 0.65, default: 0.57, labels: {0.57: "57%", 0.65: "65%"}},
    36: {min: 0.47, max: 0.6, default: 0.47, labels: {0.47: "47%", 0.6: "60%"}},
    48: {min: 0.38, max: 0.55, default: 0.45, labels: {0.38: "38%", 0.55: "55%"}},
    60: {min: 0.28, max: 0.5, default: 0.3, labels: {0.28: "28%", 0.5: "50%"}}
  },
  "chattel": {
    12: {min: 0, max: 0.7, default: 0, labels: {0: "0%", 0.7: "70%"}},
    24: {min: 0, max: 0.65, default: 0, labels: {0: "0%", 0.65: "65%"}},
    36: {min: 0, max: 0.55, default: 0, labels: {0: "0%", 0.55: "55%"}},
    48: {min: 0, max: 0.55, default: 0, labels: {0: "0%", 0.55: "55%"}},
    60: {min: 0, max: 0.5, default: 0, labels: {0: "0%", 0.5: "50%"}}
  }
};
const financeFees = {
  "consumer": 398,
  "novated": 1425,
  "chattel": 1425
};

class FinanceCalculator extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      rate: props.isUsed ? 0.0648 : 0.0548,
      deposit: props.initialDeposit || 1000,
      fees: financeFees["novated"],
      term: financeTermRange["novated"].default,
      balloon: financeBalloonRange["novated"][financeTermRange["novated"].default].default,
      financePurpose: "business",
      financeProduct: "novated",
      depositHasFocus: false,
      showBreakdown: false,
      showDetail: false,
      showDisclaimer: false,
      summary: 'total', // 'monthly'
      gtmInteractCart: false,
      gtmInteractFinance: false
    }
    this.refPriceBox = React.createRef();
  }

  scrollToAFSDisclaimer = () => {
    smoothScrollTo(this.refPriceBox.current, this.refPriceBox.current.offsetHeight + this.refPriceBox.current.scrollTop - 100, 300);
    this.onInteractFinance();
  }

  openAFSModal = () => {
    this.props.openModal('afs');
    this.onInteractFinance();
  }

  onInteractFinance = () => {
    if (!this.state.gtmInteractFinance) {
        let gtmEventCat = this.props.isUsed ? "VTP Used" : "VTP"
        gtmPush(gtmEventCat, 'Clicked FinanceTool', this.props.model);
        this.setState({gtmInteractFinance: true});
    }
  }

  render() {
    const {amount, isUsed} = this.props;
    const {rate, deposit, fees, term, balloon, financePurpose, financeProduct} = this.state;
    const financeProductOpts = isUsed ? {
        "personal": [{value: 'consumer', label: 'Consumer Loan'}],
        "business": [{value: 'chattel', label: 'Chattel Mortgage'}]
    } : {
      "personal": [{value: 'consumer', label: 'Consumer Loan'}, {value: 'novated', label: 'Novated Lease'}],
      "business": [{value: 'novated', label: 'Novated Lease'}, {value: 'chattel', label: 'Chattel Mortgage'}]
    };
    var monthlyRepayment = null;
    var finalBalloon = null;
    if (amount) {
      let pv = ((amount - deposit) + fees) - ((amount * balloon) / (Math.pow((1 + (rate / 12)), term)));
      let apf = (rate / 12) / (1 - (Math.pow((1 + (rate / 12)), -term)));
      monthlyRepayment = pv * apf;
      if (financeProduct === "novated" || financeProduct === "chattel") {
        let advFirstInt = 1 / (1 + rate / 12);
        monthlyRepayment = pv * apf * advFirstInt;
      }
      finalBalloon = amount * balloon;
    }
    return (
      <div className="finance-calculator" ref={this.refPriceBox}>
        <p className="aui-headline-4 mb-2"><b>Audi Financial Services</b><sup>VI</sup></p>
        <p className="des-font mb-2">
          <span className="aui-headline-4">{formatDollar(monthlyRepayment, true)} / mo<sup>VII</sup></span><br />
          <span className="small-text">Estimated loan repayment</span>
        </p>
        <p className="des-font mb-2">
          <span className="aui-headline-5">{formatDollar(finalBalloon, true)}<sup>VIII</sup></span><br />
          <span className="small-text">Final balloon repayment</span>
        </p>
        <RangeSlider
          min={0.01} max={0.2} step={0.0001}
          value={this.state.rate}
          label={(<>Interest Rate<sup>IX</sup></>)}
          labels={{0.01: "1%", 0.2: "20%"}}
          onChange={(v) => {
            this.setState({
              rate: parseFloat(v.toFixed(4))
            });
          }}
          onChangeComplete={this.onInteractFinance}
          format={(v) => { return `${(v * 100).toFixed(2)}%`; }}
          className="mb-3"
        />
        <Select
          name="purpose"
          label="Purpose"
          value={financePurpose}
          options={[{value: 'personal', label: 'Personal'}, {value: 'business', label: 'Business'}]}
          onChange={(v) => {
            this.onInteractFinance();
            this.setState({
              financePurpose: v,
              financeProduct: financeProductOpts[v][0].value,
              term: financeTermRange[financeProductOpts[v][0].value].default,
              balloon: financeBalloonRange[financeProductOpts[v][0].value][financeTermRange[financeProductOpts[v][0].value].default].default,
              fees: financeFees[financeProductOpts[v][0].value],
            });
          }}
          className="mb-4"
        />
        <Select
          name="product"
          label="Finance Product"
          value={financeProduct}
          options={financeProductOpts[financePurpose]}
          onChange={(v) => {
            this.onInteractFinance();
            this.setState({
              financeProduct: v,
              term: financeTermRange[v].default,
              balloon: financeBalloonRange[v][financeTermRange[v].default].default,
              fees: financeFees[v],
            });
          }}
          className="mb-4"
        />
        <RangeSlider
          min={financeTermRange[financeProduct].min}
          max={financeTermRange[financeProduct].max}
          step={12}
          value={this.state.term}
          label="Term"
          labels={financeTermRange[financeProduct].labels}
          onChange={(v) => {
            this.setState({
              term: v,
              balloon: financeBalloonRange[financeProduct][v].default
            });
          }}
          onChangeComplete={this.onInteractFinance}
          format={(v) => { return `${v} mths`; }}
          className="mb-3"
        />
        <TextField
          name="deposit"
          label="Deposit"
          className="mb-4"
          value={this.state.depositHasFocus ? this.state.deposit : formatDollar(this.state.deposit)}
          onFocus={() => { this.setState({depositHasFocus: true}); }}
          onBlur={() => { this.setState({depositHasFocus: false}); }}
          onChange={(v) => {
            this.onInteractFinance();
            this.setState({
              deposit: unFormatDollar(v),
            });
          }}
        />
        <RangeSlider
          min={financeBalloonRange[financeProduct][this.state.term].min}
          max={financeBalloonRange[financeProduct][this.state.term].max}
          step={0.01}
          disabled={financeBalloonRange[financeProduct][this.state.term].disabled}
          value={this.state.balloon}
          label="Balloon"
          labels={financeBalloonRange[financeProduct][this.state.term].labels}
          onChange={(v) => { this.setState({balloon: v}); }}
          onChangeComplete={this.onInteractFinance}
          format={(v) => { return `${(v * 100).toFixed(0)}%`; }}
          className="mb-5"
        />
        <Button onClick={this.openAFSModal} className="d-none d-large-block" variant="text">Disclaimer</Button>
        <CollapsibleSection className="d-large-none" toggleOnClickVisible onOpen={this.scrollToAFSDisclaimer} visibleContent={(
          <p>Disclaimer</p>
        )}>
          <AFSDisclaimer />
        </CollapsibleSection>
      </div>
    );
  }
}

export default FinanceCalculator;
