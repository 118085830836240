
const SET_POST_DATA = "DATA/SET_POST_DATA";

export function setPostData(data) {
  return {type: SET_POST_DATA, payload: data};
}

const defaultState = {
  postData: null
};

export function dataReducer(state = defaultState, action) {
  switch (action.type) {
    case SET_POST_DATA:
    return Object.assign({}, state, {
      postData: action.payload
    });
    default:
    return state;
  }
}
