import React from 'react';
import classnames from 'classnames';

export default ({className, style}) => {
  return (
    <div className={classnames("disclaimer", className)} style={style ? style : {}}>
      Audi, a division of Volkswagen Group Australia Pty Ltd, will collect, hold and use your personal information for the purpose(s) set out above. Audi may disclose your personal information to Dealers, related companies or third parties that provide us with services, including to overseas locations (such as Germany). If you consent, Audi and Audi Dealers may also use your personal information to send you communications concerning products, offers, news and events. Please note that Audi and Audi Dealers are separate organisations, and that Dealers will handle your information in accordance with their own privacy policies. Our Privacy Policy explains how you can request access to and the correction of your personal information and how you may make a privacy complaint to us. For full details, see our <a href="http://www.audi.com.au/privacypolicy" target="_blank" rel="noopener noreferrer" className="aui-textlink">Privacy Policy</a>.
    </div>
  )
};
