import React from 'react';

export default (_props) => {
  const { className, size, large, small, ...props } = _props;

  // small icon
  if ((size && size.toLowerCase() === 'small') || small) {
    return (
      <svg width="24" height="24" viewBox="0 0 24 24" className={`audiicon audiicon-small ${className ? className : ''}`} {...props}>
        <g stroke="currentColor" strokeWidth="1" fill="none" fillRule="evenodd">
          <path d="M19.5,10.5 L19.5,17.5 C19.5,18.6045695 18.6045695,19.5 17.5,19.5 L17.5,19.5 C16.3954305,19.5 15.5,18.6045695 15.5,17.5 L15.5,12.5 C15.5,11.3954305 14.6045695,10.5 13.5,10.5 L12.5,10.5"/>
          <path d="M22.5,4.5 L22.5,7.5 C22.5,9.15685425 21.1568542,10.5 19.5,10.5 L19.5,10.5 C17.8431458,10.5 16.5,9.15685425 16.5,7.5 L16.5,4.5 L22.5,4.5 Z M17.5,4.5 L17.5,1 M21.5,4.5 L21.5,1"/>
          <path d="M14,22.5 L1,22.5 M12.5,22.5 L12.5,3.5 C12.5,2.3954305 11.6045695,1.5 10.5,1.5 L4.5,1.5 C3.3954305,1.5 2.5,2.3954305 2.5,3.5 L2.5,22.5 M11,10.5 L4,10.5"/>
        </g>
      </svg>
    );
  }

  // large icon
  return (
    <svg width="48" height="48" viewBox="0 0 48 48" className={`audiicon audiicon-large ${className ? className : ''}`} {...props}>
      <g stroke="currentColor" strokeWidth="1" fill="none" fillRule="evenodd">
        <path d="M40.5,21.5 L40.5,35.5 C40.5,37.709139 38.709139,39.5 36.5,39.5 L36.5,39.5 C34.290861,39.5 32.5,37.709139 32.5,35.5 L32.5,24.5 C32.5,22.290861 30.709139,20.5 28.5,20.5 L26.5,20.5"/>
        <path d="M46.5,9.5 L46.5,15.5 C46.5,18.8137085 43.8137085,21.5 40.5,21.5 C37.1862915,21.5 34.5,18.8137085 34.5,15.5 L34.5,9.5 L46.5,9.5 Z M37.5,9.5 L37.5,4.5 L37.5,9.5 Z M43.5,9.5 L43.5,4.5 L43.5,9.5 Z"/>
        <path d="M29,44.5 L3,44.5 M26.5,44.5 L26.5,6.5 C26.5,4.84314575 25.1568542,3.5 23.5,3.5 L8.5,3.5 C6.84314575,3.5 5.5,4.84314575 5.5,6.5 L5.5,44.5 M24,20.5 L8,20.5"/>
      </g>
    </svg>
  );
};
