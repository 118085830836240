import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import IconArrow from '../audi-ui-components/icons/Forward';

class CollapsibleSection extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            isOpen: props.isOpen || false,
            maxHeight: 800
        }
    }

    componentDidMount() {
        if (this.props.trackClickOutside) {
            document.addEventListener('mousedown', this.handleClickOutside, false);
        }
    }

    componentWillUnmount() {
        if (this.props.trackClickOutside) {
            document.removeEventListener('mousedown', this.handleClickOutside, false);
        }
    }

    componentDidUpdate(prevProps) {
      if (this.props.autoOpen && this.props.isOpen && !this.state.isOpen) {
        this.toggle(true, !this.props.isLocked);
      }
      if (this.props.autoClose && !this.props.isOpen && this.state.isOpen) {
        this.toggle(false, !this.props.isLocked);
      }
      if (this.props.resetHeightProp !== prevProps.resetHeightProp) {
        setTimeout(() => {
          var height = this._content.offsetHeight + 30;
          this.setState({maxHeight: height});
        }, 500);
      }
    }

    toggle = (o, force) => {
        if (!this.props.isLocked || force) {
            var _o = typeof o === 'undefined' ? !this.state.isOpen : o;
            var height = this._content.offsetHeight + 30;
            this.setState({
                isOpen: _o,
                maxHeight: height
            });
            if (typeof this.props.onOpen === 'function' && _o) {
              setTimeout(this.props.onOpen, 500);
            }
            if (this.props.trackClickOutside) {
                if (!_o) { // closing
                    document.removeEventListener('mousedown', this.handleClickOutside, false);
                }
                if (_o) { // opening
                    document.addEventListener('mousedown', this.handleClickOutside, false);
                }
            }
            if (typeof this.props.onToggle === 'function') {
                setTimeout(this.props.onToggle, 500);
            }
        }
    }

    handleClickOutside = (e) => {
        if (this._element && !this._element.contains(e.target)) {
            if (!this.props.isOpen && this.state.isOpen) {
                this.setState({
                    isOpen: false
                });
            }
        }
    }

    render() {
        return (
            <div ref={(el) => this._element = el} className={classnames("collapsible", this.props.className, {"is-collapsed": !this.state.isOpen})} onFocus={this.props.onFocus} onBlur={this.props.onBlur}>
                {this.props.toggleOnClickVisible && <div className="visible-content" onClick={() => {this.toggle();}}>{this.props.visibleContent}</div>}
                {!this.props.toggleOnClickVisible && this.props.visibleContent}
                {!this.state.isOpen && this.props.closedContent && this.props.closedContent}
                <button type="button" onClick={() => {this.toggle();}} className="collapsible-toggle">
                    <IconArrow large />
                </button>
                <div className="collapsible-content" style={{maxHeight: this.state.isOpen ? `${this.state.maxHeight}px` : "0"}}>
                    <div ref={(el) => this._content = el} className="pb-3">
                        {this.props.children}
                    </div>
                </div>
            </div>
        );
    }
}

CollapsibleSection.propTypes = {
    isOpen: PropTypes.bool,
    isLocked: PropTypes.bool,
    onToggle: PropTypes.func,
    onOpen: PropTypes.func,
    autoOpen: PropTypes.bool,
    autoClose: PropTypes.bool,
    toggleOnClickVisible: PropTypes.bool,
    trackClickOutside: PropTypes.bool,
    visibleContent: PropTypes.node,
    closedContent: PropTypes.node,
    onFocus: PropTypes.func,
    onBlur: PropTypes.func,
    children: PropTypes.node
}

export default CollapsibleSection;
