import React from 'react';
import classnames from 'classnames';
import PlacesAutocomplete from 'react-places-autocomplete';
import TextField from '../audi-ui-components/TextField';
import Select from '../audi-ui-components/Select';
import Button from '../audi-ui-components/Button';
import { Helmet } from "react-helmet";

class LocationSearchInput extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      error: false,
      address: '',
      suggestion: null
    };
  }

  handleChange = address => {
    if (this.state.suggestion) {
      this.props.onReset();
    }
    this.setState({ address, suggestion: null });
  };

  handleSelect = (address, placeId, suggestion) => {
    // console.log('handleSelect', address, placeId, suggestion);
    this.setState({ address, suggestion: suggestion });
  };

  handleError = (status, clearSuggestions) => {
    this.setState({error: status});
    clearSuggestions();
  }

  handleClickSearch = () => {
    if (this.state.suggestion) {
      this.props.onSearch(this.state.suggestion.formattedSuggestion.mainText);
    } else if (this.state.error) {
      this.props.onSearch(this.state.address);
    }
  }

  render() {
    return (
      <div className={classnames("location-field", this.props.className)}>
        <PlacesAutocomplete
          value={this.state.address}
          onChange={this.handleChange}
          onSelect={this.handleSelect}
          searchOptions={{
            types: ['(regions)'],
            componentRestrictions: {
                country: 'AU',
            }
          }}
          shouldFetchSuggestions={this.state.address.length > 1}
          debounce={800}
          highlightFirstSuggestion
          googleCallbackName="mapCb"
        >
          {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => {
            const {onChange, ...rest} = getInputProps({label: 'Suburb or Postcode', theme: this.props.theme});
            return (
              <div className="place-field">
                <TextField
                  onChange={(v,e) => {onChange(e);}}
                  {...rest}
                />
                <div className={classnames("autocomplete-dropdown-container", {'has-items': suggestions.length})}>
                  {loading && <div>Loading...</div>}
                  {suggestions.map((suggestion, i) => {
                    let c = suggestion.active
                      ? 'suggestion-item active'
                      : 'suggestion-item';
                    return (
                      <div
                        {...getSuggestionItemProps(suggestion, {
                          className: c
                        })}
                        key={i}
                      >
                        <span>{suggestion.description}</span>
                      </div>
                    );
                  })}
                </div>
              </div>
            );
          }}
        </PlacesAutocomplete>

        <Select name="locationDistance"
          value={this.props.locationDistance}
          options={[{label: "50km", value: "50"}, {label: "100km", value: "100"}, {label: "500km", value: "500"}, { label: "1000km", value: "1000"}]}
          onChange={this.props.onChangeDistance}
          theme={this.props.theme}
        />

        <Button
          variant="primary"
          onClick={this.handleClickSearch}
          disabled={!this.state.suggestion && !this.state.error}
        >
          Search
        </Button>

        <Helmet>
          <script src={`https://maps.googleapis.com/maps/api/js?key=${process.env.RAZZLE_GOOGLE_MAPS_KEY}&libraries=places&callback=mapCb`}></script>
        </Helmet>
      </div>
    );
  }
}

export default LocationSearchInput;
