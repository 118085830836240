import React from 'react';
import PropTypes from 'prop-types';
import Radio from './Radio';
import Fieldset from './Fieldset';

class RadioFieldset extends React.Component {

  render() {
    const {options, value, onChange, label, ...rest} = this.props;
    return (
      <Fieldset title={label} {...rest}
        fields={options.map(opt => <Radio name={opt.name} label={opt.label} value={opt.value} checked={opt.value === value} disabled={opt.disabled} onChange={this.props.onChange} />)}
        fieldType="radios" />
    );
  }

}

RadioFieldset.propTypes = {
  options: PropTypes.array,
  value: PropTypes.any,
  onChange: PropTypes.func,
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  isInvalid: PropTypes.bool,
  invalidMessage: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  style: PropTypes.object,
  className: PropTypes.string,
  theme: PropTypes.oneOf(['light']) // see styles/components/_fieldset-themes.scss
};

export default RadioFieldset;
