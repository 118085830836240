
export const financeSchema = {
  driveAwayPrice: {
    isNumeric: {
      message: "Please enter a number"
    }
    // temporarily removed
    // isValidDriveAwayPrice: {
    //   message: "This offer is not within the acceptable range for the dealership"
    // }
  },
  contactFinance: {
    presence: {
      message: "This field is required",
      allowEmpty: false
    },
    inclusion: {
      within: ['true', 'false'],
      message: "You need to check the checkbox"
    }
  },
  monthyRepayment: {
    financeInformationRequired: {
      message: "This field is required"
    }
  },
  tradeIn: {
    presence: {
      message: "This field is required",
      allowEmpty: false
    },
    inclusion: {
      within: ['true', 'false'],
      message: "You need to check the checkbox"
    }
  },
  tradeMake: {
    tradeinRequired: {
      message: "This field is required"
    }
  },
  tradeModel: {
    type: "string"
  },
  tradeYear: {
    tradeinRequired: {
      message: "This field is required"
    }
  },
  tradeKms: {
    isNumeric: {
      message: "Please enter a number"
    }
  },
  tradeHasFinance: {
    tradeinRequired: {
      message: "This field is required"
    }
  },
};

export const customerSchema = {
  firstName: {
    presence: {
      message: "This field is required",
      allowEmpty: false
    },
    length: {
      minimum: 1,
      message: "Please enter a valid name"
    }
  },
  lastName: {
    presence: {
      message: "This field is required",
      allowEmpty: false
    },
    length: {
      minimum: 2,
      message: "Please enter a valid name"
    }
  },
  email: {
    presence: {
      message: "This field is required",
      allowEmpty: false
    },
    email: {message: "Please enter a valid email address"}
  },
  emailConfirm: {
    presence: {
      message: "This field is required",
      allowEmpty: false
    },
    email: {message: "Please enter a valid email address"},
    equality: {
      attribute: "email",
      message: "Addresses must match",
      comparator: function (v1, v2) {
        return JSON.stringify(v1) === JSON.stringify(v2);
      }
    }
  },
  mobile: {
    presence: {
      message: "This field is required",
      allowEmpty: false
    },
    format: {
      pattern: /^04.*$/,
      message: "Please enter a valid mobile number"
    }
  },
  postcode: {
    presence: {
      message: "This field is required",
      allowEmpty: false
    },
    numericality: {
      onlyInteger: true,
      greaterThan: 0,
      lessThan: 9999,
      message: "Please enter a valid postcode"
    },
    length: {
      minimum: 4,
      maximum: 4,
      message: "Please enter a valid postcode"
    }
  }
};

export const paymentSchema = {
  cardName: {
    presence: {
      message: "Please enter the name of the card holder",
      allowEmpty: false
    }
  },
  // cardNumber: {
  //   presence: {
  //     message: "This field is required",
  //     allowEmpty: false
  //   },
  //   stripeCardNumber: {
  //     message: "Please enter a valid card number"
  //   }
  // },
  // cardCVC: {
  //   presence: {
  //     message: "This field is required",
  //     allowEmpty: false
  //   },
  //   stripeCVC: {
  //     message: "Please enter a valid CVV"
  //   }
  // },
  // cardMonth: {
  //   presence: {
  //     message: "This field is required",
  //     allowEmpty: false
  //   }
  // },
  // cardYear: {
  //   presence: {
  //     message: "This field is required",
  //     allowEmpty: false
  //   }
  // },
  optIn: {
    presence: {
      message: "This field is required",
      allowEmpty: false
    },
    inclusion: {
      within: [true],
      message: "You need to check the checkbox"
    }
  }
};
