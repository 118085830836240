import React from 'react';
import IconCarSearch from '../audi-ui-components/icons/CarSearch';
import IconGroups from '../audi-ui-components/icons/Groups';
import IconNews from '../audi-ui-components/icons/News';

export default () => {
  return (
    <div className="how-to-order">
      <p className="mb-5"><span className="aui-headline-3">You can order your Audi online from your dealer in three easy steps</span><sup>2</sup></p>

      <div className="row mb-5">
        <div className="col-small">
          <div className="text-center"><IconCarSearch large style={{width: '50%', height: 'auto'}} /></div>
          <p className="aui-headline-4 mb-2">Step 1: Select your Audi</p>
          <p>With convenient search filters and numerous cars to choose from, you can find your perfect Audi in minutes.</p>
        </div>
        <div className="col-small">
          <div className="text-center">
            <svg width="48" height="48" viewBox="0 0 48 48" version="1.1" xmlns="http://www.w3.org/2000/svg" style={{width: '50%', height: 'auto'}}>
              <path d="M6.5,9.5 L41.5,9.5 C42.6045695,9.5 43.5,10.3954305 43.5,11.5 L43.5,36.5 C43.5,37.6045695 42.6045695,38.5 41.5,38.5 L6.5,38.5 C5.3954305,38.5 4.5,37.6045695 4.5,36.5 L4.5,11.5 C4.5,10.3954305 5.3954305,9.5 6.5,9.5 Z M25,33.5 L39,33.5 M4.5,17.5 L43.5,17.5" stroke="currentColor" strokeWidth="1" fill="none" fillRule="evenodd" />
            </svg>
          </div>
          <p className="aui-headline-4 mb-2">Step 2: Order your car</p>
          <p>Complete the simple online form and secure your Audi with a refundable $500 holding fee. If you change your mind, you can cancel the online order prior to entering into a purchase agreement with your dealer and receive a full refund of your holding fee.<sup>3</sup></p>
        </div>
        <div className="col-small">
          <div className="text-center"><IconGroups large style={{width: '50%', height: 'auto'}} /></div>
          <p className="aui-headline-4 mb-2">Step 3: Connect with your dealer</p>
          <p>Your dealer will be in contact to confirm availability of your chosen vehicle and guide you through the process, either online or face to face, whichever is your preference. They can also help you manage any finance or trade in requirements you may have.</p>
        </div>
      </div>

      <p className="mb-2">
        <a href="https://www.audi.com.au/au/web/en/audi-new-car-search/layer/faq.html" target="_blank" rel="noopener noreferrer">
          <IconNews small style={{verticalAlign: 'text-bottom'}} /> Online Ordering FAQs
        </a>
      </p>
      <p>
        <a href="https://www.audi.com.au/au/web/en/terms-and-conditions/online-reservation.html" target="_blank" rel="noopener noreferrer">
          <IconNews small style={{verticalAlign: 'text-bottom'}} /> Terms and Conditions
        </a>
      </p>

      <div className="py-5 disclaimer">
        <p className="mb-2"><sup>2</sup>Audi Australia is not party to your interactions with your chosen dealer, is not party to any transactions entered into between you and dealer (including payment or refund of the holding fee and any final sale) and does not warrant the accuracy of any representations made or information provided to you by dealer in response to your Online Order made via the Online Order Form. Audi Australia Pty Ltd does not warrant the accuracy of any of this information as applicable to your particular circumstances and to the extent permitted by law is not liable for any claims or losses of any nature, including, but not limited to, damages, expenses, lost profits, punitive, special, indirect or consequential losses/damages caused by reliance on this information.</p>
        <p><sup>3</sup>Subject to availability. See terms and conditions for further information on the refund of your holding fee. Prices displayed are the Manufacturer's Recommended Drive Away Price for the individual vehicles (including optional equipment fitted)¹. Contact the selling dealer for details on any specific vehicle, final dealer pricing, or financing information.</p>
      </div>

    </div>
  );
};
