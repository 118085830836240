import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import Carousel from '../../components/Carousel';
import Spinner from '../../audi-ui-components/Spinner';
import IconSelect from '../../audi-ui-components/icons/Select';

class HeroSection extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      activeSlideIdx: 0,
      gtmInteract: false,
      intersect: true
    }
    this.refIntersectEl = React.createRef();
  }

  componentDidMount() {
    if (this.props.stickyEnabled) {
      const observer = new IntersectionObserver((entries) => {
        this.setState({intersect: entries[0]['isIntersecting']});
      }, { threshold: [0, 0.5, 1] });
      observer.observe(this.refIntersectEl.current);
    }
  }

  getSlides = () => {
    const {vin, loading, carouselImages} = this.props;
    if (loading) {
      return [(
        <div key="loaderslide" className="">
          <div className="responsive-aspect-ratio wide active"><Spinner isContinuous /></div>
        </div>
      )];
    }
    const extViews = ["n3c01", "n3c14", "n3c12", "n3c11", "n3c03"];
    const intViews = ["n4n05", "n4n06"];
    var slides = [];
    for (let i=0; i<extViews.length; i++) {
      slides.push(
        <div key={`ext_${i}`} className="xfade-img-wrap">
          <div className="xfade-img responsive-aspect-ratio wide" style={{backgroundImage: `url('https://mediaservice.audi.com/media/live/${extViews[i]}/${vin}')`}} />
        </div>
      );
    }
    for (let i=0; i<intViews.length; i++) {
      slides.push(
        <div key={`int_${i}`} className="xfade-img-wrap">
          <div className="xfade-img responsive-aspect-ratio wide interior" style={{backgroundImage: `url('https://mediaservice.audi.com/media/live/${intViews[i]}/${vin}')`}} />
        </div>
      );
    }
    if (carouselImages && carouselImages.length) {
      // additional images to be added to the carousel
      for (let i=0; i<carouselImages.length; i++) {
        let img = carouselImages[i];
        let imgStyle = {backgroundImage: `url('${process.env.RAZZLE_APP_ENV === 'production' ? img.src : img.srcStg}')`};
        if (img.style) {
          imgStyle = Object.assign(imgStyle, img.style);
        }
        slides.splice(img.position, 0, (
          <div key={`add_${i}`} className="xfade-img-wrap">
            <div className="xfade-img responsive-aspect-ratio wide" style={imgStyle} />
          </div>
        ));
      }
    }
    return slides;
  }

  handleClickSlide = (idx) => {
    this.setState({activeSlideIdx: idx});
  }

  render() {
    const slides = this.getSlides();
    return (
      <div className={classnames("order-section order-section__colour order-section__exterior aui-color-gray95 padded", {"use-sticky": this.props.stickyEnabled, "sticky": !this.state.intersect})}>
        <div ref={this.refIntersectEl} />
        <div className="order-section--content">

          <div className="right">
            {this.props.stickyEnabled && <div className="carousel-placeholder"><div className="responsive-aspect-ratio wide" /></div>}
            <div className="carousel-fixed-wrap">
              <div className="carousel-main-wrap">
                <div className="carousel-section-wrap">
                  <div className="carousel-right-wrap">
                    <div className="carousel-wrap">
                      <Carousel className="colour-carousel" transitionMode="fade" slideIndex={this.state.activeSlideIdx} setSlide={this.handleClickSlide}>
                        {slides.map((slide) => slide)}
                      </Carousel>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {slides.length > 1 &&
              <div className="row mx-n1 mx-small-2 mx-medium-n3 mt-2 colour-carousel-nav">
                {slides.map((slide, i) => { return (
                  <div key={`nav_${i}`} className="col px-1 px-small-2 px-medium-3">
                    <div className={classnames("nav-item", {"active": i === this.state.activeSlideIdx})} onClick={() => {this.handleClickSlide(i);}}>{slide}</div>
                  </div>
                ); })}
              </div>
            }

          </div>

        </div>
      </div>
    );
  }
}

HeroSection.propTypes = {
  vin: PropTypes.string,
  stickyEnabled: PropTypes.bool,
  loading: PropTypes.bool,
}

export default HeroSection;
