// https://github.com/appigram/react-rangeslider/blob/master/src/Rangeslider.js
import cx from 'classnames';
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ResizeObserver from 'resize-observer-polyfill';

const capitalize = (str) => {
  return str.charAt(0).toUpperCase() + str.substr(1)
};

const clamp = (value, min, max) => {
  return Math.min(Math.max(value, min), max)
};

class RangeSlider extends Component {
  static propTypes = {
    min: PropTypes.number,
    max: PropTypes.number,
    step: PropTypes.number,
    value: PropTypes.number,
    labels: PropTypes.object,
    handleLabel: PropTypes.string,
    format: PropTypes.func,
    disabled: PropTypes.bool,
    onChangeStart: PropTypes.func,
    onChange: PropTypes.func,
    onChangeComplete: PropTypes.func
  }

  static defaultProps = {
    min: 0,
    max: 100,
    step: 1,
    value: 0,
    labels: {},
    handleLabel: ''
  }

  constructor (props, context) {
    super(props, context)
    this.state = {
      active: false,
      limit: 0,
      grab: 0
    }
  }

  componentDidMount () {
    this.handleUpdate();
    const resizeObserver = new ResizeObserver(this.handleUpdate);
    resizeObserver.observe(this.slider);
  }

  /**
   * Format value
   * @param  {Number} - value
   * @return {Formatted Number}
   */
  handleFormat = value => {
    const { format } = this.props;
    return format ? format(value) : value;
  };

  /**
   * Update slider state on change
   * @return {void}
   */
  handleUpdate = () => {
    if (!this.slider) {
      // for shallow rendering
      return;
    }
    const sliderPos = this.slider.offsetWidth;
    const handlePos = this.handle.offsetWidth;

    this.setState({
      limit: sliderPos - handlePos,
      grab: handlePos / 2
    })
  };

  /**
   * Attach event listeners to mousemove/mouseup events
   * @return {void}
   */
  handleStart = e => {
    if (this.props.disabled) { return; }
    const { onChangeStart } = this.props;
    document.addEventListener('mousemove', this.handleDrag);
    document.addEventListener('mouseup', this.handleEnd);
    this.setState(
      {
        active: true
      },
      () => {
        onChangeStart && onChangeStart(e);
      }
    )
  };

  /**
   * Handle drag/mousemove event
   * @param  {Object} e - Event object
   * @return {void}
   */
  handleDrag = e => {
    if (this.props.disabled) { return; }
    e.stopPropagation();
    const { onChange } = this.props;
    const { target: { className, classList, dataset } } = e;
    if (!onChange || className === 'rangeslider__labels') return;
    let value = this.position(e);
    if (
      classList &&
      classList.contains('rangeslider__label-item') &&
      dataset.value
    ) {
      value = parseFloat(dataset.value);
    }
    onChange && onChange(value, e)
  };

  /**
   * Detach event listeners to mousemove/mouseup events
   * @return {void}
   */
  handleEnd = e => {
    if (this.props.disabled) { return; }
    const { onChangeComplete } = this.props;
    this.setState(
      {
        active: false
      },
      () => {
        onChangeComplete && onChangeComplete(e);
      }
    )
    document.removeEventListener('mousemove', this.handleDrag);
    document.removeEventListener('mouseup', this.handleEnd);
  };

  /**
   * Support for key events on the slider handle
   * @param  {Object} e - Event object
   * @return {void}
   */
  handleKeyDown = e => {
    e.preventDefault();
    if (this.props.disabled) { return; }
    const { keyCode } = e;
    const { value, min, max, step, onChange } = this.props;
    let sliderValue;

    switch (keyCode) {
      case 38:
      case 39:
        sliderValue = value + step > max ? max : value + step;
        onChange && onChange(sliderValue, e);
        break;
      case 37:
      case 40:
        sliderValue = value - step < min ? min : value - step;
        onChange && onChange(sliderValue, e);
        break;
    }
  };

  /**
   * Calculate position of slider based on its value
   * @param  {number} value - Current value of slider
   * @return {position} pos - Calculated position of slider based on value
   */
  getPositionFromValue = value => {
    const { limit } = this.state;
    const { min, max } = this.props;
    const diffMaxMin = max - min;
    const diffValMin = value - min;
    const percentage = diffValMin / diffMaxMin;
    const pos = Math.round(percentage * limit);
    return pos;
  };

  /**
   * Translate position of slider to slider value
   * @param  {number} pos - Current position/coordinates of slider
   * @return {number} value - Slider value
   */
  getValueFromPosition = pos => {
    const { limit } = this.state;
    const { min, max, step } = this.props;
    const percentage = clamp(pos, 0, limit) / (limit || 1);
    const baseVal = step * Math.round(percentage * (max - min) / step);
    const value = baseVal + min;
    return clamp(value, min, max)
  };

  /**
   * Calculate position of slider based on value
   * @param  {Object} e - Event object
   * @return {number} value - Slider value
   */
  position = e => {
    const { grab } = this.state;
    const node = this.slider;
    const coordinate = !e.touches ? e.clientX : e.touches[0].clientX;
    const direction = node.getBoundingClientRect().left;
    const pos = coordinate - direction - grab;
    const value = this.getValueFromPosition(pos);
    return value;
  };

  /**
   * Grab coordinates of slider
   * @param  {Object} pos - Position object
   * @return {Object} - Slider fill/handle coordinates
   */
  coordinates = pos => {
    const { limit, grab } = this.state;
    const value = this.getValueFromPosition(pos);
    const position = this.getPositionFromValue(value);
    const handlePos = position + grab;
    const fillPos = handlePos;
    return {
      fill: fillPos,
      handle: handlePos,
      label: handlePos
    };
  };

  renderLabels = labels => (
    <ul
      ref={sl => { this.labels = sl }}
      className={cx('rangeslider__labels')}
    >
      {labels}
    </ul>
  );

  render () {
    const {
      value,
      className,
      label,
      labels,
      min,
      max
    } = this.props;
    const { active } = this.state;
    const position = this.getPositionFromValue(value);
    const coords = this.coordinates(position);
    const fillStyle = { width: `${coords.fill}px` };
    const handleStyle = { left: `${coords.handle}px` };

    const labelItems = [];
    let labelKeys = Object.keys(labels);
    if (labelKeys.length > 0) {
      labelKeys = labelKeys.sort((a, b) => (b - a));
      for (const key of labelKeys) {
        const labelPosition = this.getPositionFromValue(key);
        const labelCoords = this.coordinates(labelPosition);
        const labelStyle = { left: `${labelCoords.label}px` };
        labelItems.push(
          <li
            key={key}
            className={cx('rangeslider__label-item')}
            data-value={key}
            onMouseDown={this.handleDrag}
            onTouchStart={this.handleStart}
            onTouchEnd={this.handleEnd}
            style={labelStyle}
          >
            {this.props.labels[key]}
          </li>
        );
      }
    }

    return (
      <div className={cx("rangeslider-wrapper", className, {"disabled": this.props.disabled})}>
        <div className="row">
          <div className="col">{this.props.label}</div>
          <div className="col-auto"><b>{this.handleFormat(value)}</b></div>
        </div>
        <div
          ref={s => {
            this.slider = s
          }}
          className={cx(
            'rangeslider',
            'rangeslider-horizontal',
            {'rangeslider-haslabels': labelKeys.length > 0}
          )}
          onMouseDown={this.handleDrag}
          onMouseUp={this.handleEnd}
          onTouchStart={this.handleStart}
          onTouchEnd={this.handleEnd}
          aria-valuemin={min}
          aria-valuemax={max}
          aria-valuenow={value}
          aria-orientation={'horizontal'}
        >
          <div className='rangeslider__fill' style={fillStyle} />
          <div
            ref={sh => {
              this.handle = sh
            }}
            className='rangeslider__handle'
            onMouseDown={this.handleStart}
            onTouchMove={this.handleDrag}
            onTouchEnd={this.handleEnd}
            onKeyDown={this.handleKeyDown}
            style={handleStyle}
            tabIndex={0}
          >
          </div>
          {labels && this.renderLabels(labelItems)}
        </div>
      </div>
    );
  }
}

export default RangeSlider;
