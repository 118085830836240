import React from 'react';
import PropTypes from 'prop-types';
import NukaCarousel from 'nuka-carousel';
import ArrowBack from '../audi-ui-components/icons/Back';
import ArrowForward from '../audi-ui-components/icons/Forward';
// import ResizeObserver from 'resize-observer-polyfill';

class Carousel extends React.Component {

  // constructor(props) {
  //   super(props);
  //   this.refWrapper = React.createRef();
  //   this.refCarousel = React.createRef();
  // }

  // componentDidMount() {
  //   const resizeObserver = new ResizeObserver(() => {
  //     if (this.refCarousel.current) {
  //       this.refCarousel.current.setDimensions(null);
  //     }
  //   });
  //   resizeObserver.observe(this.refWrapper.current);
  // }

  render() {
        const isControlled = typeof this.props.slideIndex !== 'undefined' && typeof this.props.setSlide === 'function';

        if (isControlled) {
            const {children, slideIndex, setSlide, controlColour, onInteract, ...rest} = this.props;
            return (
              <div>
                <NukaCarousel
                    slideIndex={slideIndex}
                    {...rest}
                    afterSlide={(s) => { setSlide(s); onInteract(); }}
                    renderCenterLeftControls={({previousSlide, currentSlide, slideCount}) => (
                        <button
                          style={{color: controlColour}}
                            onClick={() => {
                                let n = slideIndex - 1;
                                if (n < 0) { n = slideCount - 1; }
                                setSlide(n);
                            }}
                            disabled={!this.props.wrapAround && slideIndex === 0}>
                            <ArrowBack large />
                        </button>
                    )}
                    renderCenterRightControls={({nextSlide, currentSlide, slideCount}) => (
                        <button
                          style={{color: controlColour}}
                            onClick={() => {
                                let n = slideIndex + 1;
                                if (n >= slideCount) { n = 0; }
                                setSlide(n);
                            }}
                            disabled={!this.props.wrapAround && currentSlide + this.props.slidesToShow === slideCount}>
                            <ArrowForward large />
                        </button>
                    )}
                    renderBottomCenterControls={() => (<span />)}
                >
                    {this.props.children}
                </NukaCarousel>
              </div>
            );
        }
        else {
            const {children, controlColour, onInteract, ...rest} = this.props;
            return (
              <div>
                <NukaCarousel
                    afterSlide={onInteract}
                    {...rest}
                    renderCenterLeftControls={({previousSlide, currentSlide, slideCount}) => (
                    <button onClick={previousSlide} disabled={!this.props.wrapAround && currentSlide === 0} style={{color: controlColour}}><ArrowBack large /></button>
                    )}
                    renderCenterRightControls={({nextSlide, currentSlide, slideCount}) => (
                    <button onClick={nextSlide} disabled={!this.props.wrapAround && currentSlide + this.props.slidesToShow === slideCount} style={{color: controlColour}}><ArrowForward large /></button>
                    )}
                    renderBottomCenterControls={() => (<span />)}
                >
                    {this.props.children}
                </NukaCarousel>
              </div>
            );
        }
    }
}

Carousel.propTypes = {
    slidesToShow: PropTypes.number,
    autoplay: PropTypes.bool,
    heightMode: PropTypes.string,
    transitionMode: PropTypes.oneOf(['scroll', 'fade']),
    wrapAround: PropTypes.bool,
    setSlide: PropTypes.func,
    slideIndex: PropTypes.number,
    className: PropTypes.string,
    controlColour: PropTypes.string,
    onInteract: PropTypes.func,
    children: PropTypes.node
}

Carousel.defaultProps = {
    slidesToShow: 1,
    heightMode: 'first',
    transitionMode: 'scroll',
    wrapAround: true,
    controlColour: '#000',
    onInteract: () => {}
}

export default Carousel;
