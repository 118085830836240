import React from 'react';
import Button from '../audi-ui-components/Button';
import Select from '../audi-ui-components/Select';
import Modal from '../audi-ui-components/Modal';
import IconForward from '../audi-ui-components/icons/Forward';
import IconPoi from '../audi-ui-components/icons/Poi';
import IconPower from '../audi-ui-components/icons/Power';
import IconFuel from '../audi-ui-components/icons/FuelStation';
import IconCharge from '../audi-ui-components/icons/ChargingStation';
import IconTime from '../audi-ui-components/icons/Time';
import IconSearch from '../audi-ui-components/icons/Search';
import IconCancel from '../audi-ui-components/icons/Cancel';
import LoadingSpinner from '../components/LoadingSpinner';
import HowToOrder from '../components/HowToOrder';
import LocationSearchInput from '../components/LocationSearchInput';
import { request, requestJsonFile } from '../lib/apiRequestWrapper';
import { formatDollar, gtmPush } from '../lib/helpers';
import _find from 'lodash/find';

import { MODELS_DROPDOWN, MODELS_SEARCH } from '../constants';
const STATUS_CODES = {
  "B": "Arriving soon",
  "D": "Arriving soon",
  "F": "Arriving soon",
  "5": "Arriving soon"
};
const stateOpts = [
  {value: 'ACT', label: 'ACT'},
  {value: 'NSW', label: 'New South Wales'},
  {value: 'NT', label: 'Northern Territory'},
  {value: 'QLD', label: 'Queensland'},
  {value: 'SA', label: 'South Australia'},
  {value: 'TAS', label: 'Tasmania'},
  {value: 'VIC', label: 'Victoria'},
  {value: 'WA', label: 'Western Australia'}
];

class VehicleList extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      activeModal: null,
      loading: true,
      spinnerType: "placeholder",
      unavailable: false,
      unavailableMessage: "The all-new Audi e-tron and e-tron Sportback have arrived. Find your perfect Audi at our <a href='https://www.audi.com.au/au/web/en/audi-new-car-search/results.html' class='aui-textlink'>vehicle stock locator</a>, with the option to order online.",
      apiList: null,
      modelList: null,
      count: 0,
      errorStatus: null,
      selectedCarline: "",
      selectedVariant: "",
      locationSearch: "",
      locationDistance: "500",
      selectedSort: "prices/driveAwayPrice asc"
    };
  }

  componentDidMount() {
    setTimeout(() => {window.scrollTo(0, 0);}, 50);
    // get json
    const {match} = this.props;
    if (match.params && match.params.modelName) {
      requestJsonFile(
        `${process.env.PUBLIC_PATH}models/${match.params.modelName}.json`
      ).then((jsonData) => {
        if (jsonData && jsonData.unavailable) {
          this.setState({unavailable: true, unavailableMessage: jsonData.unavailableMessage, loading: false});
        } else {
          this.fetchModelList();
        }
      }).catch((error) => {
        console.error(error);
        this.fetchModelList();
      });
    } else {
      this.fetchModelList();
    }
  }

  componentDidUpdate(prevProps) {
    const {match} = this.props;
    let slug = match.params.modelName ? match.params.modelName.toLowerCase() : "";
    let prevSlug = prevProps.match.params.modelName ? prevProps.match.params.modelName.toLowerCase() : "";
    if (slug !== prevSlug) {
      this.fetchData();
    }
  }

  fetchModelList = () => {
    request(
      `${process.env.RAZZLE_API}/1/service/modelList`,
      {
        method: "POST"
      }
    ).then(res => {
      // console.log(res);
      if (res && res.length > 0) {
        var modelList = res.map(m => {
          let variants = m.variants.map(v => {
            return {label: v.variantName, value: v.variantId};
          });
          variants.unshift({label: "all variants", value: "all"});
          return {label: m.modelName, value: m.modelName.toLowerCase().replace(" ", "-"), id: m.modelId, variants: variants};
        });
        let carline = this.props.match.params.modelName ? this.props.match.params.modelName.toLowerCase() : "";
        let selectedCarline = "";
        let found = true;
        if (carline) {
          found = _find(modelList, {value: carline}) || false;
          if (found) {
            selectedCarline = carline;
          }
        }
        this.setState({modelList: modelList, selectedCarline: selectedCarline}, this.fetchData);
        if (!found) {
          this.props.history.push("/");
        }
      } else {
        // no models available
        this.setState({unavailable: true, loading: false});
      }
    }).catch((error) => {
      console.error(error);
    });
  }

  fetchData = (_skip, _sort, _variant) => {
    const {apiList, modelList, count, selectedVariant, locationSearch, locationDistance} = this.state;
    const {match} = this.props;
    let skip = _skip || 0;
    let sort = _sort || this.state.selectedSort;
    let filter = "";
    let filters = [];
    let carline = this.state.selectedCarline;
    if (carline) {
      let modelId = _find(modelList, {value: carline}).id || false;
      if (modelId) {
        filters.push(`model/modelId eq ${modelId}`);
      }
    }
    let variant = _variant || selectedVariant;
    if (variant && variant !== "all") {
      filters.push(`model/variantId eq ${variant}`);
    }
    let loc = "";
    if (locationSearch) {
      loc = `&keyword=${locationSearch}&distance=${locationDistance}`;
    }
    if (filters.length) {
      let f = filters.join(' and ');
      filter = `&$filter=${f}`;
    }
    let q = `?pageSize=12&$top=12&$skip=${skip}&$count=true&$orderby=${sort}${filter}${loc}`;
    request(
      `${process.env.RAZZLE_API}/1/service/carstocks${q}`
    ).then((res) => {
      if (!res.items || res.items.length === 0) {
        throw {status: 404};
      }
      let newList = skip > 0 ? apiList.concat(res.items) : res.items;
      this.setState({
        errorStatus: null,
        apiList: newList,
        count: res.count,
        loading: false
      });
    }).catch((error) => {
      console.error(error);
      let newList = skip > 0 ? apiList : [];
      let newCount = skip > 0 ? count : 0;
      this.setState({
        errorStatus: error.status,
        apiList: newList,
        count: newCount,
        loading: false
      });
    });
  }

  loadMore = () => {
    this.setState({loading: true, spinnerType: "placeholder"});
    const {apiList} = this.state;
    this.fetchData(apiList.length);
  }

  selectCarline = (v) => {
    this.setState({loading: true, spinnerType: "overlay", selectedCarline: v, selectedVariant: ""});
    this.props.history.push(`/${v}`);
  }

  selectVariant = (v) => {
    this.setState(
      {loading: true, spinnerType: "overlay", selectedVariant: v},
      this.fetchData
    );
  }

  selectSort = (v) => {
    this.setState(
      {loading: true, spinnerType: "overlay", selectedSort: v},
      this.fetchData
    );
  }

  onClickSearch = (v) => {
    this.setState(
      {loading: true, spinnerType: "overlay", locationSearch: v},
      this.fetchData
    );
  }

  resetSearch = () => {
    const {match} = this.props;
    this.setState({
      loading: true,
      apiList: null,
      spinnerType: "placeholder",
      selectedCarline: match.params.modelName ? match.params.modelName.toLowerCase() : "",
      selectedVariant: "",
      locationSearch: "",
      locationDistance: "500",
      selectedSort: "prices/driveAwayPrice asc"
    }, this.fetchData);
  }

  scrollToDisclaimer = (el) => {
    var elmnt = document.getElementById(el);
    elmnt.scrollIntoView();
  }

  goToDetailPage = (n, modelId) => {
    const {history, match} = this.props;
    let slug = match.params.modelName ? match.params.modelName.toLowerCase() : false;
    if (!slug) {
      let carline = _find(this.state.modelList, {id: modelId});
      slug = carline ? carline.value : "";
    }
    history.push(`/${slug}/${n}`);
  }

  render() {

    if (this.state.unavailable) {
      return (
        <div className="sc-list spinner-wrapper pt-4">
          <div className="main-inner">
            <h1 className="aui-headline-3 mb-5"><b>Cars in build</b></h1>
            <div className="mb-5 cms-content" style={{minHeight: "300px"}} dangerouslySetInnerHTML={{__html: this.state.unavailableMessage}} />
          </div>
        </div>
      );
    }

    const {apiList, modelList, selectedCarline, selectedVariant, count, loading, errorStatus} = this.state;
    const {match} = this.props;
    // const slug = match.params.modelName.toLowerCase();
    const variantOpts = (modelList && selectedCarline) ? _find(modelList, {value: selectedCarline}).variants : false;
    return (
      <div className="sc-list spinner-wrapper pt-4">
        <div className="main-inner">

          <h1 className="aui-headline-3 mb-5"><b>Cars in build</b></h1>

          <section className="mb-3 aui-color-gray30 aui-color-text-light" style={{zIndex: '300'}}>
            <div className="row align-items-center">
              <div className="col-12 col-medium-auto pb-2 pb-medium-0">
                <div className="select-model d-flex ps-2">
                  {modelList && <Select label="Select model"
                    options={modelList}
                    value={selectedCarline}
                    onChange={(v) => {
                      this.selectCarline(v);
                      let opt = _find(modelList, {value: v});
                      gtmPush("VTP", `Select model - ${opt.label}`, opt.label);
                    }}
                    theme="light"
                  />}
                  {variantOpts && <Select label="Select variant"
                    options={variantOpts}
                    value={selectedVariant}
                    onChange={(v) => {
                      this.selectVariant(v);
                      let opt = _find(variantOpts, (o) => { return o.value.toString() === v.toString(); });
                      gtmPush("VTP", `Select variant - ${opt.label}`, opt.label);
                    }}
                    theme="light"
                  />}
                </div>
              </div>
              <div className="col-12 col-medium">

                <LocationSearchInput
                  theme="light"
                  className="ps-2"
                  onSearch={(v) => {
                    this.onClickSearch(v);
                    let gtmLabel = null;
                    if (selectedVariant) {
                      let opt = _find(variantOpts, (o) => { return o.value.toString() === selectedVariant.toString(); });
                      gtmLabel = opt.label;
                    } else if (selectedCarline) {
                      gtmLabel = selectedCarline;
                    }
                    gtmPush("VTP", `SelectLocation - ${v} - ${this.state.locationDistance}km`, gtmLabel);
                  }}
                  onReset={() => { this.setState({locationSearch: ""}); }}
                  locationDistance={this.state.locationDistance}
                  onChangeDistance={(v) => { this.setState({locationDistance: v}); }}
                />

              </div>
            </div>
          </section>

          <div className="mb-3 mb-medium-4">
            {count > 0 && <b>{count} available to order</b>}
          </div>

          <section className="intro">
            <p>
              Order your perfect Audi online and secure it with a refundable holding fee.
              Here you can find available dealer cars<sup onClick={() => { this.scrollToDisclaimer("disc-avail"); }}>*</sup> that are currently in-build at the factory.
            </p>
            <p>
              <Button variant="text" onClick={() => { this.setState({activeModal: 'howtoorder'}); }}>
                How to order online
              </Button>
            </p>
          </section>

          <section className="sc-sort container-fluid mb-1 mt-n1">
            <div className="row justify-content-end">
              <div className="col-auto">
                <Select label="Sort by"
                  value={this.state.selectedSort}
                  onChange={this.selectSort}
                  options={[
                    {label: "Price ascending", value: "prices/driveAwayPrice asc"},
                    {label: "Price descending", value: "prices/driveAwayPrice desc"},
                    {label: "Model ascending", value: "model/modelName asc"},
                    {label: "Model descending", value: "model/modelName desc"}
                  ]}
                />
              </div>
            </div>
          </section>

          <div className="row mx-xsmall-n4">
            {apiList && apiList.map(car => {
              let imgStyle = {};
              if (car.vin) {
                imgStyle.backgroundImage = `url("https://mediaservice.audi.com/media/live/sc4o01/${car.vin}?wid=800")`;
              }
              return (
                <div className="col-12 col-xsmall-6 col-medium-4 px-xsmall-4" key={car.vtpStockId}>
                  <div className="sc-car-tile py-3 mb-3">
                    <div className="sc-car-tile__image" style={imgStyle} onClick={() => { this.goToDetailPage(car.commissionNumber, car.model.modelId); }} />
                    <div className="sc-car-tile__info">
                      <p className="title">{car.model.variantName}</p>
                      <p className="description">{car.model.engineName}</p>
                      <p className="price">{formatDollar(car.prices.driveAwayPrice)}<sup onClick={() => { this.scrollToDisclaimer("disc-price"); }}>^</sup></p>
                      <div className="d-flex flex-wrap">
                        <div className="dealer mr-4 mb-3 text-nowrap"><IconPoi small /> {car.dealer.dealerName}</div>
                        <div className="me-4 mb-3 text-nowrap"><IconTime small /> {STATUS_CODES[car.statusCode]}</div>
                        <div className="me-4 mb-3 text-nowrap"><IconPower small /> {car.model.enginePower}</div>
                        <div className="me-4 mb-3 text-nowrap">
                          {car.model.engineType === "Electric" ? <IconCharge small /> : <IconFuel small />}
                          {` `}
                          {car.model.engineType}
                        </div>
                      </div>
                      <Button variant="primary"
                        isStretched
                        onClick={() => { this.goToDetailPage(car.commissionNumber, car.model.modelId); }}
                      >
                        View details
                      </Button>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>

          {errorStatus && <div className="text-center py-5 px-2 px-small-5">
            {errorStatus === 404 && <>
              {selectedCarline === "e-tron" && <>
                <p className="mb-3">Availability of e-tron models is limited. Contact your nearest e-tron dealer to discuss options for sourcing a specific car.</p>
                <Button href="https://www.audi.com.au/au/web/en/e-tron-dealers.html" variant="primary" className="mb-3">Find an e-tron dealer</Button>{` `}
              </>}
              {selectedCarline !== "e-tron" && <p className="mb-3 aui-color-text-red">Sorry, we couldn't find any vehicles.</p>}
            </>}
            {errorStatus !== 404 && <p className="mb-3">An error has occured.</p>}
            <Button variant="secondary" onClick={this.resetSearch} className="mb-3">Reset search</Button>
          </div>}

          {loading && <LoadingSpinner type={this.state.spinnerType} />}

          <div className="text-center my-5">
            {!loading && apiList && apiList.length < count &&
              <Button icon={<IconForward small />} iconPosition="right" onClick={this.loadMore} variant="primary">More</Button>
            }
          </div>

        </div>

        <Modal isActive={this.state.activeModal === 'howtoorder'} variant="layer" closeButton close={() => { this.setState({activeModal: null}); }}>
          <HowToOrder />
        </Modal>
      </div>
    );
  }
}

export default VehicleList;
