import React from 'react';
import classnames from 'classnames';

export default ({className, style, symbol}) => {
  return (
    <div id="disc-price" className={classnames("disclaimer", className)} style={style ? style : {}}>
      <sup>{symbol}</sup> Manufacturer's Recommended Drive Away Price (MRDP) is an estimated price for the vehicle in the configuration and with each of the options and other features installed in the vehicle as available for sale from the listed dealer (includes Recommended Dealer Delivery charge, Government taxes and statutory charges) at the time of publishing and based on the State or Territory where the Dealer is located. If you register the vehicle in a different State or Territory, the MRDP may vary from that above as a result of differences in price components such as stamp duty or registration fees. The MRDP is based on the owner being a ‘rating one’ driver aged 40 with good driving record. Actual drive away price is subject to agreement with the selling dealer and will differ depending on choice of dealer and individual circumstances. Contact your Audi dealer of choice for final pricing of vehicles available from that dealer. Volkswagen Group Australia Pty Ltd does not warrant the accuracy of any of this information as applicable to your particular circumstances and to the extent permitted by law is not liable for any claims or losses of any nature, including, but not limited to, damages, expenses, lost profits, punitive, special, indirect or consequential losses/damages caused by reliance on this information. 
    </div>
  )
};
