import React from 'react';
import { formatMobile, gtmPush } from '../../lib/helpers';
import FormField from '../../components/FormField';
import Button from '../../audi-ui-components/Button';

class CustomerStep extends React.Component {

  componentDidMount() {
      let gtmEventCat = this.props.isUsed ? "VTP Used" : "VTP";
      gtmPush(gtmEventCat, "MyDetails", this.props.model);
  }

  render() {
    const {formikBag, nextClicked} = this.props;
    return (
      <div className="step customer">

        <section className="order-section">
          <div className="order-section--content">
            <p className="aui-headline-3 mb-2">My details</p>
            <p className="mb-3">Please provide contact details so your dealer can get in touch to confirm availability of this car and the next steps. Rest assured your information is kept secure in accordance with our <a href="http://www.audi.com.au/privacypolicy" target="_blank" rel="noopener noreferrer" className="text-link">Privacy Policy</a>.</p>

            <div className="row">
              <div className="col-12 col-medium-6">
                <FormField name="firstName"
                  label="First name"
                  showErrors={nextClicked}
                  formikBag={formikBag}
                  wrapperClassName="mb-4"
                />
              </div>
              <div className="col-12 col-medium-6">
                <FormField name="lastName"
                  label="Last name"
                  showErrors={nextClicked}
                  formikBag={formikBag}
                  wrapperClassName="mb-4"
                />
              </div>
            </div>
            <FormField name="email"
              label="Email"
              trim
              showErrors={nextClicked}
              formikBag={formikBag}
              wrapperClassName="mb-4"
            />
            <FormField name="emailConfirm"
              label="Confirm email"
              trim
              showErrors={nextClicked}
              formikBag={formikBag}
              wrapperClassName="mb-4"
            />
            <div className="row">
              <div className="col-12 col-medium-8">
                <FormField name="mobile"
                  label="Mobile"
                  formatValue={formatMobile}
                  showErrors={nextClicked}
                  formikBag={formikBag}
                  wrapperClassName="mb-4"
                />
              </div>
              <div className="col-12 col-medium-4">
                <FormField name="postcode"
                  label="Postcode"
                  trim
                  showErrors={nextClicked}
                  formikBag={formikBag}
                  wrapperClassName="mb-4"
                />
              </div>
            </div>

          </div>
        </section>

        <section className="order-section">
          <div className="order-section--content">
            <Button variant="secondary" onClick={(e) => { this.props.onClickBack(); e.preventDefault(); }}>Back</Button>
            {` `}
            <Button variant="primary" onClick={(e) => { this.props.onClickNext(formikBag.values, formikBag); e.preventDefault(); }}>Next</Button>
          </div>
        </section>

      </div>
    );
  }

}

export default CustomerStep;
