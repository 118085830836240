export const enquirySchema = {
  firstName: {
    presence: {
      message: "Please enter your first name",
      allowEmpty: false
    },
  },
  lastName: {
    presence: {
      message: "Please enter your last name",
      allowEmpty: false
    },
  },
  emailAddress: {
    presence: {
      message: "Please enter a valid email address",
      allowEmpty: false
    },
    email: {
      message: "Please enter a valid email"
    }
  },
  phoneNumber: {
    presence: {
      message: "Please enter your phone number",
      allowEmpty: false
    },
    format: {
      pattern: /^04.*$/,
      message: "Please enter a valid mobile number"
    }
  },
  postcode: {
    presence: {
      message: "Please enter your postcode",
      allowEmpty: false
    },
    numericality: {
      onlyInteger: true,
      greaterThan: 0,
      lessThan: 9999,
      message: "Please enter a valid postcode"
    },
    length: {
      minimum: 4,
      maximum: 4,
      message: "Please enter a valid postcode"
    }
  },
};
