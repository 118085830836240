import React from 'react';

export default () => {
  return (
    <div className="terms">
      <p className="aui-headline-3 mb-5">Audi Online Reservation Terms of Use</p>

      <div className="row mb-3">
        <div className="col-12 col-small-1">1.</div>
        <div className="col">
          <b>(Terms of Use)</b> These terms of use <b>(Terms)</b> set out the terms and conditions that apply with respect to
          your access to and use of the online configuration and pre order website <b>(Online Reservation Tool)</b>.
          The Online Reservation Tool is operated by and made available to you by Audi Australia Pty Ltd, ABN 86 007 092 776 <b>(Audi Australia)</b>.
        </div>
      </div>

      <div className="row mb-3">
        <div className="col-12 col-small-1">2.</div>
        <div className="col">
          <b>(Acceptance of Terms of Use)</b> By using the Online Reservation Tool you accept and agree to
          be bound by these Terms which set out Audi Australia’s obligations to you, and your obligations
          to Audi Australia in relation to the use of the Online Reservation Tool.
        </div>
      </div>

      <div className="row mb-3">
        <div className="col-12 col-small-1">3.</div>
        <div className="col">
          <b>(Online Reservation Tool)</b> The Online Reservation Tool is a website that you can use to configure a specific Audi model <b>(Vehicle Configuration)</b>
          and reserve a production slot for the model configured by you <b>(Vehicle)</b>.
        </div>
      </div>

      <div className="row mb-3">
        <div className="col-12 col-small-1">4.</div>
        <div className="col">
          <b>(Not an Agreement to Purchase)</b> Nothing in these Terms constitutes an agreement to purchase the Vehicle
          nor is this an offer by Audi Australia to sell the Vehicle to you. Audi Australia does not sell any Vehicles directly to end customers.
          Any purchase agreement for the Vehicle must be entered between you and your preferred Audi Dealer either in person at the dealership
          or by online correspondence between you and your preferred Audi Dealer <b>(Dealer Sales Contract)</b>.
          If you decide to enter into a Dealer Sales Contract, that Dealer Sales Contract will set out all terms and conditions on which you purchase the Vehicle from the Dealer.
        </div>
      </div>

      <div className="row mb-3">
        <div className="col-12 col-small-1">5.</div>
        <div className="col">
          <b>(Reservation Fee)</b> In order to submit your Vehicle Configuration and to reserve a production slot for the Vehicle <b>(Online Reservation)</b>
          you must pay a fee to Audi Australia in the amount of $1,000 <b>(Reservation Fee)</b>. The Reservation Fee will either be applied towards payment of the
          Final Purchase Price (see clause 7 below) or will be fully refunded, if you do not enter a Dealer Sales Contract within 5 business days after submitting
          your Online Reservation with us (see clause 6 below).
        </div>
      </div>

      <div className="row mb-3">
        <div className="col-12 col-small-1">6.</div>
        <div className="col">
          <b>(Online Reservation Process)</b> Once your Reservation Fee has been received by us, we will forward your Configuration and personal details
          to your preferred Audi Dealer who will then contact you to confirm the availability of your Vehicle configuration, the final drive away price
          and the anticipated delivery date of your Vehicle and to discuss any trade-in or financing requirements you might have.
          Whether or not you decide to proceed with the purchase of the Vehicle is entirely up to you. If you decide to enter into a Dealer Sales Contract
          for the Vehicle with your preferred Audi Dealer, the Dealer will notify us and we will forward your Reservation Fee to the Dealer who will apply
          the Reservation Fee towards payment of the Final Purchase Price (see clause 7 below). If you do not enter into a Dealer Sales Contract within 5 business
          days after submitting your Online Reservation with us, we will cancel your Online Reservation and refund the Reservation Fee to you in full.
        </div>
      </div>

      <div className="row mb-3">
        <div className="col-12 col-small-1">7.</div>
        <div className="col">
          <b>(Recommended Drive Away Price)</b> The drive away price for the Vehicle as indicated in your Vehicle Configuration or Online Reservation
          is our recommended indicative drive away price only which has been calculated based on your configuration. The final drive away price payable
          by you for the Vehicle will be agreed between you and your preferred Audi Dealer at the time when you enter into the Dealer Sales Contract.
          We note that on road costs are constantly changing and depend on many factors, such as your registration address, vehicle usage and licence plate type.
          The final drive away price will be set out in the Dealer Sales Contract and may be different from the recommended indicative drive away price
          shown in the Vehicle Configuration or Online Reservation (Final Purchase Price). Once you enter into the Dealer Sales Contract, the amount of the
          Reservation Fee will be forwarded by us to the Dealer and will be applied towards payment of the Final Purchase Price and thereby reduce your
          payment obligations under the Dealer Sales Contract.
        </div>
      </div>

      <div className="row mb-3">
        <div className="col-12 col-small-1">8.</div>
        <div className="col">
          <b>(Limited Offer)</b> The number of vehicles available through the Online Reservation Tool is limited and submitting an Online Reservation
          and paying the Reservation Fee does not guarantee that you will be able to purchase the Vehicle configured by you. If the Vehicle you have
          reserved online should not be available then your preferred Audi dealer will inform you and your Reservation Fee will be refunded in full.
        </div>
      </div>

      <div className="row mb-3">
        <div className="col-12 col-small-1">9.</div>
        <div className="col">
          <b>(Privacy Collection Statement)</b> Under the Online Reservation Tool, Audi Australia is collecting your personal information,
          such as your name and contact details. Failure to provide your personal information may prevent the completion of your Online Reservation.
          We may disclose your personal information to third parties, including to your preferred Audi Dealer, our service providers or as required by law.
          We may also share your personal information with our related bodies corporate, other Audi Dealers or entities associated with Audi Australia,
          some of which may  be located overseas, including in Germany. Further information about our privacy practices is available in our Privacy Policy located
          at <a href="https://www.audi.com.au/privacypolicy" className="text-link" target="_blank" rel="noopener noreferrer">https://www.audi.com.au/privacypolicy</a>
        </div>
      </div>

      <div className="row mb-3">
        <div className="col-12 col-small-1">10.</div>
        <div className="col">
          <b>(Disclaimer)</b> While every effort has been made to ensure that the information included in the Online Reservation Tool is correct and up to date,
          some inaccuracies may be present, specifications and options may change without notice and we make no representations or warranties as to the accuracy
          of the images or information included in the Online Reservation Tool.<br />
          The specified fuel consumption and emission data have been determined by testing under standardised laboratory conditions to comply with ADR 81/02.
          They do not refer to a particular individual vehicle and do not constitute part of any sales offering. They are intended exclusively as a means of
          comparison between different vehicle types. Additional equipment and accessories (e.g. add-on parts, different tyre formats, etc.) may change the
          relevant vehicle parameters, such as weight, rolling resistance and aerodynamics, and, in conjunction with weather and traffic conditions and individual
          driving style, may affect fuel consumption, electrical power consumption, CO2 emissions and the performance figures for the vehicle.<br />
          We also refer to our general
          {` `}
          <a href="https://www.audi.com.au/au/web/en.html#layer=/au/web/en/terms_and_conditions.html&data-innerlayer=true" className="text-link" target="_blank" rel="noopener noreferrer">Disclaimer</a>
          {` and `}
          <a href="https://www.audi.com.au/au/web/en.html#layer=/au/web/en/terms_and_conditions/non-liability_clause.html&data-innerlayer=true" className="text-link" target="_blank" rel="noopener noreferrer">Non-liability Clause</a>
          {` `}
          which apply to all parts of the Audi website, including without limitation, the Online Reservation Tool.
        </div>
      </div>

      <div className="row mb-3">
        <div className="col-12 col-small-1">11.</div>
        <div className="col">
          <b>(Governing Law and Jurisdiction)</b> These Terms are governed by the laws applicable in the State of New South Wales, and the parties submit to the exclusive jurisdiction of the Courts of that State.
        </div>
      </div>

    </div>
  );
};
