import queryString from 'query-string';

export const request = (base, options) => {

  // Request config
  let config = {
    method: 'GET',
    headers: {
      'Accept': 'application/json',
      'Cache-Control': 'no-cache',
      'Content-Type': 'application/json'
    },
  };
  if (options) {
    config = Object.assign({}, config, options);
  }

  // Request params
  let uri = `${base}`;
  if (options && options.path) {
    uri = `${base}/${options.path}`;
  }
  if (options && options.params) {
    uri = `${uri}?${queryString.stringify(options.params)}`;
  }

  return fetch(uri, config).then((response) => {

    let ok = response.status >= 200 && response.status < 300;
    let contentType = response.headers.get('Content-Type') || '';

    // json response
    if (contentType.includes('application/json')) {
      if (ok) {
        return response.json().catch(() => response);
      }
      // error
      return response.json().then((body) => {
        return Promise.reject({
          status: response.status,
          body,
        });
      });
    }

    // not json response
    if (ok) {
      return response;
    }
    return Promise.reject({
      status: response.status,
      body: response.body
    });

  });

};

export const requestJsonFile = (base, options) => {
  // Request config
  let config = {
    method: 'GET',
    headers: {
      'Accept': 'application/json',
      'Cache-Control': 'no-cache'
    },
  };
  if (options) {
    config = Object.assign({}, config, options);
  }

  // Request params
  let uri = `${base}`;
  if (options && options.path) {
    uri = `${base}/${options.path}`;
  }
  if (options && options.params) {
    uri = `${uri}?${queryString.stringify(options.params)}`;
  }

  return fetch(uri, config).then(
    response => response.text()
  ).then(text => {
    try {
      const data = JSON.parse(text);
      return data;
    } catch(err) {
      let error = new Error("bad data");
      throw error;
    }
  }).then((jsondata) => {
    return Promise.resolve(jsondata);
  }).catch((error) => {
    return Promise.reject(error);
  });
}
