import React from 'react';
import classnames from 'classnames';
import LoadingSpinner from '../../components/LoadingSpinner';
import FormField from '../../components/FormField';
import StripeField from '../../components/StripeField';
import Button from '../../audi-ui-components/Button';
import IconEdit from '../../audi-ui-components/icons/Edit';
import IconSelect from '../../audi-ui-components/icons/Select'
import {
  EXP_MONTHS,
  EXP_YEARS,
  FIELD_SELECT,
  FIELD_CHECKBOX
} from '../../constants';
import {
  formatMobile,
  formatCreditCard,
  stateByPostcode,
  formatNumberOnly,
  gtmPush
} from '../../lib/helpers';

class PaymentStep extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      isEditingCustomer: false
    };
  }

  componentDidMount() {
      let gtmEventCat = this.props.isUsed ? "VTP Used" : "VTP";
      gtmPush(gtmEventCat, "ReserveMyCar", this.props.model);
  }

  render() {
    const { formikBag, dealer } = this.props;
    const dealerState = stateByPostcode(dealer.zipCode);
    const customerHasErrors = formikBag.errors.firstName || formikBag.errors.lastName || formikBag.errors.email || formikBag.errors.emailConfirm || formikBag.errors.mobile || formikBag.errors.postcode;
    return (
      <div className="step payment">

        <section className="order-section">
          <div className="order-section--content">
            <p className="aui-headline-3 mb-2">Submit order</p>
            <p>
              To complete your online order and secure this car, a refundable $500 holding fee is required.
              If you move ahead with the purchase, your dealer will deduct $500 from the final purchase price as agreed between you and your dealer.
              If the car is not available, or if you decide not to purchase it prior to entering into a purchase agreement or the holding period expires,
              your dealer will issue a full refund of your holding fee
              <sup style={{fontWeight: "bold", cursor: "pointer"}} onClick={() => { window.scrollTo(0,document.body.scrollHeight); }}>2</sup>.
            </p>
          </div>
        </section>

        <section className="order-section">
          <div className="order-section--content">
            <div className="left-small">
              <p className="aui-headline-4 mb-3">My details</p>
            </div>
            <div className="right-small">
              <div className={classnames("editable", {"is-editing": this.state.isEditingCustomer})}>
                {!this.state.isEditingCustomer && <>
                  <div className="editable__btn">
                    <Button variant="icon" icon={<IconEdit small />} onClick={() => { this.setState({isEditingCustomer: true}); }}>Edit</Button>
                  </div>
                  <p><b>{formikBag.values.firstName} {formikBag.values.lastName}</b></p>
                  <p>
                    {formikBag.values.email}<br />
                    {formikBag.values.mobile}<br />
                    {formikBag.values.postcode}
                  </p>
                </>}
                {this.state.isEditingCustomer && <>
                  <div className="editable__btn">
                    <Button variant="icon" icon={<IconSelect small />} onClick={() => { this.setState({isEditingCustomer: false}); }} disabled={customerHasErrors}>Done</Button>
                  </div>
                  <div className="row">
                    <div className="col-12 col-medium-6">
                      <FormField name="firstName" label="First name" showErrors formikBag={formikBag} wrapperClassName="mb-4" />
                    </div>
                    <div className="col-12 col-medium-6">
                      <FormField name="lastName" label="Last name" showErrors formikBag={formikBag} wrapperClassName="mb-4" />
                    </div>
                  </div>
                  <FormField name="email" label="Email" showErrors formikBag={formikBag} wrapperClassName="mb-4" />
                  <FormField name="emailConfirm" label="Confirm email" showErrors formikBag={formikBag} wrapperClassName="mb-4" />
                  <div className="row">
                    <div className="col-12 col-medium-8">
                      <FormField name="mobile" label="Mobile" formatValue={formatMobile} showErrors formikBag={formikBag} wrapperClassName="mb-4" />
                    </div>
                    <div className="col-12 col-medium-4">
                      <FormField name="postcode" label="Postcode" showErrors formikBag={formikBag} wrapperClassName="mb-4" />
                    </div>
                  </div>
                </>}
              </div>
            </div>
          </div>
        </section>

        <section className="order-section">
          <div className="order-section--content">
            <div className="left-small">
              <p className="aui-headline-4 mb-3">Dealer</p>
            </div>
            <div className="right-small">
              <p><b>{dealer.name}</b></p>
              <p>
                {dealer.street}<br />
                {`${dealer.city} ${dealer.zipCode} ${dealerState}`}
              </p>
            </div>
          </div>
        </section>

        <section className="stripe order-section aui-color-gray95 padded my-5">
          <div className="order-section--content">
            <div className="row">
              <div className="col-small-6">
                <FormField name="cardName"
                  label="Name on card"
                  formikBag={formikBag}
                  wrapperClassName="mb-4"
                />
              </div>
              <div className="col-small-6">
                <StripeField label="Card number" fieldType="number" />
              </div>
            </div>
            <div className="row">
              <div className="col-12 col-small-6">
                <StripeField label="Card expiry date" fieldType="exp" />
              </div>
              <div className="col-12 col-small-6">
                <StripeField label="CVC" fieldType="cvc" />
              </div>
            </div>
          </div>
          {this.props.stripeError && <p className="aui-color-text-red my-2">{this.props.stripeError}</p>}
        </section>

        {this.props.submitError && <section className="submit-errors order-section">
          <div className="order-section--content">
            <p className="aui-color-text-red mb-3">{this.props.submitError}</p>
          </div>
        </section>}

        <section className="order-section">
          <div className="order-section--content">
            <FormField name="optIn"
              fieldType={FIELD_CHECKBOX}
              label={<>
                By submitting this form you agree to the <a className="aui-textlink" href="https://www.audi.com.au/au/web/en/audi-new-car-search.html#layer=/au/web/en/terms-and-conditions/online-reservation.html" target="_blank" rel="noopener noreferrer">Online Ordering Terms and Conditions</a>
                {` `}including the collection of personal information in accordance with clause 7 of those Terms
                and Audi Australia's <a href="https://www.audi.com.au/privacypolicy" target="_blank" rel="noopener noreferrer" className="aui-textlink">Privacy Policy</a>.
              </>}
              formikBag={formikBag}
              wrapperClassName="mb-4"
            />
            <FormField name="marketingOptins"
              fieldType={FIELD_CHECKBOX}
              label="I would like to be contacted by Audi and Audi Dealers with occasional communications on products, offers, news and events."
              formikBag={formikBag}
              wrapperClassName="mb-4"
            />
          </div>
        </section>

        <section className="order-section">
          <div className="order-section--content">
            <Button variant="secondary" onClick={(e) => { this.props.onClickBack(); e.preventDefault(); }}>Back</Button>
            {` `}
            <Button variant="primary" type="submit" className="theme-red">Submit</Button>
          </div>
        </section>

      </div>
    );
  }
}

export default PaymentStep;
