import React from 'react';

export default (_props) => {
  const { className, size, large, small, ...props } = _props;

  // small icon
  if ((size && size.toLowerCase() === 'small') || small) {
    return (
      <svg width="24" height="24" viewBox="0 0 24 24" className={`audiicon audiicon-small ${className ? className : ''}`} {...props}>
        <g stroke="currentColor" strokeWidth="1" fill="none" fillRule="evenodd">
          <path d="M15,18.5 L22.5,18.5 L22.5,12.5 C22.5,11.9 22.1,11.5 21.5,11.5 L12,11.5"/>
          <path d="M15.5,2.5 L17.5,2.5 C18.6,2.5 19.5,3.4 19.5,4.5 L19.5,6.5 C19.5,7.6 18.6,8.5 17.5,8.5 L15.5,8.5 C14.4,8.5 13.5,7.6 13.5,6.5 L13.5,4.5 C13.5,3.4 14.4,2.5 15.5,2.5 Z"/>
          <path d="M1.5,20.5 L1.5,14.5 C1.5,13.9 1.9,13.5 2.5,13.5 L12.5,13.5 C13.1,13.5 13.5,13.9 13.5,14.5 L13.5,20.5 L1.5,20.5 Z"/>
          <path d="M6.5,4.5 L8.5,4.5 C9.6,4.5 10.5,5.4 10.5,6.5 L10.5,8.5 C10.5,9.6 9.6,10.5 8.5,10.5 L6.5,10.5 C5.4,10.5 4.5,9.6 4.5,8.5 L4.5,6.5 C4.5,5.4 5.4,4.5 6.5,4.5 Z"/>
        </g>
      </svg>
    );
  }

  // large icon
  return (
    <svg width="48" height="48" viewBox="0 0 48 48" className={`audiicon audiicon-large ${className ? className : ''}`} {...props}>
      <g stroke="currentColor" strokeWidth="1" fill="none" fillRule="evenodd">
        <path d="M30.1,37.5 L43.5,37.5 L43.5,25.5 C43.5,23.8 42.2,22.5 40.5,22.5 L24,22.5"/>
        <path d="M28.5,6.5 L34.5,6.5 C36.2,6.5 37.5,7.8 37.5,9.5 L37.5,15.5 C37.5,17.2 36.2,18.5 34.5,18.5 L28.5,18.5 C26.8,18.5 25.5,17.2 25.5,15.5 L25.5,9.5 C25.5,7.8 26.8,6.5 28.5,6.5 Z"/>
        <path d="M3.5,40.5 L3.5,28.5 C3.5,26.8 4.8,25.5 6.5,25.5 L24.5,25.5 C26.2,25.5 27.5,26.8 27.5,28.5 L27.5,40.5 L3.5,40.5 Z"/>
        <path d="M12.5,9.5 L18.5,9.5 C20.2,9.5 21.5,10.8 21.5,12.5 L21.5,18.5 C21.5,20.2 20.2,21.5 18.5,21.5 L12.5,21.5 C10.8,21.5 9.5,20.2 9.5,18.5 L9.5,12.5 C9.5,10.8 10.8,9.5 12.5,9.5 Z"/>
      </g>
    </svg>
  );
};
