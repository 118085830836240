import React from 'react';
import classnames from 'classnames';
import _debounce from 'lodash/debounce';
import _unescape from 'lodash/unescape';
import Spinner from '../../audi-ui-components/Spinner';

class Search extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            isSearching: false,
            hasSearched: false,
            searchVal: "",
            searchValChanged: false,
            searchQuery: "",
            results: [],
            resultCount: 0,
            nextSeRP: null,
            searchFocus: false
        };
        this.refSearchInput = React.createRef();
        this.refLoadMore = React.createRef();
        this._debouncedScroll = _debounce(this.handleScroll, 200);
    }

    componentDidMount() {
        window.addEventListener('scroll', this._debouncedScroll, false);
    }

    componentDidUpdate(prevProps) {
        if (this.props.searchOpen && !prevProps.searchOpen) {
            this.refSearchInput.current.focus();
        }
    }

    componentWillUnmount() {
        window.removeEventListener("scroll", this._debouncedScroll, false);
    }

    fetchSearchResults = (_searchTerm, _type) => {
        let searchTerm = _searchTerm || this.state.searchVal;
        let type = _type || "PAGES";
        let api = `https://search-service.audi.com/search/pages?q=${searchTerm}&fl=en&start=0&num=10&client=audi_cq5_au_en&additionalClients=&type=${type}`;
        this.setState({isSearching: true, searchVal: searchTerm, results: [], resultCount: 0, searchQuery: ""});
        fetch(api, {
          method: 'GET',
          headers: {
            'Accept': 'application/json',
            'Cache-Control': 'no-cache',
            'Content-Type': 'application/json'
          },
        }).then(
            (response) => {
                let ok = response.status >= 200 && response.status < 300;
                if (ok) {
                    response.json().then(data => {
                        console.log(data);
                        this.setState({
                            isSearching: false,
                            hasSearched: true,
                            resultCount: data.pagination.resultCount,
                            results: data.results,
                            searchQuery: data.query.q,
                            nextSeRP: data.pagination.nextSeRP
                        });
                    }).catch(() => {
                        console.log("search response catch");
                        this.setState({isSearching: false});
                    });
                } else {
                    console.log("search response error");
                    this.setState({isSearching: false});
                }
            }
        );
        return false;
    }

    fetchMoreSearchResults = () => {
        const {nextSeRP, results} = this.state;
        if (nextSeRP) {
            this.setState({isSearching: true});
            let api = `https://search-service.audi.com${_unescape(nextSeRP)}`;
            fetch(api, {
              method: 'GET',
              headers: {
                'Accept': 'application/json',
                'Cache-Control': 'no-cache',
                'Content-Type': 'application/json'
              },
            }).then(
                (response) => {
                    let ok = response.status >= 200 && response.status < 300;
                    if (ok) {
                        response.json().then(data => {
                            console.log(data);
                            this.setState({
                                isSearching: false,
                                hasSearched: true,
                                resultCount: data.pagination.resultCount,
                                results: results.concat(data.results),
                                searchQuery: data.query.q,
                                nextSeRP: data.pagination.nextSeRP
                            });
                        }).catch(() => {
                            console.log("search response catch");
                            this.setState({isSearching: false});
                        });
                    } else {
                        console.log("search response error");
                        this.setState({isSearching: false});
                    }
                }
            );
        }
    }

    clearSearchResults = () => {
        this.setState({results: [], resultCount: 0, hasSearched: false});
    }

    handleScroll = () => {
        const {isSearching, results, resultCount} = this.state;
        if (!isSearching && results.length > 0 && results.length < resultCount) {
            if (this.refLoadMore.current.getBoundingClientRect().top <= window.innerHeight) {
                this.fetchMoreSearchResults();
            }
        }
    }

    handleSearchChange = (e) => {
        let v = e.target.value;
        this.setState({searchVal: v});
        if (v.length === 0) {
            this.clearSearchResults();
        }
    }

    render() {
        const {searchOpen} = this.props;
        const {searchVal, searchFocus, results, resultCount, isSearching, hasSearched} = this.state;
        return (
            <div className={classnames("one-header-search", {"one-header-search--has-value": searchVal.length > 0, "one-header-search--focus": searchFocus})}>
                <div className="one-header-search-featureapp">

                    {searchOpen && <div className="search-header">
                        <div className="search-header-content">
                            <div className="search-field-container">
                                <div className="search-field">
                                    <span className="search-icon">
                                        <svg aria-hidden="true" width="24" height="24" viewBox="0 0 24 24"><path d="M22,22 L13.7,13.7 L22,22 Z M15.5,9 C15.6,12.5 12.9,15.4 9.4,15.5 C9.3,15.5 9.1,15.5 9,15.5 C5.4,15.5 2.5,12.6 2.5,9 C2.5,5.4 5.4,2.5 9,2.5 C12.6,2.5 15.5,5.4 15.5,9 C15.5,9 15.5,9 15.5,9 Z" stroke="currentColor" strokeWidth="1" fill="none" fillRule="evenodd"></path></svg>
                                    </span>
                                    <label>Models / Dealers / Audi Code</label>
                                    <input
                                        ref={this.refSearchInput}
                                        name="search__basic"
                                        value={searchVal}
                                        onChange={this.handleSearchChange}
                                        onFocus={() => { this.setState({searchFocus: true}); }}
                                        onBlur={() => { this.setState({searchFocus: false}); }}
                                        onKeyDown={(e) => { if (e.keyCode === 13) {this.fetchSearchResults();} }}
                                        type="search"
                                    />
                                </div>
                            </div>
                        </div>
                    </div>}

                    {searchOpen && !isSearching && !hasSearched && <div className="one-header-search-flyout">
                        <div className="one-header-flyout-results">
                            <div className="one-header-suggested-search">
                                <p className="search-suggestions-label">Quick links</p>
                                <div className="suggested-terms-list">
                                    <button className="suggested-term" type="button" onClick={() => { this.fetchSearchResults("Leasing"); }}>
                                        <span>Leasing</span>
                                    </button>
                                    <button className="suggested-term" type="button" onClick={() => { this.fetchSearchResults("Audi Code"); }}>
                                        <span>Audi Code</span>
                                    </button>
                                    <button className="suggested-term" type="button" onClick={() => { this.fetchSearchResults("e-tron"); }}>
                                        <span>e-tron</span>
                                    </button>
                                    <button className="suggested-term" type="button" onClick={() => { this.fetchSearchResults("Locate a dealer"); }}>
                                        <span>Locate a dealer</span>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>}

                    {searchOpen && (results || isSearching || hasSearched) && <div className="one-header-search-results">
                        <div className="results-container">
                            <div className="results-content">
                                {((results && results.length > 0) || hasSearched) && <div className="search-result-count-container">
                                    <p className="search-result-count">Your query with the search term "<strong>{searchVal}</strong>" produced {resultCount} results.</p>
                                </div>}
                                <div className="results-list">
                                    <div className="webpage-search-results-list">

                                        {results && results.map((result, i) => {
                                            let snippet = result.snippet ? result.snippet.replace(/&lt;/gi, "<").replace(/&gt;/gi, ">").replace(/&lt/gi, "<").replace(/&gt/gi, ">") : false;
                                            return (
                                                <div className="webpage-result" key={`result${i}`}>
                                                    <a className="breadcrumb" href={result.url}>
                                                        <span>{result.breadcrumbs}</span>
                                                        <svg width="8" height="13" viewBox="0 0 8 13"><path stroke="currentColor" strokeWidth="1px" fill="none" d="M1.5,1l5.485,5.504l-5.48,5.496"></path></svg>
                                                    </a>
                                                    {result.title && <a className="title" href={result.url}>
                                                        <span>{result.title}</span>
                                                        <svg width="8" height="13" viewBox="0 0 8 13"><path stroke="currentColor" strokeWidth="1px" fill="none" d="M1.5,1l5.485,5.504l-5.48,5.496"></path></svg>
                                                    </a>}
                                                    {snippet && <p className="description"><span dangerouslySetInnerHTML={{ __html: snippet}} /></p>}
                                                </div>
                                            );
                                        })}

                                    </div>
                                </div>
                            </div>

                            <div ref={this.refLoadMore} className="results-loader">
                                {isSearching && <Spinner isContinuous />}
                            </div>
                        </div>
                    </div>}

                </div>

                <div className="header-search-controls">
                    <legend>Audi Search</legend>
                    <button type="button" onClick={this.props.toggleSearch}>
                        <svg height="48" viewBox="0 0 24 24" width="48"><path d="M10 16.9L15.5 11.5 10 6.1" fill="none" stroke="currentColor"></path></svg>
                    </button>
                </div>

            </div>
        );
    }
}

export default Search;
