import React from 'react';
import Button from '../../audi-ui-components/Button';
import Modal from '../../audi-ui-components/Modal';
import IconForward from '../../audi-ui-components/icons/Forward';
import IconBack from '../../audi-ui-components/icons/Back';
import IconHelp from '../../audi-ui-components/icons/SystemHelp';
import IconCar from '../../audi-ui-components/icons/Car';
import PageSidebarLayout from '../../components/PageSidebarLayout';
import LoadingSpinner from '../../components/LoadingSpinner';
import Section from './Section';
import HeroSection from './HeroSection';
import AFSDisclaimer from '../../components/legal/AFSDisclaimer';
import { formatDollar, gtmPush } from '../../lib/helpers';
import { request, requestJsonFile } from '../../lib/apiRequestWrapper';
import { EXT_COLOURS, FAQ_LINK, MAINTENANCE } from '../../constants';

class VehicleDetail extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      activeModal: null,
      loading: true,
      jsonData: null,
      apiData: null,
      unavailable: false
    };
  }

  componentDidMount() {
    window.scrollTo(0, 0);
    const {match} = this.props;
    let commissionNumber = match.params.commissionNumber;

    if (commissionNumber) {
      let q = `?pageSize=1&$top=1&$skip=0&$filter=(commissionNumber eq '${commissionNumber}')`;
      request(
        `${process.env.RAZZLE_API}/1/service/carstocks${q}`
      ).then((res) => {
        let apiData = res.items[0];
        let unavailable = res.items.length === 0;

        if (!unavailable) {
          let modelSlug = apiData.model.modelName.toLowerCase().replace(" ", "-");
          requestJsonFile(
            `${process.env.PUBLIC_PATH}models/${modelSlug}.json`
          ).then((jsonData) => {
            this.setState({loading: false, jsonData: jsonData, apiData: apiData, unavailable: unavailable});
          }).catch((error) => {
            console.error(error);
            this.setState({loading: false});
          });
        } else {
          this.setState({loading: false});
        }

      }).catch((error) => {
        console.error(error);
        this.setState({loading: false});
      });
    }

  }

  handleCloseModal = () => {
    this.setState({ activeModal: null });
  }

  render() {
    const {match, history} = this.props;
    const {jsonData, apiData, unavailable} = this.state;
    const sections = (jsonData && apiData) ? jsonData.sections : [];
    let vehicle = null;
    if (jsonData && apiData) {
      vehicle = {
        modelName: jsonData.variants[apiData.model.modelCode].name,
        genericModelName: apiData.model.modelName,
        driveAwayPrice: apiData.prices.driveAwayPrice,
        commissionNumber: apiData.commissionNumber,
        details: []
      };
      if (EXT_COLOURS[apiData.model.exteriorColour]) {
        vehicle.details.push(<>Exterior colour: {EXT_COLOURS[apiData.model.exteriorColour].name}</>)
      }
      if (jsonData.equipment[apiData.model.interiorTrimColour]) {
        vehicle.details.push(<>Interior colour: {jsonData.equipment[apiData.model.interiorTrimColour].name}</>)
      }
    }
    return (
      <div className="">

        <PageSidebarLayout
          vehicle={vehicle}
          dealer={apiData ? apiData.dealer : null}
          faqLink={FAQ_LINK}
          financeAmount={vehicle ? vehicle.driveAwayPrice : false}
          openModal={(m) => { this.setState({activeModal: m}); }}
        >
          {!unavailable && <HeroSection vin={apiData ? apiData.vin : ""} carouselImages={jsonData ? jsonData.carouselImages : null} loading={this.state.loading} />}
          {this.state.loading && <LoadingSpinner type="placeholder" />}
          {sections.map(section => {
            if (section.id === "Opt_Equip") {
              section.subSections[0] = {equipment: apiData.options.map(o => o.option)};
            }
            return(
              <Section key={section.id} section={section} equipment={jsonData ? Object.assign({}, jsonData.equipment, jsonData.variants[apiData.model.modelCode].equipment) : null} />
            );
          })}

          {unavailable &&
            <section className="order-section mb-5">
              <div className="order-section--content p-4">
                <div className="left">
                </div>
                <div className="right">
                  This vehicle has been reserved.
                </div>
              </div>
            </section>
          }
          <section className="order-section mb-5">
            <div className="order-section--content p-4">
              <div className="left">
              </div>
              <div className="right">
                <Button icon={<IconBack small />} variant="secondary" onClick={() => { history.push(`/${match.params.modelName}`); }} className="mb-2">
                  Back
                </Button>
                {` `}
                {!unavailable && !(MAINTENANCE.reservationUsed || MAINTENANCE.reservationNew) &&
                  <Button
                    icon={<IconCar small />}
                    variant="primary"
                    onClick={() => {
                      gtmPush("VTP", "BuildOrderNow", jsonData.name);
                      history.push(`/reservation?vehicleid=${match.params.commissionNumber}`);
                    }}
                    className="mb-2">
                    Order now
                  </Button>
                }
                {` `}
                {!unavailable &&
                  <Button
                    icon={<IconHelp small />}
                    variant="secondary"
                    onClick={() => {
                      gtmPush("VTP", "BuildEnquiry", jsonData.name);
                      history.push(`/enquiry?vehicleid=${match.params.commissionNumber}`);
                    }}
                    className="mb-2">
                    Make an enquiry
                  </Button>
                }
              </div>
            </div>
          </section>

        </PageSidebarLayout>

        <Modal isActive={this.state.activeModal === 'afs'} variant="layer" closeButton close={this.handleCloseModal}>
          <AFSDisclaimer />
        </Modal>
      </div>
    );
  }
}

export default VehicleDetail;
