import React, { Fragment } from 'react';
import { Link } from 'react-router-dom';
import queryString from 'query-string';
import { Formik, Form } from 'formik';
import { validate } from '../../lib/validation';
import { request } from '../../lib/apiRequestWrapper';
import { scrollToTop, sanitizeString, formatDollar, gtmPush } from '../../lib/helpers';
import { financeSchema, customerSchema, paymentSchema } from './schema';
import { mapValuesToBackend, mapValuesToGTM, flattenBackendErrors } from './dataMapping';
import _set from 'lodash/set';

import { STEPS, STRIPE_FIELDS, FAQ_LINK, MAINTENANCE } from '../../constants';

import Modal from '../../audi-ui-components/Modal';
import Button from '../../audi-ui-components/Button';
import IconForward from '../../audi-ui-components/icons/Forward';
import LoadingSpinner from '../../components/LoadingSpinner';
import StepsList from './StepsList';
import PageSidebarLayout from '../../components/PageSidebarLayout';
import FinanceStep from './FinanceStep';
import CustomerStep from './CustomerStep';
import PaymentStep from './PaymentStep';
import ThankYou from '../ThankYou';
import Terms from '../../components/legal/Terms';
import Privacy from '../../components/legal/Privacy';
import AFSDisclaimer from '../../components/legal/AFSDisclaimer';

import { ElementsConsumer, CardNumberElement } from '@stripe/react-stripe-js';

class Reservation extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      step: STEPS.FINANCE,
      financeNextClicked: false,
      customerNextClicked: false,
      activeModal: null,
      stripeError: false,
      submitError: false,
      isInBuild: false,
      canOrder: true
    };
  }

  componentDidMount() {
    // this.props.setActiveTab(1);
    // First landing on this page, access the vehicleId from query string
    if ((MAINTENANCE.reservationUsed && this.props.isUsed) || (MAINTENANCE.reservationNew && !this.props.isUsed)) {
        return this.props.history.push(`/enquiry${this.props.location.search}`);
    }
    if (!this.props.vehicleId) {
      if (this.props.location.search) {
        let s = queryString.parse(this.props.location.search);
        if (s.vehicleid) {
          this.props.setVehicleId(s.vehicleid);
          if (s.vehicleid.replace(/[^a-z0-9]/gi, '').indexOf("AA") === 0) {
            this.setState({isInBuild: true});
          }
        }
      } else {
        this.props.setFetchError(true);
      }
    }
    request(
        `${process.env.RAZZLE_API}/1/service/canOrder`
    ).then((response) => {
        console.log("canOrder", response);
        if (!response) {
            this.setState({canOrder: false});
        }
    }).catch((error) => {
        console.error(error);
    });
  }

  handleCloseModal = () => {
    this.setState({ activeModal: null });
  }

  scrollToDisclaimer = () => {
    let to = document.body.scrollHeight - 700;
    window.scrollTo(0, to);
  }

  validateForm = values => {
    let validation = {};
    if (this.state.step === STEPS.FINANCE) {
      validation = validate(values, financeSchema, { format: "firstError", fullMessages: true });
    }
    if (this.state.step === STEPS.CUSTOMER) {
      validation = validate(values, customerSchema, { format: "firstError", fullMessages: true });
    }
    if (this.state.step === STEPS.PAYMENT) {
      validation = validate(values, Object.assign({}, paymentSchema, customerSchema, financeSchema), { format: "firstError", fullMessages: true });
    }
    return validation;
  }

  handleClickBack = () => {
    scrollToTop();
    let stepName = this.state.step === STEPS.PAYMENT ? STEPS.CUSTOMER : STEPS.FINANCE;
    this.setState({ step: stepName });
  }

  handleClickNext = (values, formikBag) => {
    if (this.state.step === STEPS.FINANCE) {
      this.setState({financeNextClicked: true});
    }
    if (this.state.step === STEPS.CUSTOMER) {
      this.setState({customerNextClicked: true});
    }
    let errors = this.validateForm(values);
    if (errors && Object.keys(errors).length > 0) {
      formikBag.setErrors(errors);
    } else {
      scrollToTop();
      let stepName = this.state.step === STEPS.FINANCE ? STEPS.CUSTOMER : STEPS.PAYMENT;
      this.setState({ step: stepName });
    }
  }

  handleSubmit = (values, formikBag) => {
    this.setState({stripeError: false, submitError: false});
    // validate all steps here
    let errFinance = validate(values, financeSchema, { format: "firstError", fullMessages: true });
    if (errFinance && Object.keys(errFinance).lenght > 0) {
      formikBag.setErrors(errFinance);
      this.setState({step: STEPS.FINANCE});
      return;
    }
    let errCustomer = validate(values, customerSchema, { format: "firstError", fullMessages: true });
    if (errCustomer && Object.keys(errCustomer).lenght > 0) {
      formikBag.setErrors(errCustomer);
      this.setState({step: STEPS.CUSTOMER});
      return;
    }
    let errPayment = validate(values, paymentSchema, { format: "firstError", fullMessages: true });
    if (errPayment && Object.keys(errPayment).length > 0) {
      formikBag.setErrors(errPayment);
      return;
    } else {
      // no errors, submit form

      // Stripe token & API submission
      const {stripe, elements} = this.props;
      if (!stripe || !elements) {
        return;
      }
      const cardElement = elements.getElement(CardNumberElement);
      stripe.createPaymentMethod({
        type: 'card',
        card: cardElement,
        billing_details: {
          name: values.cardName
        }
      }).then((result) => {
        if (result.error) {
          // Stripe returned an error
          console.log('[error]', result.error);
          this.setState({stripeError: result.error.message});
          formikBag.setSubmitting(false);
          return;
        }

        // no errors from Stripe, submit to backend
        console.log('[PaymentMethod]', result.paymentMethod);

        let data = mapValuesToBackend(values, this.props.vehicleData, this.props.vehicleEdmImage, result.paymentMethod.id);

        request(
          `${process.env.RAZZLE_MYAUDI_API}/1/vtp/reserve`,
          {
            method: 'POST',
            body: JSON.stringify(data),
          }
        ).then((res) => {
          let gtmData = mapValuesToGTM(values, this.props.vehicleData);
          let gtmEventCat = this.props.isUsed ? "VTP Used" : "VTP";
          gtmPush(gtmEventCat, "Submitted", data.model, gtmData);
          this.setState({
            submitStatus: res.status,
            reservationNumber: res.reservationNumber,
            step: STEPS.THANKYOU
          });
          scrollToTop();
          formikBag.setSubmitting(false);
        }).catch((error) => {
          let badStep = STEPS.PAYMENT;
          let msg = "Sorry, there was an issue with your submission. Please try reloading the page, or contact your dealer directly.";
          if (error.body && error.body.modelState) {
            // errors come back nested, see dataMapping.js
            let nestedErrors = {};
            for (let key in error.body.modelState) {
              _set(nestedErrors, key, error.body.modelState[key]);
            }
            // console.log(nestedErrors);
            // check if we need to go to another step
            if (nestedErrors.pricingRequests || nestedErrors.tradeIn || nestedErrors.contactFinance) {
              badStep = STEPS.FINANCE;
            } else if (nestedErrors.customer) {
              badStep = STEPS.CUSTOMER;
            }
            // un-nest and set errors
            let unNestedErrors = flattenBackendErrors(nestedErrors);
            // console.log(unNestedErrors);
            formikBag.setErrors(unNestedErrors);
            msg = "Some of the data you submitted was incorrect. Please check the error messages in the fields above.";
          } else if (error.body && error.body.message) {
            msg = error.body.message;
            if (error.status === 403) {
              msg = <>Currently there is an issue with Online Banking. <br />Please use <Link to={`/enquiry?vehicleid=${this.props.vehicleId}`} className="aui-textlink">this form</Link> to submit your vehicle enquiry.</>;
            }
          }
          this.setState({submitError: msg, step: badStep});
          formikBag.setSubmitting(false);
        });

      });

    }
  }

  render() {
    const { setVehicleId, vehicleData, vehicleImage, isFetchingData, fetchError, setFetchError, isReserved, isSold } = this.props;
    const commissionNumber = vehicleData?.vehicle?.basic?.commissionNumber || "";
    const model = vehicleData?.vehicle?.basic?.symbolicCarline?.description || "";
    const dealer = vehicleData?.vehicle?.basic?.dealer || {};
    const dealerPhone = (dealer.phoneNumbers && dealer.phoneNumbers[0]) ? dealer.phoneNumbers[0].number : "";
    const dealerName = vehicleData?.vehicle?.basic?.dealer?.name || "";
    const basicId = vehicleData?.vehicle?.basic?.id || "";
    const price = vehicleData?.vehicle?.basic?.typedPrices[0];
    const mileage = (vehicleData && this.props.isUsed && vehicleData.vehicle?.basic?.used) ? `${vehicleData.vehicle.basic.used.mileageFormatted}${vehicleData.vehicle.basic.used.mileageUnit}` : false;
    const { step, canOrder } = this.state;

    const initialValues = {
      driveAwayPrice: undefined,
      cost: price?.amount
    }

    return (
      <Fragment>
        <PageSidebarLayout
          vehicle={{modelName: model, price: price, commissionNumber: commissionNumber, isUsed: this.props.isUsed, mileage: mileage}}
          dealer={{dealerName: dealer.name, dealerContactNumber: dealerPhone}}
          faqLink={FAQ_LINK}
          financeAmount={price?.amount}
          openModal={(m) => { this.setState({activeModal: m}); }}
        >

          {fetchError && <section className="order-section">
            <div className="order-section--content">
              <p className="aui-color-text-red my-5">
                Sorry, we appear to be experiencing technical difficulties.
                <br />Please try reloading the page, or contact your dealer directly.
              </p>
            </div>
          </section>}

          {(!canOrder && !isReserved && !isSold && !fetchError) && <section className="order-section">
            <div className="order-section--content">
              <p className="aui-headline-5 aui-color-text-red mb-2">The payment gateway is currently unavailable.</p>
              <p className="mb-3">We apologise for the inconvenience and invite you to make an enquiry.</p>
              <p className="mb-3">
                <Button variant="primary" onClick={() => this.props.history.push(`/enquiry?vehicleid=${this.props.vehicleId}`)}>
                  Make an enquiry
                </Button>
              </p>
            </div>
          </section>}

          {isReserved && <section className="order-section">
            <div className="order-section--content">
              <p className="aui-headline-5 aui-color-text-red mb-2">This car has already been reserved.</p>
              <p className="mb-3">We invite you to make an enquiry so the dealer can help you find similar cars in stock, or let you know if the sale does not proceed.</p>
              <p className="mb-3">
                <Button variant="primary" onClick={() => this.props.history.push(`/enquiry?vehicleid=${this.props.vehicleId}`)}>
                  Make an enquiry
                </Button>
              </p>
            </div>
          </section>}

          {isSold && <section className="order-section">
            <div className="order-section--content">
              <p className="aui-headline-5 aui-color-text-red mb-2">This car has just been sold.</p>
              <p className="mb-3">We invite you to make an enquiry so the dealer can help you find similar cars in stock.</p>
              <p className="mb-3">
                <Button variant="primary" onClick={() => this.props.history.push(`/enquiry?vehicleid=${this.props.vehicleId}`)}>
                  Make an enquiry
                </Button>
              </p>
            </div>
          </section>}

          {vehicleData && !fetchError && !isReserved && !isSold && (canOrder || process.env.RAZZLE_APP_ENV !== "production") &&
            <>
              <StepsList currentStep={step} />
              <Formik onSubmit={this.handleSubmit} initialValues={initialValues} validate={this.validateForm}>
                {formikBag => (
                  <Form className="spinner-wrapper">
                    {formikBag.isSubmitting && <LoadingSpinner />}
                    {step === STEPS.FINANCE &&
                      <FinanceStep
                        onClickNext={this.handleClickNext}
                        nextClicked={this.state.financeNextClicked}
                        formikBag={formikBag}
                        cost={price?.amount}
                        model={model}
                        isInBuild={this.state.isInBuild}
                        onBackInBuild={() => { this.props.history.goBack(); }}
                        isUsed={this.props.isUsed}
                      />
                    }
                    {step === STEPS.CUSTOMER &&
                      <CustomerStep
                        onClickNext={this.handleClickNext}
                        onClickBack={this.handleClickBack}
                        nextClicked={this.state.customerNextClicked}
                        formikBag={formikBag}
                        model={model}
                        isUsed={this.props.isUsed}
                      />
                    }
                    {step === STEPS.PAYMENT &&
                      <PaymentStep
                        onClickBack={this.handleClickBack}
                        formikBag={formikBag}
                        dealer={dealer}
                        submitError={this.state.submitError}
                        stripeError={this.state.stripeError}
                        model={model}
                        isUsed={this.props.isUsed}
                      />
                    }
                    {step === STEPS.THANKYOU &&
                      <ThankYou
                        submitStatus={this.state.submitStatus}
                        reservationNumber={this.state.reservationNumber}
                        dealerName={dealerName}
                        model={model}
                        isUsed={this.props.isUsed}
                      />
                    }
                  </Form>
                )}
              </Formik>
            </>
          }

          <section className="order-section" id="privacy">
            <div className="order-section--content">
              <Privacy className="mb-5" />
            </div>
          </section>
        </PageSidebarLayout>

        <Modal isActive={this.state.activeModal === 'terms'} variant="layer" closeButton close={this.handleCloseModal}>
          {this.state.activeModal === 'terms' && <Terms />}
        </Modal>
        <Modal isActive={this.state.activeModal === 'afs'} variant="layer" closeButton close={this.handleCloseModal}>
          <AFSDisclaimer />
        </Modal>

      </Fragment>
    );
  }
}

const InjectedReservation = (props) => {
  return (
    <ElementsConsumer>
      {({elements, stripe}) => (
        <Reservation elements={elements} stripe={stripe} {...props} />
      )}
    </ElementsConsumer>
  );
};

export default InjectedReservation;
