import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { CardNumberElement, CardExpiryElement, CardCvcElement } from '@stripe/react-stripe-js';

const fieldComponents = {
  "number": CardNumberElement,
  "exp": CardExpiryElement,
  "cvc": CardCvcElement
};

class StripeField extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      isFocused: false
    };
  }

  handleFocus = (e) => {
    this.setState({isFocused: true});
    if (typeof this.props.onFocus === 'function') {
      this.props.onFocus(e);
    }
  }

  handleBlur = (e) => {
    this.setState({isFocused: false});
    if (typeof this.props.onBlur === 'function') {
      this.props.onBlur(e);
    }
  }

  renderMessage = () => {
    const {isValid, isInvalid, validMessage, invalidMessage, description} = this.props;
    if (isValid && validMessage) {
      return (<span className="aui-textfield__valid">{validMessage}</span>);
    }
    if (isInvalid && invalidMessage) {
      return (<span className="aui-textfield__error">{invalidMessage}</span>);
    }
    if (description) {
      return (<span className="aui-textfield__description">{description}</span>);
    }
    return false;
  }

  render() {
    const {isValid, isInvalid, floatingLabel, theme, fieldType} = this.props;
    const {isFocused} = this.state;
    const Component = fieldComponents[fieldType];
    const style = {
      base: {
        fontSize: "16px",
        lineHeight: "1.5"
      },
      invalid: {
        color: "#f50537"
      }
    }
    return(
      <div style={this.props.style} className={classnames(
        "aui-textfield",
        {
          "aui-textfield--floating-label": floatingLabel,
          "is-focused": isFocused,
          "is-dirty": true,
          "is-valid": isValid,
          "is-invalid": isInvalid,
          [`aui-theme-${theme}`]: theme
        }
      )}>
        <div className="aui-textfield__field">
          <div className="aui-textfield__input">
            <Component onFocus={this.handleFocus} onBlur={this.handleBlur} options={{style: style}} />
          </div>
          <label className="aui-textfield__label" htmlFor={this.props.name}>{this.props.label}</label>
          <span className="aui-textfield__focus-line"></span>
        </div>
        {this.renderMessage()}
      </div>
    );
  }

}

StripeField.propTypes = {
  fieldType: PropTypes.oneOf(['number', 'exp', 'cvc']),
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  floatingLabel: PropTypes.bool,
  description: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  invalidMessage: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  validMessage: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  isValid: PropTypes.bool,
  isInvalid: PropTypes.bool,
  theme: PropTypes.oneOf(['light']) // see styles/components/_textfield-themes.scss
};

StripeField.defaultProps = {
  floatingLabel: true,
}

export default StripeField;
